import { gql } from "@apollo/client";
import { Modal } from "flowbite-react";
import React from "react";
import toast from 'react-hot-toast';
import { useCreateEntityMutation } from "./__generated__/CreateEntityModal.generated";
import Label from "../core/Label";
import { Button } from "../core/Button";
import Input from "../core/Input";

type CreateEntityModalProps = {
  visible: boolean;
  onClose: () => void;
  onEntityCreated: () => void;
};

const CREATE_ENTITY = gql`
  mutation createEntity($input: CreateEntityInput!) {
    createEntity(input: $input) {
      entity {
        id
        legalName
      }
      errors
    }
  }
`;

export default (props: CreateEntityModalProps) => {
  const [legalName, setLegalName] = React.useState<string>("");

  const [createEntityAction, { loading }] = useCreateEntityMutation();

  const handleCreateEntity = () => {
    createEntityAction({
      variables: {
        input: {
          legalName: legalName,
        },
      },
      onCompleted: (response) => {
        if (response?.createEntity?.errors?.length) {
          toast.error("Failed to create entity");
          return;
        }
        toast.success("Entity created successfully");
        setLegalName('');
        props.onEntityCreated();
        props.onClose();
      }
    });
  }


  return (
    <Modal
      dismissible
      show={props.visible}
      onClose={props.onClose}
      size='md'
    >
      <Modal.Header>Create new entity</Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          <div className="flex items-center space-x-2">
            <div className="grid flex-1 gap-2">
              <Label>
                Name
              </Label>
              <Input
                id="legalName"
                value={legalName}
                onChange={(e) => { setLegalName(e.target.value) }}
                placeholder="Enter legal name..."
                required
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-between">
        <Button variant='outline' onClick={props.onClose}>
          Close
        </Button>
        <Button onClick={handleCreateEntity} loading={loading}>Create entity</Button>
      </Modal.Footer>
    </Modal>
  );
};
