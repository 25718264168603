import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Heading from "../core/Heading";;
import { gql } from "@apollo/client";
import toast from "react-hot-toast";
import WithLoader from "../core/WithLoader";
import SharedIndividualForm from "./SharedIndividualForm";
import { Individual } from "../../types";
import { useUpsertIndividualMutation, useVerifySharedLinkQuery } from "./__generated__/SharedBoi.generated";
import { INDIVIDUAL_FRAGMENT } from "./IndividualForm";

const VERIFY_TOKEN = gql`
    query verifySharedLink($token: String!) {
        verifySharedLink(token: $token) {
           ...IndividualFormIndividualFragment
        }
    }
    ${INDIVIDUAL_FRAGMENT}
`;

const UPSERT_INDIVIDUAL = gql`
    mutation upsertIndividual($input: UpsertIndividualInput!) {
        upsertIndividual(input: $input) {
            individual {
                id
                firstName
            }
            errors
        }
    }
`;

const SharedBoi = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tokenId = queryParams.get('hash');
    let navigate = useNavigate();

    const [formValues, setFormValues] = useState<Partial<Individual>>();

    const { loading, data, refetch } = useVerifySharedLinkQuery({ variables: { token: tokenId as string } });
    const [upsertIndividualAction, { loading: loadingUpsertIndividual }] = useUpsertIndividualMutation();
    const [formSubmitted, setFormSubmitted] = useState(false);

    useEffect(() => {
        if (!loading) {
            if (tokenId && data?.verifySharedLink) {
                setFormValues(data?.verifySharedLink);
            }
            else {
                toast.error('Invalid token. Please try again.');
                navigate('/login');
            }
        }

    }, [loading]);


    const handleUpdateOwner = (values) => {
        upsertIndividualAction({
            variables: {
                input: {
                    individualParams: values,
                    token: tokenId as string
                }
            },
            onCompleted: (response) => {
                if (response.upsertIndividual?.errors?.length) {
                    toast.error("Something went wrong");
                } else {
                    toast.success("Owner updated");
                    setFormSubmitted(true);
                    setTimeout(() => {
                        navigate('/login');
                    }, 3000);
                }
            }
        })
    };

    return (
        <WithLoader loading={loading || formSubmitted}
            labelText={formSubmitted ? "Redirecting to login...." : "Loading"}
        >
            <div className="mt-10 max-w-2xl mx-auto">
                <Heading className="mb-10 text-center"
                    text={`Anyone who has this link will be able to view and modify this Beneficial Owner form for
                        ${formValues?.firstName ?? ""}`} />
                {formValues && <SharedIndividualForm
                    ownerBoi={formValues}
                    onSubmit={handleUpdateOwner}
                />}

            </div>
        </WithLoader>
    )
}

export default SharedBoi;


