import React from "react";
import { COUNTRIES } from "../../utils/countries"
import Select from "./Select";
import Label from "./Label";

interface ICountrySelectProps {
    value: string;
    handleOnChange: (value: string) => void;
    label?: string;
}


const CountrySelect = (props: ICountrySelectProps) => {

    const { value, handleOnChange, label } = props;

    return (
        <>
            {label && <Label>{label}</Label>}
            <Select
                options={COUNTRIES.map((country) => ({
                    value: country.code,
                    label: country.name,
                }))}
                value={value}
                onChange={handleOnChange}
                placeholder="Select a country"
            />
        </>
    )
}

export default CountrySelect;