import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { EntityFragmentFragmentDoc } from '../../entities/new/__generated__/EditEntityDetails.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetFilingEntityDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetFilingEntityDetailsQuery = { __typename?: 'Query', getFiling: { __typename?: 'Filing', entity: { __typename?: 'Entity', id: string, legalName?: string | null, fincenId?: string | null, foreignTaxCountry?: string | null, identificationType?: string | null, entityType?: string | null, filingType?: string | null, jurisdictionState?: string | null, jurisdictionCountry?: string | null, isCreatedBeforeJan2024?: boolean | null, tradeNames?: Array<string> | null, taxIdentificationNumber?: string | null, isForeignPooledInvestmentVehicle?: boolean | null, dateOfFormation?: any | null, address?: { __typename?: 'Address', id: string, nameLine?: string | null, addressLine1?: string | null, addressLine2?: string | null, administrativeArea?: string | null, locality?: string | null, country?: string | null, postalCode?: string | null } | null, tags?: Array<{ __typename?: 'Tag', id: string, name?: string | null }> | null } } };

export type UpdateEntityMutationVariables = Types.Exact<{
  input: Types.UpdateEntityInput;
}>;


export type UpdateEntityMutation = { __typename?: 'Mutation', updateEntity?: { __typename?: 'UpdateEntityPayload', errors?: Array<string> | null, entity?: { __typename?: 'Entity', id: string, legalName?: string | null, fincenId?: string | null, foreignTaxCountry?: string | null, identificationType?: string | null, entityType?: string | null, filingType?: string | null, jurisdictionState?: string | null, jurisdictionCountry?: string | null, isCreatedBeforeJan2024?: boolean | null, tradeNames?: Array<string> | null, taxIdentificationNumber?: string | null, isForeignPooledInvestmentVehicle?: boolean | null, dateOfFormation?: any | null, address?: { __typename?: 'Address', id: string, nameLine?: string | null, addressLine1?: string | null, addressLine2?: string | null, administrativeArea?: string | null, locality?: string | null, country?: string | null, postalCode?: string | null } | null, tags?: Array<{ __typename?: 'Tag', id: string, name?: string | null }> | null } | null } | null };


export const GetFilingEntityDetailsDocument = gql`
    query getFilingEntityDetails($id: ID!) {
  getFiling(id: $id) {
    entity {
      ...EntityFragment
    }
  }
}
    ${EntityFragmentFragmentDoc}`;

/**
 * __useGetFilingEntityDetailsQuery__
 *
 * To run a query within a React component, call `useGetFilingEntityDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilingEntityDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilingEntityDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFilingEntityDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetFilingEntityDetailsQuery, GetFilingEntityDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFilingEntityDetailsQuery, GetFilingEntityDetailsQueryVariables>(GetFilingEntityDetailsDocument, options);
      }
export function useGetFilingEntityDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFilingEntityDetailsQuery, GetFilingEntityDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFilingEntityDetailsQuery, GetFilingEntityDetailsQueryVariables>(GetFilingEntityDetailsDocument, options);
        }
export function useGetFilingEntityDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetFilingEntityDetailsQuery, GetFilingEntityDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetFilingEntityDetailsQuery, GetFilingEntityDetailsQueryVariables>(GetFilingEntityDetailsDocument, options);
        }
export type GetFilingEntityDetailsQueryHookResult = ReturnType<typeof useGetFilingEntityDetailsQuery>;
export type GetFilingEntityDetailsLazyQueryHookResult = ReturnType<typeof useGetFilingEntityDetailsLazyQuery>;
export type GetFilingEntityDetailsSuspenseQueryHookResult = ReturnType<typeof useGetFilingEntityDetailsSuspenseQuery>;
export type GetFilingEntityDetailsQueryResult = Apollo.QueryResult<GetFilingEntityDetailsQuery, GetFilingEntityDetailsQueryVariables>;
export const UpdateEntityDocument = gql`
    mutation updateEntity($input: UpdateEntityInput!) {
  updateEntity(input: $input) {
    entity {
      ...EntityFragment
    }
    errors
  }
}
    ${EntityFragmentFragmentDoc}`;
export type UpdateEntityMutationFn = Apollo.MutationFunction<UpdateEntityMutation, UpdateEntityMutationVariables>;

/**
 * __useUpdateEntityMutation__
 *
 * To run a mutation, you first call `useUpdateEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntityMutation, { data, loading, error }] = useUpdateEntityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEntityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEntityMutation, UpdateEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEntityMutation, UpdateEntityMutationVariables>(UpdateEntityDocument, options);
      }
export type UpdateEntityMutationHookResult = ReturnType<typeof useUpdateEntityMutation>;
export type UpdateEntityMutationResult = Apollo.MutationResult<UpdateEntityMutation>;
export type UpdateEntityMutationOptions = Apollo.BaseMutationOptions<UpdateEntityMutation, UpdateEntityMutationVariables>;