import * as Types from '../../../../types';

import { gql } from '@apollo/client';
export type AuthUserTypeFragment = { __typename?: 'User', id: string, email: string, createdAt: any, role: string, authenticationToken?: string | null, fullName?: string | null, organization: { __typename?: 'Organization', name?: string | null }, invitation?: { __typename?: 'Invitation', status: string } | null };

export const AuthUserTypeFragmentDoc = gql`
    fragment AuthUserType on User {
  id
  email
  createdAt
  role
  authenticationToken
  fullName
  organization {
    name
  }
  invitation {
    status
  }
}
    `;