import React from "react";
import Select from "./Select";
import { STATES } from "../../utils/countries";
import Label from "./Label";

interface IUsaStateSelectProps {
    value: string;
    handleOnChange: (value: string) => void;
    label?: string
    disabled?: boolean
};

const UsaStateSelect = (props: IUsaStateSelectProps) => {

    const { value, handleOnChange, label, disabled } = props;

    return (
        <>
            {label && <Label>{label}</Label>}
            <Select
                options={STATES.map((country) => ({
                    value: country.code,
                    label: country.name,
                }))}
                disabled={disabled}
                value={value}
                onChange={handleOnChange}
                placeholder="Select a state"
            />
        </>
    )
};

export default UsaStateSelect;