import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import EditEntityDetails from './EditEntityDetails';
import EditBeneficialOwners from './EditBeneficialOwners';
import EditCompanyApplicants from './EditCompanyApplicants';
import EditFilingInformation from './EditFilingInformation';
import Review from './Review';

export default () => {
  return (
    <Routes>
      <Route path="" element={<Navigate to="entity-details" />} />
      <Route path="filing-information" element={<EditFilingInformation />} />
      <Route path="entity-details" element={<EditEntityDetails />} />
      <Route path="beneficial-owners" element={<EditBeneficialOwners />} />
      <Route path="company-applicants" element={<EditCompanyApplicants />} />
      <Route path="review" element={<Review />} />
    </Routes>
  )
}