import React, { useState } from "react";
import { Modal } from "flowbite-react";
import Input from "../../core/Input";
import { Button } from "../../core/Button";
import SmallButton from "../../core/SmallButton";
import { HiTrash } from "react-icons/hi";
import { useDebounce } from 'use-debounce';
import Select from "../../core/Select";
import { isEmailValid } from "../../../utils/strings";

interface InviteModalProps {
    visible: boolean;
    onSubmit: (invite: {email: string, role: string}[]) => void;
    onClose: () => void;
};

const InviteModal = (props: InviteModalProps) => {

    const { visible, onSubmit, onClose } = props;

    const [emails, setEmails] = useState([{
        email: "",
        role: "member"
    }]);

    const [error, setError] = useState(false);

    const [debouncedEmails] = useDebounce(emails, 1000);

    const ROLES = [
        {
            label: "Admin",
            value: "admin"
        },
        {
            label: "Member",
            value: "member"
        }
    ];

    const handleAddInputRow = () => {
        setEmails([...emails, {
            email: "",
            role: "member"
        }])
    }

    const handleOnChangeEmail = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedEmails = [...emails];
        updatedEmails[index].email = event.target.value;
        setEmails(updatedEmails);
    };

    const handleOnChangeRole = (index: number) => (value: string) => {
        const updatedEmails = [...emails];
        updatedEmails[index].role = value;
        setEmails(updatedEmails);
    }

    const handleDeleteEmail = (index: number) => () => {
        const updatedEmails = [...emails];
        updatedEmails.splice(index, 1);
        setEmails(updatedEmails);
    }

    const invalidEmails = debouncedEmails.filter((email) => !isEmailValid(email.email));
    const emptyEmails = debouncedEmails.filter((email) => email.email === "");

    const handleSubmit = (emails) => () => {
        if (emails.length === 0 || emptyEmails.length > 0 || invalidEmails.length > 0) {
            setError(true);
        } else {
            onSubmit(emails);
        }
    };

    return (
        <Modal
            show={visible}
            onClose={onClose}
            size='lg'
        >
            <Modal.Header>Invite Teammate</Modal.Header>
            <Modal.Body className="overflow-visible">
                <div className="space-y-4">
                    <p className="text-sm text-justify">Invite a teammate to join your organization and collaborate seamlessly. Enter their email address below, and they'll receive an invitation to join your team.</p>
                    <div className="flex flex-col items-start space-y-2">
                        {emails.map((item, index) => (
                            <>
                                <div key={index} className="flex flex-row space-x-2 w-full">
                                    <Input
                                        id={"email-" + index}
                                        value={item.email}
                                        onChange={handleOnChangeEmail(index)}
                                        required
                                        placeholder="Enter email..."
                                        type="email"
                                        className={`${error && !isEmailValid(item.email) ? "text-red-500" : ""}`}
                                    />
                                    <div className="w-2/5">
                                        <Select
                                            options={ROLES.map((role) => ({
                                                value: role.value,
                                                label: role.label,
                                            }))}
                                            value={item.role}
                                            onChange={handleOnChangeRole(index)}
                                        />
                                    </div>
                                    <SmallButton onClick={handleDeleteEmail(index)}
                                        className="p-0 m-0 px-0 py-0">
                                        <HiTrash color="red" size={20} aria-hidden="true" />
                                    </SmallButton>
                                </div>
                                {error && !isEmailValid(item.email) && item.email !== '' && (
                                    <p className="text-red-500 text-sm">Please enter a valid email address.</p>
                                )}
                                {error && item.email === '' && (
                                    <p className="text-red-500 text-sm">Please email cant be blank.</p>
                                )}
                            </>
                        ))}
                    </div>
                    <div className="flex flex-col-1 !ml-0">
                        <Button
                            variant='outline'
                            size='sm'
                            onClick={handleAddInputRow}
                        >+ Add more</Button>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="justify-between">
                <Button variant='outline' onClick={onClose}>
                    Close
                </Button>
                <Button
                    onClick={handleSubmit(debouncedEmails)}
                    disabled={debouncedEmails.length === 0}
                >Invite</Button>
            </Modal.Footer>
        </Modal >
    )
};

export default InviteModal;


