import React from "react";
import { ADDRESS_FRAGMENT, formatAddress } from "../../utils/address";
import { gql } from "@apollo/client";
import { IndividualFragmentFragment } from "./__generated__/IndividualCard.generated";
import { parseISO } from "date-fns";

const INDIVIDUAL_FRAGMENT = gql`
  fragment IndividualFragment on Individual {
    id
    firstName
    dateOfBirth
    identificationNumber
    identificationIssuingJurisdiction
    address {
      ...AddressFragment
    }
  }
  ${ADDRESS_FRAGMENT}
`;

type Individual = IndividualFragmentFragment;

type IndividualCardProps = {
  individual?: Individual;
};


export default (props: IndividualCardProps) => {

  const dateOfBirth = parseISO(props.individual?.dateOfBirth)?.toLocaleDateString();

  return (
    <div className="m-2 grid grid-cols-1 sm:grid-cols-2 border rounded-md border-gray-200 bg-white px-4 py-5 sm:px-6">
      <h3 className="text-base font-semibold leading-6 text-gray-900">
        {props.individual?.firstName}
      </h3>
      <p className="mt-2 text-xs text-gray-500 col-start-1">
        <span className="font-medium text-gray-600">DOB: </span>
        {dateOfBirth}
      </p>

      <p className="mt-2 text-xs text-gray-500 col-start-2">
        <span className="font-medium text-gray-600">ID #: </span>
        {props.individual?.identificationNumber}
      </p>

      <p className="mt-2 text-xs text-gray-500 col-start-1">
        <span className="font-medium text-gray-600">
          Address <br />{" "}
        </span>
        {formatAddress(props.individual?.address)?.map((line) => (
          <span key={line}>
            {line} <br />
          </span>
        ))}
      </p>

      <p className="mt-2 text-xs text-gray-500 col-start-2">
        <span className="font-medium text-gray-600">ID Jurisdiction: </span>
        {props.individual?.identificationIssuingJurisdiction}
      </p>
    </div>
  );
};
