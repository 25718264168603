import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { IndividualFormIndividualFragmentFragmentDoc } from '../../__generated__/IndividualForm.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CompanyApplicantFormEntityFragmentFragment = { __typename?: 'Entity', id: string, dateOfFormation?: any | null, companyApplicants?: Array<{ __typename?: 'Individual', id: string, fincenId?: string | null, isParentGuardianInfo?: boolean | null, firstName?: string | null, lastName?: string | null, middleName?: string | null, suffix?: string | null, dateOfBirth?: any | null, dateOfBirthUnknown?: boolean | null, identificationType?: string | null, identificationNumber?: string | null, issuingState?: string | null, identificationIssuingJurisdiction?: string | null, addressType?: string | null, identificationDocument?: { __typename?: 'Attachment', url: string, contentType: string, filename: string } | null, address?: { __typename?: 'Address', id: string, nameLine?: string | null, addressLine1?: string | null, addressLine2?: string | null, administrativeArea?: string | null, locality?: string | null, country?: string | null, postalCode?: string | null } | null }> | null };

export type GetEntityCompanyApplicantDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetEntityCompanyApplicantDetailsQuery = { __typename?: 'Query', getEntity: { __typename?: 'Entity', id: string, dateOfFormation?: any | null, companyApplicants?: Array<{ __typename?: 'Individual', id: string, fincenId?: string | null, isParentGuardianInfo?: boolean | null, firstName?: string | null, lastName?: string | null, middleName?: string | null, suffix?: string | null, dateOfBirth?: any | null, dateOfBirthUnknown?: boolean | null, identificationType?: string | null, identificationNumber?: string | null, issuingState?: string | null, identificationIssuingJurisdiction?: string | null, addressType?: string | null, identificationDocument?: { __typename?: 'Attachment', url: string, contentType: string, filename: string } | null, address?: { __typename?: 'Address', id: string, nameLine?: string | null, addressLine1?: string | null, addressLine2?: string | null, administrativeArea?: string | null, locality?: string | null, country?: string | null, postalCode?: string | null } | null }> | null } };

export type UpdateEntityForCompanyApplicantFormMutationVariables = Types.Exact<{
  input: Types.UpdateEntityInput;
}>;


export type UpdateEntityForCompanyApplicantFormMutation = { __typename?: 'Mutation', updateEntity?: { __typename?: 'UpdateEntityPayload', errors?: Array<string> | null, entity?: { __typename?: 'Entity', id: string, dateOfFormation?: any | null, companyApplicants?: Array<{ __typename?: 'Individual', id: string, fincenId?: string | null, isParentGuardianInfo?: boolean | null, firstName?: string | null, lastName?: string | null, middleName?: string | null, suffix?: string | null, dateOfBirth?: any | null, dateOfBirthUnknown?: boolean | null, identificationType?: string | null, identificationNumber?: string | null, issuingState?: string | null, identificationIssuingJurisdiction?: string | null, addressType?: string | null, identificationDocument?: { __typename?: 'Attachment', url: string, contentType: string, filename: string } | null, address?: { __typename?: 'Address', id: string, nameLine?: string | null, addressLine1?: string | null, addressLine2?: string | null, administrativeArea?: string | null, locality?: string | null, country?: string | null, postalCode?: string | null } | null }> | null } | null } | null };

export const CompanyApplicantFormEntityFragmentFragmentDoc = gql`
    fragment CompanyApplicantFormEntityFragment on Entity {
  id
  dateOfFormation
  companyApplicants {
    ...IndividualFormIndividualFragment
  }
}
    ${IndividualFormIndividualFragmentFragmentDoc}`;
export const GetEntityCompanyApplicantDetailsDocument = gql`
    query getEntityCompanyApplicantDetails($id: ID!) {
  getEntity(id: $id) {
    ...CompanyApplicantFormEntityFragment
  }
}
    ${CompanyApplicantFormEntityFragmentFragmentDoc}`;

/**
 * __useGetEntityCompanyApplicantDetailsQuery__
 *
 * To run a query within a React component, call `useGetEntityCompanyApplicantDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntityCompanyApplicantDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntityCompanyApplicantDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEntityCompanyApplicantDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetEntityCompanyApplicantDetailsQuery, GetEntityCompanyApplicantDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEntityCompanyApplicantDetailsQuery, GetEntityCompanyApplicantDetailsQueryVariables>(GetEntityCompanyApplicantDetailsDocument, options);
      }
export function useGetEntityCompanyApplicantDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEntityCompanyApplicantDetailsQuery, GetEntityCompanyApplicantDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEntityCompanyApplicantDetailsQuery, GetEntityCompanyApplicantDetailsQueryVariables>(GetEntityCompanyApplicantDetailsDocument, options);
        }
export function useGetEntityCompanyApplicantDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetEntityCompanyApplicantDetailsQuery, GetEntityCompanyApplicantDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEntityCompanyApplicantDetailsQuery, GetEntityCompanyApplicantDetailsQueryVariables>(GetEntityCompanyApplicantDetailsDocument, options);
        }
export type GetEntityCompanyApplicantDetailsQueryHookResult = ReturnType<typeof useGetEntityCompanyApplicantDetailsQuery>;
export type GetEntityCompanyApplicantDetailsLazyQueryHookResult = ReturnType<typeof useGetEntityCompanyApplicantDetailsLazyQuery>;
export type GetEntityCompanyApplicantDetailsSuspenseQueryHookResult = ReturnType<typeof useGetEntityCompanyApplicantDetailsSuspenseQuery>;
export type GetEntityCompanyApplicantDetailsQueryResult = Apollo.QueryResult<GetEntityCompanyApplicantDetailsQuery, GetEntityCompanyApplicantDetailsQueryVariables>;
export const UpdateEntityForCompanyApplicantFormDocument = gql`
    mutation updateEntityForCompanyApplicantForm($input: UpdateEntityInput!) {
  updateEntity(input: $input) {
    entity {
      ...CompanyApplicantFormEntityFragment
    }
    errors
  }
}
    ${CompanyApplicantFormEntityFragmentFragmentDoc}`;
export type UpdateEntityForCompanyApplicantFormMutationFn = Apollo.MutationFunction<UpdateEntityForCompanyApplicantFormMutation, UpdateEntityForCompanyApplicantFormMutationVariables>;

/**
 * __useUpdateEntityForCompanyApplicantFormMutation__
 *
 * To run a mutation, you first call `useUpdateEntityForCompanyApplicantFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntityForCompanyApplicantFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntityForCompanyApplicantFormMutation, { data, loading, error }] = useUpdateEntityForCompanyApplicantFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEntityForCompanyApplicantFormMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEntityForCompanyApplicantFormMutation, UpdateEntityForCompanyApplicantFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEntityForCompanyApplicantFormMutation, UpdateEntityForCompanyApplicantFormMutationVariables>(UpdateEntityForCompanyApplicantFormDocument, options);
      }
export type UpdateEntityForCompanyApplicantFormMutationHookResult = ReturnType<typeof useUpdateEntityForCompanyApplicantFormMutation>;
export type UpdateEntityForCompanyApplicantFormMutationResult = Apollo.MutationResult<UpdateEntityForCompanyApplicantFormMutation>;
export type UpdateEntityForCompanyApplicantFormMutationOptions = Apollo.BaseMutationOptions<UpdateEntityForCompanyApplicantFormMutation, UpdateEntityForCompanyApplicantFormMutationVariables>;