import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { cn } from "../../utils/styles";
import { useAuth } from "../auth/authContext";
import { Label } from "flowbite-react";
import { HiOutlineLogout } from "react-icons/hi";

const navigation = [
  { name: "Entities", href: "/entities", current: true },
  { name: "Filings", href: "/filings", current: false },
  { name: "Settings", href: "/settings", current: false },
];

const Sidebar = () => {
  const { auth, signOut } = useAuth();
  const location = useLocation();
  
  navigation.forEach((item) => {
    item.current = location.pathname === item.href;
  });

  return (
    <div className="h-screen sticky top-0 flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 p-6 w-64">
      <div className="flex flex-row items-center gap-4">
        <div className="flex flex-col">
          <img
            className="h-8 w-auto"
            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
            alt="Your Company"
          />
        </div>
        <div className="flex flex-col">
          <Label className="text-white text-base font-semibold">{auth.user?.fullName}</Label>
          <Label className="text-white font-semibold">{auth.user?.email}</Label>
        </div>
      </div>
      <nav className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" className="-mx-2 space-y-1">
              {navigation.map((item) => (
                <li key={item.name}>
                  <Link to={item.href} className={cn(
                    item.current
                      ? "bg-gray-800 text-white"
                      : "text-gray-400 hover:text-white hover:bg-gray-800",
                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold cursor-pointer"
                  )}>
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
          <li className="mt-auto">
            <ul role="list" className="-mx-2 space-y-1">
              <li>
                <a
                  onClick={signOut}
                  className='group flex gap-x-3 text-white leading-6 font-semibold 
                  cursor-pointer items-center hover:text-white hover:bg-gray-800
                  rounded-md p-2'
                >
                  <HiOutlineLogout />
                  Sign Out
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
