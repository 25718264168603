import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
// import { createUploadLink } from 'apollo-upload-client';
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { getAuthToken } from './auth';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';

const removeTypenameLink = removeTypenameFromVariables();


// const httpLink = createHttpLink({
//   uri: '/graphql',
// });



const createHttpLink = (): any =>
  createUploadLink({
    uri: '/graphql',
    credentials: 'same-origin',
  });


const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getAuthToken();
  console.log({token})
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const link = from([removeTypenameLink, authLink.concat(createHttpLink())]);

export const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache()
});