import React from "react";
import { useState } from "react";
import { cn } from "../../utils/styles";
import { Button } from "./Button";
import { Popover, PopoverContent, PopoverTrigger } from "./Popover";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from "./Command";
import { HiOutlineSearch, HiCheck } from "react-icons/hi";
import { useDebouncedCallback } from 'use-debounce';
interface ICombobox {
    data: any;
    placeholder: string;
    onSelectBoi?: (value: any) => void;
    onSearch?: (value: string) => void;
}

const Combobox = (props: ICombobox) => {

    const { data, placeholder, onSelectBoi, onSearch } = props;

    const [open, setOpen] = useState(false);
    const [value, setValue] = useState("");

    const debouncedSearch = useDebouncedCallback((searchValue) => {
        onSearch?.(searchValue);
    }, 500);

    const handleOnSelect = (currentValue: string) => {
        setValue(currentValue === value ? "" : currentValue);
        setOpen(false);
        onSelectBoi?.(currentValue);
    };

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    style={{ minWidth: '400px' }}
                    className="justify-between"
                >
                    {value
                        ? data?.find((individual) => individual?.firstName?.toLowerCase() === value?.toLowerCase())?.firstName
                        : placeholder}
                    <HiOutlineSearch className="ml-2 shrink-0 opacity-50" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[200px] p-0" style={{ minWidth: '300px' }}>
                <Command onChange={(event) => debouncedSearch(event.target.value)}>
                    <CommandInput />
                    <CommandEmpty>No BOI found.</CommandEmpty>
                    <CommandGroup>
                        {data?.map((individual) => (
                            <CommandItem
                                key={`item${individual?.id}`}
                                value={individual?.firstName ?? ""}
                                onSelect={handleOnSelect}
                            >
                                {individual?.firstName}
                                <HiCheck
                                    className={cn(
                                        "ml-auto h-4 w-4",
                                        value === individual?.firstName ? "opacity-100" : "opacity-0"
                                    )}
                                />
                            </CommandItem>
                        ))}
                    </CommandGroup>
                </Command>
            </PopoverContent>
        </Popover>
    )
}

export default Combobox;
