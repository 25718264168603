import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Separator } from '@radix-ui/react-separator';
import { FieldArray } from 'formik';
import IndividualForm, { generateNewIndividual } from './IndividualForm';
import { Button } from '../core/Button';
import SharedBoiModal from './SharedBoiModal';
import { gql } from '@apollo/client';
import { useShareLinkMutation } from './__generated__/BeneficialOwnerForm.generated';
import toast from 'react-hot-toast';
import SmallButton from '../core/SmallButton';
import { HiOutlineShare } from "react-icons/hi";
import { useParams } from 'react-router-dom';
import Combobox from '../core/Combobox';
import { useListIndividualsQuery } from './new/__generated__/EditBeneficialOwners.generated';
import { useDeleteIndividualMutation } from './__generated__/EntityDetails.generated';

const CREATE_SHARE_BIO_LINK = gql`
    mutation shareLink($input: ShareLinkInput!) {
      shareLink(input: $input) {
        sharedLink
        errors
      }
    }
`;


export default ({
  values,
  handleChange,
  setFieldValue,
  onAddOwner
}) => {

  const { entityId, } = useParams();

  const [showSharedModal, setShowSharedModal] = useState(false);
  const [createShareLinkAction, { loading }] = useShareLinkMutation();
  const [deleteIndividualAction,] = useDeleteIndividualMutation();
  const { loading: loadingIndividualList, data, refetch } = useListIndividualsQuery(
    {
      variables: {
        individualType: "beneficial_owner",
        searchParam: "",
        entityId: entityId,
        page: 1,
        limit: 10,
      }
    }
  );
  const [shareUrl, setShareUrl] = useState<string | null>(null);

  const handleGenerateShareLink = (event: SyntheticEvent, owner) => {
    event.stopPropagation();
    event.preventDefault();
    if (entityId && owner?.id) {
      createShareLinkAction({
        variables: {
          input: {
            entityId: entityId,
            ownerId: owner.id
          }
        },
        onCompleted: (data) => {
          if (data?.shareLink?.errors?.length) {
            toast.error("Something went wrong");
            handleShowSharedModal();
          } else {
            if (data?.shareLink?.sharedLink) {
              setShareUrl(data?.shareLink?.sharedLink);
              handleShowSharedModal();
            }
          }
        }
      })
    }
  }

  const handleShowSharedModal = () => {
    setShowSharedModal((prev) => !prev);
  };

  const handleOnSelectBoi = (currentValue: string) => {
    const selectedBoi = data?.listIndividuals?.collection.find((individual) =>
      individual?.firstName?.toLocaleLowerCase() === currentValue);
    if (selectedBoi) {
      setFieldValue('beneficialOwners', [...values?.beneficialOwners, selectedBoi]);
      onAddOwner(true);
    }
  };

  const handleOnSearchBoi = (searchValue: string) => {
    const isSearchValueinData = data?.listIndividuals?.collection.find((item) =>
      item?.firstName?.toLocaleLowerCase() === searchValue.toLocaleLowerCase());

    if (searchValue !== "" && !isSearchValueinData) {
      refetch({
        individualType: "beneficial_owner",
        searchParam: searchValue
      });
    }
  };

  const handleDeleteIndividual = (individualId) => {
    deleteIndividualAction({
      variables: {
        input: {
          id: individualId
        }
      },
      onCompleted: (data) => {
        if (data?.deleteIndividual?.errors?.length) {
          toast.error("Something went wrong");
        } else {
          refetch({
            individualType: "beneficial_owner",
            searchParam: ""
          });
          toast.success("Beneficial Owner successfully removed.")
        }
      }
    })
  };

  return (
    <div id='boi-container'>
      <FieldArray
        name="beneficialOwners"
        render={(arrayHelpers) => (
          <>
            <div className='flex grow items-center justify-between'>
              <Combobox
                data={data?.listIndividuals?.collection}
                placeholder="Search for existing beneficial owners..."
                onSelectBoi={handleOnSelectBoi}
                onSearch={handleOnSearchBoi}
              />
              <Button
                onClick={() => {
                  arrayHelpers.push(generateNewIndividual());
                  onAddOwner(true);
                }}
              >
                Add new beneficial owner
              </Button>
            </div>
            <div className="space-y-4 mt-2">
              {values?.beneficialOwners?.map((owner, index) => (
                <div key={owner?.id} className="space-y-4">
                  <IndividualForm
                    key={owner.id}
                    value={owner}
                    onChange={(ownerValue) => {
                      setFieldValue(
                        `beneficialOwners.${index}`,
                        ownerValue
                      );
                    }}
                    onDelete={() => {
                      arrayHelpers.remove(index);
                      handleDeleteIndividual(owner.id)
                    }}
                    title={`Beneficial Owner ${index + 1}`}
                    shareButton={
                      <div className="ml-3 cursor-pointer">
                        <SmallButton
                          onClick={(event) => handleGenerateShareLink(event, owner)}
                          className={owner.isNewRecord ? 'hidden' : ''}
                          disabled={owner.isNewRecord}
                        >
                          <HiOutlineShare />
                        </SmallButton>

                      </div>
                    }
                  />
                  <Separator />
                </div>
              ))}
            </div>
          </>
        )}
      />
      <SharedBoiModal
        shareUrl={shareUrl}
        visible={showSharedModal}
        onClose={handleShowSharedModal}
      />

    </div>
  )
}

