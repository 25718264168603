import React, { useState } from 'react';
import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi';
import FormikField from './FormikField';
import SmallButton from '../../core/SmallButton';

const PasswordField = ({ fieldName, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className='flex relative'>
      <div className='flex-1'>
        <FormikField
          fieldName={fieldName}
          type={showPassword ? 'text' : 'password'}
          {...props}
        />
        <SmallButton
          className='h-9 absolute right-0 top-0'
          onClick={() => setShowPassword((prev) => !prev)}>
          {showPassword ? <HiOutlineEye /> : <HiOutlineEyeOff />}
        </SmallButton>
      </div>
    </div>
  );
};

export default PasswordField;