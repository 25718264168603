import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { IndividualFormIndividualFragmentFragmentDoc } from '../../entities/__generated__/IndividualForm.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BeneficialOwnerFormEntityFragmentFragment = { __typename?: 'Entity', id: string, beneficialOwners?: Array<{ __typename?: 'Individual', id: string, name?: string | null, dateOfBirth?: any | null, identificationNumber?: string | null, identificationIssuingJurisdiction?: string | null, identificationDocument?: { __typename?: 'Attachment', url: string, contentType: string, filename: string } | null, address?: { __typename?: 'Address', id: string, nameLine?: string | null, addressLine1?: string | null, addressLine2?: string | null, administrativeArea?: string | null, locality?: string | null, country?: string | null, postalCode?: string | null } | null }> | null };

export type GetFilingBeneficialOwnerDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetFilingBeneficialOwnerDetailsQuery = { __typename?: 'Query', getFiling: { __typename?: 'Filing', entity: { __typename?: 'Entity', id: string, beneficialOwners?: Array<{ __typename?: 'Individual', id: string, name?: string | null, dateOfBirth?: any | null, identificationNumber?: string | null, identificationIssuingJurisdiction?: string | null, identificationDocument?: { __typename?: 'Attachment', url: string, contentType: string, filename: string } | null, address?: { __typename?: 'Address', id: string, nameLine?: string | null, addressLine1?: string | null, addressLine2?: string | null, administrativeArea?: string | null, locality?: string | null, country?: string | null, postalCode?: string | null } | null }> | null } } };

export type UpdateEntityForBeneficialOwnerFormMutationVariables = Types.Exact<{
  input: Types.UpdateEntityInput;
}>;


export type UpdateEntityForBeneficialOwnerFormMutation = { __typename?: 'Mutation', updateEntity?: { __typename?: 'UpdateEntityPayload', errors?: Array<string> | null, entity?: { __typename?: 'Entity', id: string, beneficialOwners?: Array<{ __typename?: 'Individual', id: string, name?: string | null, dateOfBirth?: any | null, identificationNumber?: string | null, identificationIssuingJurisdiction?: string | null, identificationDocument?: { __typename?: 'Attachment', url: string, contentType: string, filename: string } | null, address?: { __typename?: 'Address', id: string, nameLine?: string | null, addressLine1?: string | null, addressLine2?: string | null, administrativeArea?: string | null, locality?: string | null, country?: string | null, postalCode?: string | null } | null }> | null } | null } | null };

export const BeneficialOwnerFormEntityFragmentFragmentDoc = gql`
    fragment BeneficialOwnerFormEntityFragment on Entity {
  id
  beneficialOwners {
    ...IndividualFormIndividualFragment
  }
}
    ${IndividualFormIndividualFragmentFragmentDoc}`;
export const GetFilingBeneficialOwnerDetailsDocument = gql`
    query getFilingBeneficialOwnerDetails($id: ID!) {
  getFiling(id: $id) {
    entity {
      ...BeneficialOwnerFormEntityFragment
    }
  }
}
    ${BeneficialOwnerFormEntityFragmentFragmentDoc}`;

/**
 * __useGetFilingBeneficialOwnerDetailsQuery__
 *
 * To run a query within a React component, call `useGetFilingBeneficialOwnerDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilingBeneficialOwnerDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilingBeneficialOwnerDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFilingBeneficialOwnerDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetFilingBeneficialOwnerDetailsQuery, GetFilingBeneficialOwnerDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFilingBeneficialOwnerDetailsQuery, GetFilingBeneficialOwnerDetailsQueryVariables>(GetFilingBeneficialOwnerDetailsDocument, options);
      }
export function useGetFilingBeneficialOwnerDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFilingBeneficialOwnerDetailsQuery, GetFilingBeneficialOwnerDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFilingBeneficialOwnerDetailsQuery, GetFilingBeneficialOwnerDetailsQueryVariables>(GetFilingBeneficialOwnerDetailsDocument, options);
        }
export function useGetFilingBeneficialOwnerDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetFilingBeneficialOwnerDetailsQuery, GetFilingBeneficialOwnerDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetFilingBeneficialOwnerDetailsQuery, GetFilingBeneficialOwnerDetailsQueryVariables>(GetFilingBeneficialOwnerDetailsDocument, options);
        }
export type GetFilingBeneficialOwnerDetailsQueryHookResult = ReturnType<typeof useGetFilingBeneficialOwnerDetailsQuery>;
export type GetFilingBeneficialOwnerDetailsLazyQueryHookResult = ReturnType<typeof useGetFilingBeneficialOwnerDetailsLazyQuery>;
export type GetFilingBeneficialOwnerDetailsSuspenseQueryHookResult = ReturnType<typeof useGetFilingBeneficialOwnerDetailsSuspenseQuery>;
export type GetFilingBeneficialOwnerDetailsQueryResult = Apollo.QueryResult<GetFilingBeneficialOwnerDetailsQuery, GetFilingBeneficialOwnerDetailsQueryVariables>;
export const UpdateEntityForBeneficialOwnerFormDocument = gql`
    mutation updateEntityForBeneficialOwnerForm($input: UpdateEntityInput!) {
  updateEntity(input: $input) {
    entity {
      ...BeneficialOwnerFormEntityFragment
    }
    errors
  }
}
    ${BeneficialOwnerFormEntityFragmentFragmentDoc}`;
export type UpdateEntityForBeneficialOwnerFormMutationFn = Apollo.MutationFunction<UpdateEntityForBeneficialOwnerFormMutation, UpdateEntityForBeneficialOwnerFormMutationVariables>;

/**
 * __useUpdateEntityForBeneficialOwnerFormMutation__
 *
 * To run a mutation, you first call `useUpdateEntityForBeneficialOwnerFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntityForBeneficialOwnerFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntityForBeneficialOwnerFormMutation, { data, loading, error }] = useUpdateEntityForBeneficialOwnerFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEntityForBeneficialOwnerFormMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEntityForBeneficialOwnerFormMutation, UpdateEntityForBeneficialOwnerFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEntityForBeneficialOwnerFormMutation, UpdateEntityForBeneficialOwnerFormMutationVariables>(UpdateEntityForBeneficialOwnerFormDocument, options);
      }
export type UpdateEntityForBeneficialOwnerFormMutationHookResult = ReturnType<typeof useUpdateEntityForBeneficialOwnerFormMutation>;
export type UpdateEntityForBeneficialOwnerFormMutationResult = Apollo.MutationResult<UpdateEntityForBeneficialOwnerFormMutation>;
export type UpdateEntityForBeneficialOwnerFormMutationOptions = Apollo.BaseMutationOptions<UpdateEntityForBeneficialOwnerFormMutation, UpdateEntityForBeneficialOwnerFormMutationVariables>;