import * as React from "react"
import DatePicker from "react-datepicker";
interface DatePickerProps {
  value: Date
  onChange: (value: Date) => void
}

export default (props: DatePickerProps) => {
  const { value, onChange } = props || {};

  return (
    <DatePicker
      selected={value}
      onChange={onChange}
      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
  )
}
