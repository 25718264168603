import { gql } from "@apollo/client";
import React from "react";
import { ADDRESS_FRAGMENT, formatAddress } from "../../utils/address";
import { INDIVIDUAL_FRAGMENT } from "../entities/IndividualForm";
import { useGetFilingForReviewQuery, useSubmitFilingMutation } from "./__generated__/Review.generated";
import { useNavigate, useParams } from "react-router-dom";
import FilingLayout from "./FilingLayout";
import { Button } from "../core/Button";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../core/Accordion";
import { parseISO } from "date-fns";
import Empty from "../core/Empty";
import toast from "react-hot-toast";

const ENTITY_FRAGMENT = gql`
  fragment FilingReviewEntityFragment on Entity {
    id
    legalName
    tradeNames
    taxIdentificationNumber
    jurisdictionState
    jurisdictionCountry
    isForeignPooledInvestmentVehicle
    dateOfFormation
    entityDetailsComplete
    beneficialOwnersComplete
    companyApplicantsComplete
    address {
      ...AddressFragment
    }
    beneficialOwners {
      ...IndividualFormIndividualFragment
    }
    companyApplicants {
      ...IndividualFormIndividualFragment
    }
  }
  ${ADDRESS_FRAGMENT}
  ${INDIVIDUAL_FRAGMENT}
`;

const GET_FILING = gql`
  query getFilingForReview($id: ID!) {
    getFiling(id: $id) {
      id
      filingType
      correctedReport
      entity {
        ...FilingReviewEntityFragment
      }
    }
  }
  ${ENTITY_FRAGMENT}
`;

const SUBMIT_FILING = gql`
  mutation submitFiling($input: SubmitFilingInput!) {
    submitFiling(input: $input) {
      filing {
        id
        status
        entity {
          id
        }
      }
      errors
    }
  }
`;

const InfoItem = ({ label, value }: { label: string; value: string }) => (
  <div className="mb-2">
    <span className="font-semibold">{label}:&nbsp;</span>
    {value}
  </div>
);

export default () => {
  const { filingId } = useParams();
  const navigate = useNavigate();

  const { data, loading } = useGetFilingForReviewQuery({
    variables: {
      id: filingId || "",
    },
  });

  const [ submitFilingAction, { loading: submitFilingLoading }] = useSubmitFilingMutation({
    onCompleted: (data) => {
      if (data?.submitFiling?.errors?.length) {
        toast.error('Something went wrong');
        return
      }
      toast.success('Filing submitted successfully');
      navigate("/entities/" + data?.submitFiling?.filing?.entity?.id);
    },
  })

  const handleSubmit = () => {
    submitFilingAction({
      variables: {
        input: {
          id: filingId || "",
        },
      },
    })
  };

  const tradeNames = data?.getFiling.entity.tradeNames?.join(", ");
  const dateOfFormation = parseISO(
    data?.getFiling.entity.dateOfFormation
  )?.toLocaleDateString();

  return (
    <FilingLayout>
      <FilingLayout.Body>
        <div className="border rounded-md p-8">
          <h1 className="text-2xl font-semibold mb-4">Review</h1>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <div>
              <Accordion type="multiple">
                <AccordionItem value="filing-details">
                  <AccordionTrigger>Filing Details</AccordionTrigger>
                  <AccordionContent>
                    <InfoItem
                      label="Filing Type"
                      value={data?.getFiling.filingType || ""}
                    />
                    <InfoItem
                      label="Corrected Report"
                      value={data?.getFiling.correctedReport ? "Yes" : "No"}
                    />
                  </AccordionContent>
                </AccordionItem>

                <AccordionItem value="entity-details">
                  <AccordionTrigger>Entity Details</AccordionTrigger>
                  <AccordionContent>
                    <InfoItem
                      label="Legal Name"
                      value={data?.getFiling.entity.legalName || ""}
                    />
                    <InfoItem
                      label="Trade Names"
                      value={tradeNames?.length ? tradeNames : "None"}
                    />
                    <InfoItem
                      label="Tax Identification Number"
                      value={
                        data?.getFiling.entity.taxIdentificationNumber || ""
                      }
                    />
                    <InfoItem
                      label="Date of Formation"
                      value={dateOfFormation || ""}
                    />
                    <InfoItem
                      label="Jurisdiction State"
                      value={data?.getFiling.entity.jurisdictionState || "N/A"}
                    />
                    <InfoItem
                      label="Jurisdiction Country"
                      value={data?.getFiling.entity.jurisdictionCountry || ""}
                    />
                    <InfoItem
                      label="Is Foreign Pooled Investment Vehicle"
                      value={
                        data?.getFiling.entity.isForeignPooledInvestmentVehicle
                          ? "Yes"
                          : "No"
                      }
                    />
                  </AccordionContent>
                </AccordionItem>

                <AccordionItem value="beneficial-owners">
                  <AccordionTrigger>Beneficial Owners</AccordionTrigger>
                  <AccordionContent>
                    <Accordion type="multiple">
                      {!data?.getFiling?.entity?.beneficialOwners?.length && (
                        <Empty />
                      )}

                      {data?.getFiling?.entity?.beneficialOwners?.map(
                        (owner, index) => (
                          <AccordionItem value={`beneficial-owners-${index}`}>
                            <AccordionTrigger>
                              Beneficial Owner #{index + 1}
                            </AccordionTrigger>
                            <AccordionContent>
                              <div key={owner.id}>
                                <InfoItem
                                  label={`Owner ${index + 1}`}
                                  value={owner.name || ""}
                                />
                                <InfoItem
                                  label="Date of Birth"
                                  value={owner.dateOfBirth || ""}
                                />
                                <InfoItem
                                  label="Identification Number"
                                  value={owner.identificationNumber || ""}
                                />
                                <InfoItem
                                  label="Identification Issuing Jurisdiction"
                                  value={
                                    owner.identificationIssuingJurisdiction ||
                                    ""
                                  }
                                />
                                <div className="mb-2">
                                  <span className="font-semibold">
                                    Address:&nbsp;
                                  </span>
                                  {(formatAddress(owner.address) || []).map(
                                    (line) => (
                                      <div>
                                        {line} <br />
                                      </div>
                                    )
                                  )}
                                </div>
                                {/* <InfoItem label="Address" value={owner.address?.formatted || ""} /> */}
                              </div>
                            </AccordionContent>
                          </AccordionItem>
                        )
                      )}
                    </Accordion>
                  </AccordionContent>
                </AccordionItem>

                <AccordionItem value="company-applicants">
                  <AccordionTrigger>Company Applicants</AccordionTrigger>
                  <AccordionContent>
                    <Accordion type="multiple">
                      {!data?.getFiling?.entity?.companyApplicants?.length && (
                        <Empty />
                      )}
                      {data?.getFiling?.entity?.companyApplicants?.map(
                        (owner, index) => (
                          <AccordionItem value={`company-applicant-${index}`}>
                            <AccordionTrigger>
                              Beneficial Owner #{index + 1}
                            </AccordionTrigger>
                            <AccordionContent>
                              <div key={owner.id}>
                                <InfoItem
                                  label={`Owner ${index + 1}`}
                                  value={owner.name || ""}
                                />
                                <InfoItem
                                  label="Date of Birth"
                                  value={owner.dateOfBirth || ""}
                                />
                                <InfoItem
                                  label="Identification Number"
                                  value={owner.identificationNumber || ""}
                                />
                                <InfoItem
                                  label="Identification Issuing Jurisdiction"
                                  value={
                                    owner.identificationIssuingJurisdiction ||
                                    ""
                                  }
                                />
                                <div className="mb-2">
                                  <span className="font-semibold">
                                    Address:&nbsp;
                                  </span>
                                  {(formatAddress(owner.address) || []).map(
                                    (line) => (
                                      <div>
                                        {line} <br />
                                      </div>
                                    )
                                  )}
                                </div>
                                {/* <InfoItem label="Address" value={owner.address?.formatted || ""} /> */}
                              </div>
                            </AccordionContent>
                          </AccordionItem>
                        )
                      )}
                    </Accordion>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </div>
          )}
        </div>
      </FilingLayout.Body>
      <FilingLayout.Actions>
        <Button
          onClick={handleSubmit}
          disabled={
            !data?.getFiling.entity.entityDetailsComplete ||
            !data?.getFiling.entity.beneficialOwnersComplete ||
            !data?.getFiling.entity.companyApplicantsComplete
          }
        >
          Submit Filing
        </Button>
      </FilingLayout.Actions>
    </FilingLayout>
  );
};
