import React, { useState } from "react";
import AppLayout from "../layout/AppLayout";
import { Button } from "../core/Button";
import { gql } from "@apollo/client";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import WithLoader from "../core/WithLoader";
import Tabs from "../core/Tabs";
import {
  useGetEntityForFilingsPageQuery,
  useListEntityFilingsQuery,
} from "./__generated__/EntityFilings.generated";
import FilingDatatable from "../filings/FilingsDatatable";
import { useListFilingsQuery } from "../filings/__generated__/ListFilings.generated";
import PageLayout from "../layout/PageLayout";

const ENTITY_FRAGMENT = gql`
  fragment EntityDetailsFilingEntityFragment on Entity {
    id
    legalName
    filings {
      id
      filingType
      createdAt
      status
    }
  }
`;

const LIST_ENTITY_FILINGS = gql`
  query listEntityFilings($page: Int, $limit: Int, $filters: FilterParams) {
    listFilings(page: $page, limit: $limit, filters: $filters) {
      collection {
        id
        filingType
        status
        fincenStatus
        createdAt
        entity {
          id
          legalName
        }
      }
      metadata {
        currentPage
        totalPages
      }
    }
  }
`;

const GET_ENTITY = gql`
  query getEntityForFilingsPage($id: ID!) {
    getEntity(id: $id) {
      ...EntityDetailsFilingEntityFragment
    }
  }
  ${ENTITY_FRAGMENT}
`;

export default () => {
  const { entityId } = useParams();
  const [page, setPage] = useState(1);

  const { data, loading, refetch } = useGetEntityForFilingsPageQuery({
    variables: { id: entityId || "" },
  });

  const { data: filingsData, loading: filingLoading } =
    useListEntityFilingsQuery({
      variables: {
        page: page,
        limit: 10, // You can adjust this value or make it a state variable if needed
        filters: {
          entityId: entityId,
        },
      },
      fetchPolicy: "network-only",
    });

  const filings = filingsData?.listFilings?.collection || [];

  const navigate = useNavigate();

  const entity = data?.getEntity;

  return (
    <WithLoader loading={loading}>
      <FilingDatatable
        data={filings}
        page={page}
        onPageChange={(page) => setPage(page)}
        totalPages={filingsData?.listFilings?.metadata?.totalPages}
      />
    </WithLoader>
  );
};
