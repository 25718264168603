import { gql } from '@apollo/client';

export const USER_FRAGMENT = gql`
  fragment AuthUserType on User {
    id
    email
    createdAt
    role
    authenticationToken
    fullName
    organization {
      name
    }
    invitation {
      status
    }
  }
`;
