import { Dropdown, Tooltip } from "flowbite-react";
import React, { ReactNode } from "react";
import { HiDotsVertical } from "react-icons/hi";
import SmallButton from "./SmallButton";

type OptionType = {
    key: string;
    text: string;
};

interface IconMenuProps {
    options: OptionType[];
    isDisabled?: boolean;
    customIcon?: ReactNode
    onSelectedOption: (option: string) => void;
};

const IconMenu = (props: IconMenuProps) => {

    const { options, isDisabled, customIcon, onSelectedOption } = props;

    return (
        <Dropdown label=""
            dismissOnClick={false}
            placement="right-start"
            renderTrigger={() =>
                <span className="cursor-pointer">
                    <SmallButton disabled={isDisabled}>
                        {customIcon}
                    </SmallButton>
                </span>
            }>
            {options?.map((option) => (
                <Dropdown.Item
                    key={option.key}
                    onClick={() => onSelectedOption(option.key)}>
                    {option.text}
                </Dropdown.Item>
            ))}
        </Dropdown>
    )
};

IconMenu.defaultProps = {
    customIcon: <HiDotsVertical />,
    options: [
        {
            key: 'edit',
            text: 'Edit'
        },
        {
            key: 'delete',
            text: 'Delete'
        },
    ]
};

export default IconMenu;