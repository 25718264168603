import React, { HTMLProps, useEffect, useMemo, useRef, useState } from "react";
import { gql } from "@apollo/client";
import { Button } from "../core/Button";
import { useAddTagsToEntityMutation, useListEntitiesQuery, useListTagsQuery } from "./__generated__/ListEntities.generated";
import CreateEntityModal from "./CreateEntityModal";
import { DataTable } from "../core/DataTable";
import { ColumnDef } from "@tanstack/react-table"
import { Entity } from "../../types";
import { Link } from "react-router-dom";
import { HiOutlineSwitchVertical } from "react-icons/hi";
import WithLoader from '../core/WithLoader';
import PageLayout from "../layout/PageLayout";
import { titlecase } from "../../utils/strings";
import { useAuth } from "../auth/authContext";
import AddTagsModal from "../tags/AddTagsModal";
import { ENTITY_FRAGMENT } from "./new/EditEntityDetails";
import toast from "react-hot-toast";
import { ComboboxMultiSelect } from "../tags/components/ComboboxMultiSelect";
import Tag, { TagData } from "../tags/components/Tag";

const LIST_ENTITIES = gql`
  query listEntities($tagIds: [ID!]) {
    listEntities(tagIds: $tagIds) {
      id
      legalName
      tags {
        id
        name
      }
      visibility
      createdAt
      invitedUsers {
        id
        email
        role
      }
    }
  }
`;

const ADD_TAG_TO_ENTITY = gql`
   mutation addTagsToEntity($input: AddTagsInput!) {
      addTagsToEntity(input: $input) {
        entity {
          ...EntityFragment
        }
        errors
      }
   }
   ${ENTITY_FRAGMENT}
`;

const LIST_TAGS = gql`
  query listTags {
    listTags {
      id
      name
    }
  }
`;

export type SelectedRowData = {
  id: string;
  name: string;
  tags: TagData[];
};

function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!)

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate])

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + ' cursor-pointer'}
      {...rest}
    />
  )
}

const ListEntities = () => {
  const { data: tagsData, refetch: refetchTags } = useListTagsQuery();
  const { loading, data, refetch } = useListEntitiesQuery();
  const { auth } = useAuth();
  const [addTagsToEntityAction] = useAddTagsToEntityMutation();
  const [showCreateEntityModal, setShowCreateEntityModal] =
    useState(false);
  const [showAddTagModal, setShowAddTagModal] =
    useState(false);
  const [selectedRows, setSelectedRows] = useState<SelectedRowData[]>([]);

  const listOrgTags = useMemo(() => {
    return tagsData?.listTags?.map((tag) => {
      return {
        id: tag.id,
        value: tag.name,
      };
    });
  }, [tagsData]);


  const columns: ColumnDef<Partial<Entity>>[] = [
    {
      id: 'select',
      /*  header: ({ table }) => (
         <IndeterminateCheckbox
           {...{
             checked: table.getIsAllRowsSelected(),
             indeterminate: table.getIsSomeRowsSelected(),
             onChange: table.getToggleAllRowsSelectedHandler(),
           }}
         />
       ), */
      cell: ({ row }) => (
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
    },
    {
      accessorKey: "legalName",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            className="p-0"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Legal Name
            <HiOutlineSwitchVertical className="h-4 w-4" />
          </Button>
        )
      },
      cell: ({ row }) => {
        const id = row.original.id;
        return <Link className="underline" to={`/entities/${id}`}>{row.original.legalName}</Link>
      },
    },
    {
      accessorKey: "tags",
      header: "Tags",
      cell: ({ row }) => {
        return (
          <div className="flex flex-wrap items-center">
            {row.original.tags?.map((tag) => (
              <Tag key={tag.id} value={tag.name} />
            ))}
          </div>
        )
      }
    },
    {
      accessorKey: "access",
      header: "Access",
      cell: ({ row }) => <span>{titlecase(row.original.visibility === 'public' ? 'Public' : row.original.visibility)}</span>
    },
    {
      accessorKey: "createdAt",
      header: () => <span className="flex justify-end">Created At</span>,
      cell: ({ row }) => {
        const date = new Date(row.original.createdAt).toLocaleDateString();
        return (
          <span className="flex justify-end">{date}</span>
        )
      },
    },
  ]

  const handleOnSelectedRow = (rowId: string, rowName: string, tags: TagData[]) => {
    setSelectedRows((prev) => {
      if (prev.find((row) => row.id === rowId)) {
        return prev.filter((row) => row.id !== rowId);
      } else {
        return [...prev, { id: rowId, name: rowName, tags }];
      }
    });
  }

  const handleAddTagsToEntity = (tags) => {
    addTagsToEntityAction({
      variables: {
        input: {
          entityIds: selectedRows[0].id,
          tags: tags
        }
      },
      onCompleted: (data) => {
        if (data?.addTagsToEntity?.errors?.length) {
          toast.error(data?.addTagsToEntity?.errors.join(', \n'));
        } else {
          setShowAddTagModal((prev) => !prev);
          toast.success("Tags added successfully");
          refetch();
        }
      },
    });
  }

  const handleOnSelectTags = (tags: TagData[]) => {
    const tagsIdsData = tags?.map((tag) => tag.id).filter((id): id is string => id !== undefined);
    if (tagsIdsData && tagsIdsData.length === 0) {
      refetch({ tagIds: undefined });
    } else {
      refetch({ tagIds: tagsIdsData });
    }
  };

  return (
    <>
      <WithLoader loading={loading}>
        <PageLayout
          className="list-entities"
          header="Entities"
          headerActions={
            <>
              <Button
                onClick={() => {
                  setShowAddTagModal((prev) => !prev);
                }}
                variant={selectedRows.length === 0 ? "ghost" : "secondary"}
                disabled={selectedRows.length === 0}
              >
                Add tags
              </Button>
              {auth.user?.role === "admin" &&
                <Button
                  onClick={() => {
                    setShowCreateEntityModal((prev) => !prev);
                  }}
                >
                  Create new entity
                </Button>
              }
            </>
          }
        >
          <DataTable
            columns={columns}
            data={data?.listEntities ?? []}
            searchByKey="legalName"
            onRowSelection={handleOnSelectedRow}
            extraControl={
              <ComboboxMultiSelect
                tags={listOrgTags || []}
                onChangeTags={handleOnSelectTags}
              />
            }
          />
        </PageLayout>
      </WithLoader >

      {showCreateEntityModal && <CreateEntityModal
        visible={showCreateEntityModal}
        onEntityCreated={() => refetch()}
        onClose={() => {
          setShowCreateEntityModal(false);
        }} />}
      {showAddTagModal && <AddTagsModal
        listOrgTags={listOrgTags}
        selectedRowsData={selectedRows[0]}
        visible={showAddTagModal}
        onClose={() => setShowAddTagModal(false)}
        onSubmit={handleAddTagsToEntity}
      />}
    </>
  );
};

export default ListEntities;
