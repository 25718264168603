import React from 'react';
import { Separator } from '@radix-ui/react-separator';
import { FieldArray } from 'formik';
import IndividualForm, { generateNewIndividual } from './IndividualForm';
import { Button } from '../core/Button';
import Combobox from '../core/Combobox';
import { useListIndividualsQuery } from './new/__generated__/EditBeneficialOwners.generated';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useDeleteIndividualMutation } from './__generated__/EntityDetails.generated';

export default ({
  values,
  handleChange,
  setFieldValue,
  onAddCompanyApp
}) => {

  const { entityId } = useParams();

  const [deleteIndividualAction,] = useDeleteIndividualMutation();
  const { loading: loadingIndividualList, data, refetch } = useListIndividualsQuery({
    variables: {
      individualType: "company_applicant",
      searchParam: "",
      entityId: entityId,
      page: 1,
      limit: 10,
    }
  });

  const handleOnSelectBoi = (currentValue: string) => {
    const selectedCompanyApp = data?.listIndividuals?.collection.find((companyApp) =>
      companyApp?.firstName?.toLocaleLowerCase() === currentValue);
    if (selectedCompanyApp) {
      setFieldValue('companyApplicants', [...values.companyApplicants, selectedCompanyApp]);
      onAddCompanyApp(true);
    }
  }

  const handleOnSearchCompanyApp = (searchValue: string) => {
    console.log('searchValue', searchValue);
  };

  const handleDeleteIndividual = (individualId) => {
    deleteIndividualAction({
      variables: {
        input: {
          id: individualId
        }
      },
      onCompleted: (data) => {
        if (data?.deleteIndividual?.errors?.length) {
          toast.error("Something went wrong");
        } else {
          refetch({
            individualType: "company_applicant",
            searchParam: ""
          });
          toast.success("Company applicant successfully removed.")
        }
      }
    })
  };

  return (
    <FieldArray
      name="companyApplicants"
      render={(arrayHelpers) => (
        <>
          <div className='flex grow items-center justify-between'>
            <Combobox
              data={data?.listIndividuals?.collection}
              placeholder='Search for existing company applicants...'
              onSelectBoi={handleOnSelectBoi}
              onSearch={handleOnSearchCompanyApp}
            />
            <Button
              onClick={() => {
                arrayHelpers.push(generateNewIndividual());
                onAddCompanyApp(true);
              }}
            >
              Add new company applicant
            </Button>
          </div>
          <div className="space-y-4 mt-2">
            {values?.companyApplicants?.map((owner, index) => (
              <div key={owner?.id} className="space-y-4">
                <IndividualForm
                  key={owner.id}
                  value={owner}
                  onChange={(ownerValue) => {
                    setFieldValue(
                      `companyApplicants.${index}`,
                      ownerValue
                    );
                  }}
                  title={`Company Applicant ${index + 1}`}
                  onDelete={() => {
                    arrayHelpers.remove(index);
                    handleDeleteIndividual(owner.id)
                  }}
                />
                <Separator />
              </div>
            ))}
          </div>
        </>
      )}
    />
  )
}