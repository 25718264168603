export const AUTH_TOKEN_KEY = 'token';

export const getAuthToken = () => {
  return localStorage.getItem(AUTH_TOKEN_KEY);
}

export const signInUser = (authToken) => {
  localStorage.setItem(AUTH_TOKEN_KEY, authToken);
}

export const signOutUser = () => {
  localStorage.removeItem(AUTH_TOKEN_KEY);
}