import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { IndividualFormIndividualFragmentFragmentDoc } from '../../__generated__/IndividualForm.generated';
import { BeneficialOwnerFormEntityFragmentFragmentDoc } from '../../../filings/__generated__/EditBeneficialOwners.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BeneficialOwnerFormEntityFragmentFragment = { __typename?: 'Entity', id: string, beneficialOwners?: Array<{ __typename?: 'Individual', id: string, fincenId?: string | null, isParentGuardianInfo?: boolean | null, firstName?: string | null, lastName?: string | null, middleName?: string | null, suffix?: string | null, dateOfBirth?: any | null, dateOfBirthUnknown?: boolean | null, identificationType?: string | null, identificationNumber?: string | null, issuingState?: string | null, identificationIssuingJurisdiction?: string | null, addressType?: string | null, identificationDocument?: { __typename?: 'Attachment', url: string, contentType: string, filename: string } | null, address?: { __typename?: 'Address', id: string, nameLine?: string | null, addressLine1?: string | null, addressLine2?: string | null, administrativeArea?: string | null, locality?: string | null, country?: string | null, postalCode?: string | null } | null }> | null };

export type GetEntityBeneficialOwnerDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetEntityBeneficialOwnerDetailsQuery = { __typename?: 'Query', getEntity: { __typename?: 'Entity', id: string, beneficialOwners?: Array<{ __typename?: 'Individual', id: string, fincenId?: string | null, isParentGuardianInfo?: boolean | null, firstName?: string | null, lastName?: string | null, middleName?: string | null, suffix?: string | null, dateOfBirth?: any | null, dateOfBirthUnknown?: boolean | null, identificationType?: string | null, identificationNumber?: string | null, issuingState?: string | null, identificationIssuingJurisdiction?: string | null, addressType?: string | null, identificationDocument?: { __typename?: 'Attachment', url: string, contentType: string, filename: string } | null, address?: { __typename?: 'Address', id: string, nameLine?: string | null, addressLine1?: string | null, addressLine2?: string | null, administrativeArea?: string | null, locality?: string | null, country?: string | null, postalCode?: string | null } | null }> | null } };

export type UpdateEntityForBeneficialOwnerFormMutationVariables = Types.Exact<{
  input: Types.UpdateEntityInput;
}>;


export type UpdateEntityForBeneficialOwnerFormMutation = { __typename?: 'Mutation', updateEntity?: { __typename?: 'UpdateEntityPayload', errors?: Array<string> | null, entity?: { __typename?: 'Entity', id: string, beneficialOwners?: Array<{ __typename?: 'Individual', id: string, fincenId?: string | null, isParentGuardianInfo?: boolean | null, firstName?: string | null, lastName?: string | null, middleName?: string | null, suffix?: string | null, dateOfBirth?: any | null, dateOfBirthUnknown?: boolean | null, identificationType?: string | null, identificationNumber?: string | null, issuingState?: string | null, identificationIssuingJurisdiction?: string | null, addressType?: string | null, identificationDocument?: { __typename?: 'Attachment', url: string, contentType: string, filename: string } | null, address?: { __typename?: 'Address', id: string, nameLine?: string | null, addressLine1?: string | null, addressLine2?: string | null, administrativeArea?: string | null, locality?: string | null, country?: string | null, postalCode?: string | null } | null }> | null } | null } | null };

export type ListIndividualsQueryVariables = Types.Exact<{
  individualType: Types.Scalars['String']['input'];
  searchParam?: Types.InputMaybe<Types.Scalars['String']['input']>;
  entityId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type ListIndividualsQuery = { __typename?: 'Query', listIndividuals?: { __typename?: 'IndividualCollection', collection: Array<{ __typename?: 'Individual', id: string, fincenId?: string | null, isParentGuardianInfo?: boolean | null, firstName?: string | null, lastName?: string | null, middleName?: string | null, suffix?: string | null, dateOfBirth?: any | null, dateOfBirthUnknown?: boolean | null, identificationType?: string | null, identificationNumber?: string | null, issuingState?: string | null, identificationIssuingJurisdiction?: string | null, addressType?: string | null, identificationDocument?: { __typename?: 'Attachment', url: string, contentType: string, filename: string } | null, address?: { __typename?: 'Address', id: string, nameLine?: string | null, addressLine1?: string | null, addressLine2?: string | null, administrativeArea?: string | null, locality?: string | null, country?: string | null, postalCode?: string | null } | null }>, metadata: { __typename?: 'CollectionMetadata', totalPages: number, totalCount: number, currentPage: number, limitValue: number } } | null };

export const BeneficialOwnerFormEntityFragmentFragmentDoc = gql`
    fragment BeneficialOwnerFormEntityFragment on Entity {
  id
  beneficialOwners {
    ...IndividualFormIndividualFragment
  }
}
    ${IndividualFormIndividualFragmentFragmentDoc}`;
export const GetEntityBeneficialOwnerDetailsDocument = gql`
    query getEntityBeneficialOwnerDetails($id: ID!) {
  getEntity(id: $id) {
    ...BeneficialOwnerFormEntityFragment
  }
}
    ${BeneficialOwnerFormEntityFragmentFragmentDoc}`;

/**
 * __useGetEntityBeneficialOwnerDetailsQuery__
 *
 * To run a query within a React component, call `useGetEntityBeneficialOwnerDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntityBeneficialOwnerDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntityBeneficialOwnerDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEntityBeneficialOwnerDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetEntityBeneficialOwnerDetailsQuery, GetEntityBeneficialOwnerDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEntityBeneficialOwnerDetailsQuery, GetEntityBeneficialOwnerDetailsQueryVariables>(GetEntityBeneficialOwnerDetailsDocument, options);
      }
export function useGetEntityBeneficialOwnerDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEntityBeneficialOwnerDetailsQuery, GetEntityBeneficialOwnerDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEntityBeneficialOwnerDetailsQuery, GetEntityBeneficialOwnerDetailsQueryVariables>(GetEntityBeneficialOwnerDetailsDocument, options);
        }
export function useGetEntityBeneficialOwnerDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetEntityBeneficialOwnerDetailsQuery, GetEntityBeneficialOwnerDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEntityBeneficialOwnerDetailsQuery, GetEntityBeneficialOwnerDetailsQueryVariables>(GetEntityBeneficialOwnerDetailsDocument, options);
        }
export type GetEntityBeneficialOwnerDetailsQueryHookResult = ReturnType<typeof useGetEntityBeneficialOwnerDetailsQuery>;
export type GetEntityBeneficialOwnerDetailsLazyQueryHookResult = ReturnType<typeof useGetEntityBeneficialOwnerDetailsLazyQuery>;
export type GetEntityBeneficialOwnerDetailsSuspenseQueryHookResult = ReturnType<typeof useGetEntityBeneficialOwnerDetailsSuspenseQuery>;
export type GetEntityBeneficialOwnerDetailsQueryResult = Apollo.QueryResult<GetEntityBeneficialOwnerDetailsQuery, GetEntityBeneficialOwnerDetailsQueryVariables>;
export const UpdateEntityForBeneficialOwnerFormDocument = gql`
    mutation updateEntityForBeneficialOwnerForm($input: UpdateEntityInput!) {
  updateEntity(input: $input) {
    entity {
      ...BeneficialOwnerFormEntityFragment
    }
    errors
  }
}
    ${BeneficialOwnerFormEntityFragmentFragmentDoc}`;
export type UpdateEntityForBeneficialOwnerFormMutationFn = Apollo.MutationFunction<UpdateEntityForBeneficialOwnerFormMutation, UpdateEntityForBeneficialOwnerFormMutationVariables>;

/**
 * __useUpdateEntityForBeneficialOwnerFormMutation__
 *
 * To run a mutation, you first call `useUpdateEntityForBeneficialOwnerFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntityForBeneficialOwnerFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntityForBeneficialOwnerFormMutation, { data, loading, error }] = useUpdateEntityForBeneficialOwnerFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEntityForBeneficialOwnerFormMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEntityForBeneficialOwnerFormMutation, UpdateEntityForBeneficialOwnerFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEntityForBeneficialOwnerFormMutation, UpdateEntityForBeneficialOwnerFormMutationVariables>(UpdateEntityForBeneficialOwnerFormDocument, options);
      }
export type UpdateEntityForBeneficialOwnerFormMutationHookResult = ReturnType<typeof useUpdateEntityForBeneficialOwnerFormMutation>;
export type UpdateEntityForBeneficialOwnerFormMutationResult = Apollo.MutationResult<UpdateEntityForBeneficialOwnerFormMutation>;
export type UpdateEntityForBeneficialOwnerFormMutationOptions = Apollo.BaseMutationOptions<UpdateEntityForBeneficialOwnerFormMutation, UpdateEntityForBeneficialOwnerFormMutationVariables>;
export const ListIndividualsDocument = gql`
    query listIndividuals($individualType: String!, $searchParam: String, $entityId: ID, $page: Int, $limit: Int) {
  listIndividuals(
    individualType: $individualType
    searchParam: $searchParam
    entityId: $entityId
    page: $page
    limit: $limit
  ) {
    collection {
      ...IndividualFormIndividualFragment
    }
    metadata {
      totalPages
      totalCount
      currentPage
      limitValue
    }
  }
}
    ${IndividualFormIndividualFragmentFragmentDoc}`;

/**
 * __useListIndividualsQuery__
 *
 * To run a query within a React component, call `useListIndividualsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListIndividualsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListIndividualsQuery({
 *   variables: {
 *      individualType: // value for 'individualType'
 *      searchParam: // value for 'searchParam'
 *      entityId: // value for 'entityId'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useListIndividualsQuery(baseOptions: Apollo.QueryHookOptions<ListIndividualsQuery, ListIndividualsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListIndividualsQuery, ListIndividualsQueryVariables>(ListIndividualsDocument, options);
      }
export function useListIndividualsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListIndividualsQuery, ListIndividualsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListIndividualsQuery, ListIndividualsQueryVariables>(ListIndividualsDocument, options);
        }
export function useListIndividualsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListIndividualsQuery, ListIndividualsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListIndividualsQuery, ListIndividualsQueryVariables>(ListIndividualsDocument, options);
        }
export type ListIndividualsQueryHookResult = ReturnType<typeof useListIndividualsQuery>;
export type ListIndividualsLazyQueryHookResult = ReturnType<typeof useListIndividualsLazyQuery>;
export type ListIndividualsSuspenseQueryHookResult = ReturnType<typeof useListIndividualsSuspenseQuery>;
export type ListIndividualsQueryResult = Apollo.QueryResult<ListIndividualsQuery, ListIndividualsQueryVariables>;