import React from "react";
import { HiPlus, HiTrash } from "react-icons/hi";

import Input from "./Input";
import SmallButton from "./SmallButton";

type MultiTextInputProps = {
  value: string[];
  type?: string;
  placeholder?: string;
  onChange: (value: string[]) => void;
  buttonLabel?: string;
};

const MultiTextInput: React.FC<MultiTextInputProps> = (
  props: MultiTextInputProps
) => {
  const { value, type, buttonLabel, placeholder, onChange } = props;

  const addItem = () => {
    onChange([...value, ""]);
  };

  const deleteItem = (index: number) => {
    onChange(value.filter((_, i) => i !== index));
  };

  const updateItem = (index: number, newValue: string) => {
    const updatedItems = [...value];
    updatedItems[index] = newValue;
    onChange(updatedItems);
  };

  return (
    <div className="flex flex-col gap-4">
      {value.map((item, index) => (
        <div className="sm:col-span-4 flex">
          <Input
            id={`item-${index}`}
            value={item}
            onChange={(e) => { updateItem(index, e.target.value) }}
            name={`item-${index}`}
            type={type}
            className="w-full"
            placeholder={placeholder}
          />
          <SmallButton onClick={() => { deleteItem(index) }} className="p-0 m-0 px-0 py-0 ml-2">
            <HiTrash color="red" size={20} aria-hidden="true" />
          </SmallButton>
        </div>
      ))}
      <SmallButton
        onClick={addItem}
        className="flex gap-2 w-min whitespace-nowrap items-center"
      >
        <HiPlus aria-hidden="true" />
        {buttonLabel}
      </SmallButton>
    </div>
  );
};

MultiTextInput.defaultProps = {
  buttonLabel: "Add item",
  type: "text",
};

export default MultiTextInput;
