import React, { useEffect } from 'react';
import { gql } from '@apollo/client';

import { useAuth } from './authContext';
import { useGetSignedInUserLazyQuery } from './__generated__/InitialLoginHandler.generated';
import { signInUser } from '../../utils/auth';
import { USER_FRAGMENT } from './types/UserFragment';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';


const GET_SIGNED_IN_USER = gql`
  query getSignedInUser {
    getSignedInUser {
      ...AuthUserType
    }
  }
   ${USER_FRAGMENT}
`;

export default () => {
  const { auth, setAuthStatus, setUserData, setAuth } = useAuth();
  const [getSignedInUserAction] = useGetSignedInUserLazyQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.authenticated) {
      getSignedInUserAction().then((response) => {
        const currentUser = response.data?.getSignedInUser
        if (currentUser) {
          signInUser(response.data?.getSignedInUser?.authenticationToken);

          setAuthStatus(true);
          setUserData(response.data?.getSignedInUser)
        }
        setAuth({ initialAuthAttempted: true })
        setAuth({ tokenExpired: false })
      });
    }
  }, [])

  useEffect(() => {
    if (auth.tokenExpired) {
      setUserData(null);
      navigate('/login', { replace: true });
      toast.success('Logged out successfully, see you soon!')
    }
  }, [auth.tokenExpired])

  return (
    <></>
  )
}