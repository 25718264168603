import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AddressFragmentFragmentDoc } from '../../../utils/__generated__/address.generated';
export type IndividualFragmentFragment = { __typename?: 'Individual', id: string, firstName?: string | null, dateOfBirth?: any | null, identificationNumber?: string | null, identificationIssuingJurisdiction?: string | null, address?: { __typename?: 'Address', id: string, nameLine?: string | null, addressLine1?: string | null, addressLine2?: string | null, administrativeArea?: string | null, locality?: string | null, country?: string | null, postalCode?: string | null } | null };

export const IndividualFragmentFragmentDoc = gql`
    fragment IndividualFragment on Individual {
  id
  firstName
  dateOfBirth
  identificationNumber
  identificationIssuingJurisdiction
  address {
    ...AddressFragment
  }
}
    ${AddressFragmentFragmentDoc}`;