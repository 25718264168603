import React, { useState } from "react";
import { Button } from "../../core/Button";
import { Formik } from "formik";
import EntityLayout from "./EntityLayout";
import { gql } from "@apollo/client";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useUpdateEntityMutation } from "./__generated__/EditEntityDetails.generated";
import { stripTypename } from "@apollo/client/utilities";
import CompanyApplicantFormFields from "../CompanyApplicantForm";
import { INDIVIDUAL_FRAGMENT, trimMetadata } from "../../entities/IndividualForm";
import { useGetEntityCompanyApplicantDetailsQuery } from "./__generated__/EditCompanyApplicants.generated";

const ENTITY_FRAGMENT = gql`
  fragment CompanyApplicantFormEntityFragment on Entity {
    id
    dateOfFormation
    companyApplicants {
      ...IndividualFormIndividualFragment
    }
  }
  ${INDIVIDUAL_FRAGMENT}
`;

const GET_ENTITY = gql`
  query getEntityCompanyApplicantDetails($id: ID!) {
    getEntity(id: $id) {
        ...CompanyApplicantFormEntityFragment
    }
  }
  ${ENTITY_FRAGMENT}
`;

const UPDATE_ENTITY = gql`
  mutation updateEntityForCompanyApplicantForm($input: UpdateEntityInput!) {
    updateEntity(input: $input) {
      entity {
        ...CompanyApplicantFormEntityFragment
      }
      errors
    }
  }
  ${ENTITY_FRAGMENT}
`;

export default () => {
  const { entityId } = useParams();

  const { data, loading, refetch } = useGetEntityCompanyApplicantDetailsQuery({
    variables: {
      id: entityId || "",
    },
  });

  const [isAddedNewCompanyApp, setIsAddedNewCompanyApp] = useState(false);

  const [updateEntityAction, { loading: loadingUpdateEntity }] =
    useUpdateEntityMutation();

  const navigate = useNavigate();

  const initialValues = stripTypename(data?.getEntity);

  const handleUpdateEntity = (values) => {
    const dateOfFormation = new Date(data?.getEntity?.dateOfFormation);
    console.log({dateOfFormation}, dateOfFormation < new Date('2024-01-01'))
    if (values.companyApplicants.length === 0 && dateOfFormation && dateOfFormation > new Date('2024-01-01')) {
      toast.error("Please add at least one company applicant");
      return;
    }

    let params = {
      ...values,
      id: undefined,
      companyApplicants: values.companyApplicants.map(trimMetadata),
    };

    updateEntityAction({
      variables: {
        input: {
          id: values.id,
          params: params,
        },
      },
      onCompleted: (data) => {
        if (data?.updateEntity?.errors?.length) {
          toast.error(data?.updateEntity?.errors.join(', \n'));
        } else {
          toast.success("Entity updated successfully");
          if (!isAddedNewCompanyApp) {
            navigate(`/entities/${entityId}`);
          }
          refetch();
        }
      },
      onError: (error) => {
        toast.error("Something went wrong");
      },
    });

    setIsAddedNewCompanyApp(false);
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues || {}}
      onSubmit={handleUpdateEntity}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <EntityLayout>
            <EntityLayout.Body>
              <div className="border rounded-md p-8">
                <h1 className="text-2xl font-semibold mb-4">Company Applicants</h1>
                <CompanyApplicantFormFields
                  values={values}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  onAddCompanyApp={(isNewCompanyApplicant) => {
                    setIsAddedNewCompanyApp(isNewCompanyApplicant);
                    handleSubmit();
                  }}
                />
              </div>
            </EntityLayout.Body>
            <EntityLayout.Actions>
              <Button
                onClick={() => {
                  handleSubmit();
                }}
              >
                Save
              </Button>
            </EntityLayout.Actions>
          </EntityLayout>
        </form>
      )}
    </Formik>
  );
};
