

import { Modal } from 'flowbite-react';
import React, { useState } from 'react';
import { HiOutlineLockClosed } from "react-icons/hi";
import { HiOutlineGlobeAlt } from "react-icons/hi";
import Select from '../core/Select';
import MultiTextInput from '../core/MultiTextInput';
import { Button } from '../core/Button';

interface IVisibilityModalProps {
    entity: any;
    visible: boolean;
    onClose: () => void;
    onSubmit: (visibility: string, people: string[]) => void;
};


const VisibilityModal = (props: IVisibilityModalProps) => {

    const { entity, visible, onClose, onSubmit, } = props;
    const [selectedVisibility, setSelectedVisibility] = useState(entity?.visibility);
    const [selectedPeople, setSelectedPeople] = useState(['']);

    return (
        <Modal
            show={visible}
            onClose={onClose}
            size='lg'
        >
            <Modal.Header>Entity Visibility</Modal.Header>
            <Modal.Body className="overflow-visible" style={{ paddingTop: 16, paddingBottom: 16 }}>
                <div className="space-y-4">
                    <h5 className="font-semibold">General access</h5>
                    <div className="flex flex-col">
                        <div className='flex flex-row items-center'>
                            <div className='flex flex-col'>
                                {selectedVisibility === 'private' ? <HiOutlineLockClosed size={25} className='mr-2' /> :
                                    <HiOutlineGlobeAlt size={25} className='mr-2' />
                                }
                            </div>
                            <div className='flex flex-col w-2/4'>
                                <Select
                                    options={
                                        [
                                            {
                                                label: "Public",
                                                value: "public"
                                            },
                                            {
                                                label: "Private",
                                                value: "private"
                                            }
                                        ]
                                    }
                                    value={selectedVisibility}
                                    onChange={(value) => { setSelectedVisibility(value) }}
                                />
                            </div>
                        </div>
                        <div className='flex flex-row'>
                            <span className="text-sm text-gray-600 mt-2">
                                {selectedVisibility === 'private' && "Only people with access can view and edit this entity"}
                                {selectedVisibility === 'public' && "Anyone can view and edit this entity"}
                            </span>
                        </div>
                    </div>
                    <hr className="my-12 h-0.5 border-t-0 bg-neutral-100 dark:bg-white/10" />
                    <h5 className="font-semibold">People with access</h5>
                    {entity.invitedUsers?.map((user, id) => (
                        <div key={id} className="flex flex-row items-center rounded bg-slate-50 px-2 py-1 border hover:border-indigo-500 hover:text-indigo-500 cursor-pointer">
                            <div className='flex flex-col grow'>
                                <span className="font-semibold text-sm text-gray-900">
                                    {user.fullName}
                                </span>
                                <span className="text-sm text-gray-600">{user.email}</span>
                            </div><div className='flex flex-col'>
                                <span className="text-sm text-gray-800">{user.role}</span>
                            </div>
                        </div>
                    ))}
                    <div className='flex flex-col grow'>
                        <MultiTextInput
                            value={selectedPeople}
                            type='email'
                            placeholder='Add more people to entity'
                            buttonLabel='Add person'
                            onChange={(value) => setSelectedPeople([...value])}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="justify-between">
                <Button variant='outline' onClick={onClose}>
                    Close
                </Button>
                <Button onClick={() => onSubmit?.(selectedVisibility, selectedPeople)}>
                    Done
                </Button>
            </Modal.Footer>
        </Modal>
    )

};

export default VisibilityModal;