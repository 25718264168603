import React from "react";
import { Button } from "../core/Button";
import EntityDetailsForm from "../entities/EntityDetailsForm";
import { Formik } from "formik";
import FilingLayout from "./FilingLayout";
import { gql } from "@apollo/client";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useGetFilingEntityDetailsQuery } from "./__generated__/EditFiling.generated";
import { useUpdateEntityMutation } from "./__generated__/EditEntityDetails.generated";
import { stripTypename } from "@apollo/client/utilities";
import CompanyApplicantFormFields from "../entities/CompanyApplicantForm";
import { INDIVIDUAL_FRAGMENT, trimMetadata } from "../entities/IndividualForm";
import { useGetFilingCompanyApplicantDetailsQuery } from "./__generated__/EditCompanyApplicants.generated";
// import {
//   useGetFilingCompanyApplicantDetailsLazyQuery,
//   useGetFilingCompanyApplicantDetailsQuery,
// } from "./__generated__/UpdateCompanyApplicants.generated";

const ENTITY_FRAGMENT = gql`
  fragment FilingCompanyApplicantFormEntityFragment on Entity {
    id
    dateOfFormation
    companyApplicants {
      ...IndividualFormIndividualFragment
    }
  }
  ${INDIVIDUAL_FRAGMENT}
`;

const GET_FILING = gql`
  query getFilingCompanyApplicantDetails($id: ID!) {
    getFiling(id: $id) {
      entity {
        ...FilingCompanyApplicantFormEntityFragment
      }
    }
  }
  ${ENTITY_FRAGMENT}
`;

const UPDATE_ENTITY = gql`
  mutation updateFilingEntityForCompanyApplicantForm($input: UpdateEntityInput!) {
    updateEntity(input: $input) {
      entity {
        ...FilingCompanyApplicantFormEntityFragment
      }
      errors
    }
  }
  ${ENTITY_FRAGMENT}
`;

export default () => {
  const { entityId, filingId } = useParams();

  const { data, loading } = useGetFilingCompanyApplicantDetailsQuery({
    variables: {
      id: filingId || "",
    },
  });

  const [updateEntityAction, { loading: loadingUpdateEntity }] =
    useUpdateEntityMutation();

  const navigate = useNavigate();

  const initialValues = stripTypename(data?.getFiling?.entity);

  const handleUpdateEntity = (values) => {


    const dateOfFormation = new Date(data?.getFiling?.entity?.dateOfFormation);
    if (values.companyApplicants.length === 0 && dateOfFormation && dateOfFormation > new Date('2024-01-01')) {
      toast.error("Please add at least one company applicant");
      return;
    }

    let params = {
      ...values,
      id: undefined,
      companyApplicants: values.companyApplicants.map(trimMetadata),
    };

    updateEntityAction({
      variables: {
        input: {
          id: values.id,
          params: params,
        },
      },
      onCompleted: (data) => {
        if (data?.updateEntity?.errors?.length) {
          toast.error(data?.updateEntity?.errors.join(', \n'))
        } else {
          toast.success("Entity updated successfully");
          navigate(`/edit-filing/${entityId}/${filingId}/review`);
        }
      },
      onError: (error) => {
        toast.error("Something went wrong");
      },
    });
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues || {}}
      onSubmit={handleUpdateEntity}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <FilingLayout>
            <FilingLayout.Body>
              <div className="border rounded-md p-8">
                <h1 className="text-2xl font-semibold mb-4">Company Applicants</h1>
                <CompanyApplicantFormFields
                  values={values}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
              </div>
            </FilingLayout.Body>
            <FilingLayout.Actions>
              <Button variant="outline" onClick={() => handleSubmit()}>
                Save and Exit
              </Button>
              <Button
                onClick={() => {
                  handleSubmit();
                }}
              >
                Save and Continue
              </Button>
            </FilingLayout.Actions>
          </FilingLayout>
        </form>
      )}
    </Formik>
  );
};
