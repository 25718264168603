import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteDraftFilingMutationVariables = Types.Exact<{
  input: Types.DeleteDraftFilingInput;
}>;


export type DeleteDraftFilingMutation = { __typename?: 'Mutation', deleteDraftFiling?: { __typename?: 'DeleteDraftFilingPayload', id?: string | null, errors?: Array<string> | null } | null };

export type SubmitFilingMutationVariables = Types.Exact<{
  input: Types.SubmitFilingInput;
}>;


export type SubmitFilingMutation = { __typename?: 'Mutation', submitFiling?: { __typename?: 'SubmitFilingPayload', errors?: Array<string> | null, filing?: { __typename?: 'Filing', id: string, status: string, entity: { __typename?: 'Entity', id: string } } | null } | null };


export const DeleteDraftFilingDocument = gql`
    mutation deleteDraftFiling($input: DeleteDraftFilingInput!) {
  deleteDraftFiling(input: $input) {
    id
    errors
  }
}
    `;
export type DeleteDraftFilingMutationFn = Apollo.MutationFunction<DeleteDraftFilingMutation, DeleteDraftFilingMutationVariables>;

/**
 * __useDeleteDraftFilingMutation__
 *
 * To run a mutation, you first call `useDeleteDraftFilingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDraftFilingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDraftFilingMutation, { data, loading, error }] = useDeleteDraftFilingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDraftFilingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDraftFilingMutation, DeleteDraftFilingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDraftFilingMutation, DeleteDraftFilingMutationVariables>(DeleteDraftFilingDocument, options);
      }
export type DeleteDraftFilingMutationHookResult = ReturnType<typeof useDeleteDraftFilingMutation>;
export type DeleteDraftFilingMutationResult = Apollo.MutationResult<DeleteDraftFilingMutation>;
export type DeleteDraftFilingMutationOptions = Apollo.BaseMutationOptions<DeleteDraftFilingMutation, DeleteDraftFilingMutationVariables>;
export const SubmitFilingDocument = gql`
    mutation submitFiling($input: SubmitFilingInput!) {
  submitFiling(input: $input) {
    filing {
      id
      status
      entity {
        id
      }
    }
    errors
  }
}
    `;
export type SubmitFilingMutationFn = Apollo.MutationFunction<SubmitFilingMutation, SubmitFilingMutationVariables>;

/**
 * __useSubmitFilingMutation__
 *
 * To run a mutation, you first call `useSubmitFilingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitFilingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitFilingMutation, { data, loading, error }] = useSubmitFilingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitFilingMutation(baseOptions?: Apollo.MutationHookOptions<SubmitFilingMutation, SubmitFilingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitFilingMutation, SubmitFilingMutationVariables>(SubmitFilingDocument, options);
      }
export type SubmitFilingMutationHookResult = ReturnType<typeof useSubmitFilingMutation>;
export type SubmitFilingMutationResult = Apollo.MutationResult<SubmitFilingMutation>;
export type SubmitFilingMutationOptions = Apollo.BaseMutationOptions<SubmitFilingMutation, SubmitFilingMutationVariables>;