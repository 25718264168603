import React from 'react';
import Label from '../core/Label';
import Input from '../core/Input';
import MultiTextInput from '../core/MultiTextInput';
import DatePicker from '../core/DatePicker';
import { parseISO } from 'date-fns';
import { trimTime } from '../../utils/time';
import AddressInput from '../core/AddressInput';
import { RadioGroup, RadioGroupItem } from '../core/RadioGroup';
import CountrySelect from '../core/CountrySelect';
import UsaStateSelect from '../core/UsaStateSelect';
import Select from '../core/Select';

const identificationTypes = [
  { name: "EIN", code: "ein" },
  { name: "SSN", code: "ssn" },
  { name: "Foreign", code: "foreign" },
];


export default ({
  values,
  handleChange,
  setFieldValue,
}) => {

  console.log({values})

  return (
    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8">
      <div className="sm:col-span-4">
        <h3 className="text-lg font-semibold leading-9 tracking-tight text-gray-900 pb-2">
          Company details
        </h3>
        <div className="flex flex-col gap-4">
          <div className="flex flex-row gap-2">
            <div className="w-1/2">
              <Label>Reporting Company Legal Name *</Label>
              <Input
                id="legalName"
                value={values.legalName}
                onChange={handleChange}
                name="legalName"
                type="text"
                required
              />
            </div>
            <div className="w-1/2">
              <Label>Entity Type</Label>
              <Input
                id="entityType"
                value={values.entityType}
                onChange={handleChange}
                name="entityType"
                type="text"
              />
            </div>
          </div>
          <div className="flex flex-row gap-2">
            <div className="w-1/2">
              <CountrySelect
                label="Country/Jurisdiction of Formation *"
                value={values.jurisdictionCountry}
                handleOnChange={(value) =>
                  setFieldValue("jurisdictionCountry", value)
                }
              />
            </div>
            {values.jurisdictionCountry === "United States" && (
              <div className="w-1/2">
                <UsaStateSelect
                  label="US State/Jurisdiction of Formation"
                  value={values.jurisdictionState}
                  handleOnChange={(value) =>
                    setFieldValue("jurisdictionState", value)
                  }
                />
              </div>
            )}

          </div>

          <div className="flex flex-col gap-2">
            <div className="flex flex-row gap-2">
              <div className="flex flex-col flex-1">
                <Label>Date of Formation *</Label>
                <DatePicker
                  value={parseISO(
                    trimTime(
                      values.dateOfFormation || new Date().toISOString()
                    )
                  )}
                  onChange={(dateOfFormation) =>
                    setFieldValue(
                      "dateOfFormation",
                      trimTime(dateOfFormation.toISOString())
                    )
                  }
                />
              </div>
              <div className="flex flex-col flex-2 mt-auto mb-0">
                <Label>
                  Was this entity created before January 2024?
                </Label>
                <RadioGroup
                  onValueChange={(value) =>
                    setFieldValue(
                      "isCreatedBeforeJan2024",
                      value === "true"
                    )
                  }
                  value={(values.isCreatedBeforeJan2024 === true).toString()}
                  className="flex flex-row space-y-1 items-center"
                >
                  <RadioGroupItem id="true" value="true" />
                  <Label htmlFor="true">Yes</Label>
                  <RadioGroupItem id="false" value="false" />
                  <Label htmlFor="false">No</Label>
                </RadioGroup>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sm:col-span-4">
        <h3 className="text-lg font-semibold leading-9 tracking-tight text-gray-900 pb-2">
          Additional Information
        </h3>
        <div className="flex flex-col gap-2">
          <Label>Alternate name (e.g. trade name, DBA)</Label>
          <div className='pb-4'>
            <MultiTextInput
              value={values.tradeNames || []}
              onChange={(value) => setFieldValue("tradeNames", value)}
            />
          </div>
        </div>

        <div className="flex flex-col gap-4">
          <div className="flex flex-row gap-2">
            <div className="w-1/2">
              <div className="mb-2">
                <Label>Tax Identification Type</Label>
                <Select
                  options={identificationTypes.map((idType) => ({
                    value: idType.code,
                    label: idType.name,
                  }))}
                  value={values.identificationType}
                  onChange={(value) => setFieldValue("identificationType", value)}
                  placeholder="Select a tax identification type"
                />
              </div>
              {values.identificationType === "Foreign" && (
                <CountrySelect
                  label="Country/Jurisdiction of Foreign Tax ID"
                  value={values.foreignTaxCountry}
                  handleOnChange={(value) =>
                    setFieldValue("foreignTaxCountry", value)
                  }
                />
              )}
            </div>
            <div className="w-1/2">
              <Label>Tax Identification Number</Label>
              <Input
                id="taxIdentificationNumber"
                value={values.taxIdentificationNumber}
                onChange={handleChange}
                name="taxIdentificationNumber"
                type="text"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="sm:col-span-4">
        <h3 className="text-lg font-semibold leading-9 tracking-tight text-gray-900 pb-2">
          Current US Address
        </h3>
        <Label>Address</Label>
        <div className="mt-2">
          <AddressInput
            value={values.address || {}}
            onChange={(value) => setFieldValue("address", value)}
          />
        </div>
      </div>
      <div className="sm:col-span-4">
        <Label>
          Is reporting company a foreign pooled investment vehicle?
        </Label>
        <div className="mt-2">
          <RadioGroup
            onValueChange={(value) =>
              setFieldValue(
                "isForeignPooledInvestmentVehicle",
                value === "true"
              )
            }
            value={(values.isForeignPooledInvestmentVehicle === true).toString()}
            className="flex flex-row space-y-1 items-center"
          >
            <RadioGroupItem id="true" value="true" />
            <Label htmlFor="true">True</Label>
            <RadioGroupItem id="false" value="false" />
            <Label htmlFor="false">False</Label>
          </RadioGroup>
        </div>
      </div>
    </div>
  )
}