import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AuthUserTypeFragmentDoc } from '../types/__generated__/UserFragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSignedInUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSignedInUserQuery = { __typename?: 'Query', getSignedInUser?: { __typename?: 'User', id: string, email: string, createdAt: any, role: string, authenticationToken?: string | null, fullName?: string | null, organization: { __typename?: 'Organization', name?: string | null }, invitation?: { __typename?: 'Invitation', status: string } | null } | null };


export const GetSignedInUserDocument = gql`
    query getSignedInUser {
  getSignedInUser {
    ...AuthUserType
  }
}
    ${AuthUserTypeFragmentDoc}`;

/**
 * __useGetSignedInUserQuery__
 *
 * To run a query within a React component, call `useGetSignedInUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignedInUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignedInUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSignedInUserQuery(baseOptions?: Apollo.QueryHookOptions<GetSignedInUserQuery, GetSignedInUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSignedInUserQuery, GetSignedInUserQueryVariables>(GetSignedInUserDocument, options);
      }
export function useGetSignedInUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSignedInUserQuery, GetSignedInUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSignedInUserQuery, GetSignedInUserQueryVariables>(GetSignedInUserDocument, options);
        }
export function useGetSignedInUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSignedInUserQuery, GetSignedInUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSignedInUserQuery, GetSignedInUserQueryVariables>(GetSignedInUserDocument, options);
        }
export type GetSignedInUserQueryHookResult = ReturnType<typeof useGetSignedInUserQuery>;
export type GetSignedInUserLazyQueryHookResult = ReturnType<typeof useGetSignedInUserLazyQuery>;
export type GetSignedInUserSuspenseQueryHookResult = ReturnType<typeof useGetSignedInUserSuspenseQuery>;
export type GetSignedInUserQueryResult = Apollo.QueryResult<GetSignedInUserQuery, GetSignedInUserQueryVariables>;