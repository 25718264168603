import React from 'react';
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../auth/authContext';
import AppLayout from '../layout/AppLayout';
const PrivateRoutes = () => {
  const { auth } = useAuth();

  return (
    <AppLayout>
      {auth.authenticated ? <Outlet /> : <Navigate to='/login' />}
    </AppLayout>
  )
}
export default PrivateRoutes;