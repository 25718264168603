import React, { useState } from 'react';
import { AuthContext, DEFAULT_AUTH } from './authContext';
import { AUTH_TOKEN_KEY } from '../../utils/auth';

export default ({ children }: { children: React.ReactElement }) => {
  const [auth, setAuthState] = useState(DEFAULT_AUTH);

  const setAuth = (newAuth) => {
    setAuthState(auth => ({ ...auth, ...newAuth }));
  };

  const setAuthStatus = (authenticated) => {
    setAuthState(auth => ({ ...auth, authenticated }));
  };

  const setUserData = (user) => {
    setAuthState(auth => ({ ...auth, user }));
  };

  const signOut = () => {
    localStorage.removeItem(AUTH_TOKEN_KEY);
    setAuthState(auth => ({
      ...auth,
      authenticated: false,
      initialAuthAttempted: false,
      user: null,
      tokenExpired: true
    }));
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        setAuthStatus,
        setUserData,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );

}