import React, { useState } from "react";
import { gql } from "@apollo/client";
import toast from 'react-hot-toast';
import Input from "../core/Input";
import { Button } from "../core/Button";
import Heading from "../core/Heading";
import Logo from "../core/Logo";
import { useSignInMutation } from "./__generated__/SignIn.generated";
import { signInUser } from "../../utils/auth";
import { useAuth } from "./authContext";
import { USER_FRAGMENT } from "./types/UserFragment";
import Label from "../core/Label";
import { Link } from "react-router-dom";

const SIGN_IN_MUTATION = gql`
  mutation signIn($input: SignInInput!) {
    signIn(input: $input) {
      user {
        ...AuthUserType
      }
      errors
    }
    ${USER_FRAGMENT}
  }
`;

export default () => {
  const [signInAction, { loading }] = useSignInMutation();
  const { setAuthStatus, setUserData } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSignIn = (event) => {
    event.preventDefault();

    signInAction({
      variables: {
        input: {
          email,
          password,
        },
      },
      onCompleted: (response) => {
        if (response.signIn?.errors?.length) {
          toast.error("Invalid email or password")
        } else {
          signInUser(response.signIn?.user?.authenticationToken);

          setAuthStatus(true);
          setUserData(response.signIn?.user)

          toast.success('Login successful')
        }
      },
      onError: (error) => {
        toast.error("Something went wrong")
      },
    });
  };

  return (
    <div className="sign-in-container">
      <Heading className="text-center" text="Sign in to your account" />
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6 text-center">
          <Input
            id="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            name="email"
            type="email"
            placeholder="Email address"
            autoComplete="email"
            required={true}
          />
          <Input
            id="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            name="password"
            type="password"
            placeholder="Password"
            autoComplete="current-password"
            required={true}
          />
          <Button onClick={handleSignIn} loading={loading} size='lg' className="w-full">
            Sign In
          </Button>
          <Label className="text-center">Do not have account?
            <Link className="underline" to='/sign-up'> Sign up</Link>
          </Label>
        </form>
      </div>
    </div>
  );
};
