import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateUserMutationVariables = Types.Exact<{
  input: Types.CreateInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser?: { __typename?: 'CreatePayload', errors?: Array<string> | null, user?: { __typename?: 'User', email: string, fullName?: string | null, authenticationToken?: string | null } | null } | null };

export type VerifyInvitationMutationVariables = Types.Exact<{
  input: Types.VerifyInvitationInput;
}>;


export type VerifyInvitationMutation = { __typename?: 'Mutation', verifyInvitation?: { __typename?: 'VerifyInvitationPayload', errors?: Array<string> | null, organizationName?: string | null, email?: string | null } | null };


export const CreateUserDocument = gql`
    mutation createUser($input: CreateInput!) {
  createUser(input: $input) {
    user {
      email
      fullName
      authenticationToken
    }
    errors
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const VerifyInvitationDocument = gql`
    mutation verifyInvitation($input: VerifyInvitationInput!) {
  verifyInvitation(input: $input) {
    errors
    organizationName
    email
  }
}
    `;
export type VerifyInvitationMutationFn = Apollo.MutationFunction<VerifyInvitationMutation, VerifyInvitationMutationVariables>;

/**
 * __useVerifyInvitationMutation__
 *
 * To run a mutation, you first call `useVerifyInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyInvitationMutation, { data, loading, error }] = useVerifyInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyInvitationMutation(baseOptions?: Apollo.MutationHookOptions<VerifyInvitationMutation, VerifyInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyInvitationMutation, VerifyInvitationMutationVariables>(VerifyInvitationDocument, options);
      }
export type VerifyInvitationMutationHookResult = ReturnType<typeof useVerifyInvitationMutation>;
export type VerifyInvitationMutationResult = Apollo.MutationResult<VerifyInvitationMutation>;
export type VerifyInvitationMutationOptions = Apollo.BaseMutationOptions<VerifyInvitationMutation, VerifyInvitationMutationVariables>;