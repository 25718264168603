import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { EntityFragmentFragmentDoc } from '../new/__generated__/EditEntityDetails.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListEntitiesQueryVariables = Types.Exact<{
  tagIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type ListEntitiesQuery = { __typename?: 'Query', listEntities?: Array<{ __typename?: 'Entity', id: string, legalName?: string | null, visibility: string, createdAt: any, tags?: Array<{ __typename?: 'Tag', id: string, name?: string | null }> | null, invitedUsers?: Array<{ __typename?: 'User', id: string, email: string, role: string }> | null }> | null };

export type AddTagsToEntityMutationVariables = Types.Exact<{
  input: Types.AddTagsInput;
}>;


export type AddTagsToEntityMutation = { __typename?: 'Mutation', addTagsToEntity?: { __typename?: 'AddTagsPayload', errors?: Array<string> | null, entity?: { __typename?: 'Entity', id: string, legalName?: string | null, fincenId?: string | null, foreignTaxCountry?: string | null, identificationType?: string | null, entityType?: string | null, filingType?: string | null, jurisdictionState?: string | null, jurisdictionCountry?: string | null, isCreatedBeforeJan2024?: boolean | null, tradeNames?: Array<string> | null, taxIdentificationNumber?: string | null, isForeignPooledInvestmentVehicle?: boolean | null, dateOfFormation?: any | null, address?: { __typename?: 'Address', id: string, nameLine?: string | null, addressLine1?: string | null, addressLine2?: string | null, administrativeArea?: string | null, locality?: string | null, country?: string | null, postalCode?: string | null } | null, tags?: Array<{ __typename?: 'Tag', id: string, name?: string | null }> | null } | null } | null };

export type ListTagsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListTagsQuery = { __typename?: 'Query', listTags?: Array<{ __typename?: 'Tag', id: string, name?: string | null }> | null };


export const ListEntitiesDocument = gql`
    query listEntities($tagIds: [ID!]) {
  listEntities(tagIds: $tagIds) {
    id
    legalName
    tags {
      id
      name
    }
    visibility
    createdAt
    invitedUsers {
      id
      email
      role
    }
  }
}
    `;

/**
 * __useListEntitiesQuery__
 *
 * To run a query within a React component, call `useListEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEntitiesQuery({
 *   variables: {
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useListEntitiesQuery(baseOptions?: Apollo.QueryHookOptions<ListEntitiesQuery, ListEntitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListEntitiesQuery, ListEntitiesQueryVariables>(ListEntitiesDocument, options);
      }
export function useListEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListEntitiesQuery, ListEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListEntitiesQuery, ListEntitiesQueryVariables>(ListEntitiesDocument, options);
        }
export function useListEntitiesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListEntitiesQuery, ListEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListEntitiesQuery, ListEntitiesQueryVariables>(ListEntitiesDocument, options);
        }
export type ListEntitiesQueryHookResult = ReturnType<typeof useListEntitiesQuery>;
export type ListEntitiesLazyQueryHookResult = ReturnType<typeof useListEntitiesLazyQuery>;
export type ListEntitiesSuspenseQueryHookResult = ReturnType<typeof useListEntitiesSuspenseQuery>;
export type ListEntitiesQueryResult = Apollo.QueryResult<ListEntitiesQuery, ListEntitiesQueryVariables>;
export const AddTagsToEntityDocument = gql`
    mutation addTagsToEntity($input: AddTagsInput!) {
  addTagsToEntity(input: $input) {
    entity {
      ...EntityFragment
    }
    errors
  }
}
    ${EntityFragmentFragmentDoc}`;
export type AddTagsToEntityMutationFn = Apollo.MutationFunction<AddTagsToEntityMutation, AddTagsToEntityMutationVariables>;

/**
 * __useAddTagsToEntityMutation__
 *
 * To run a mutation, you first call `useAddTagsToEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTagsToEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTagsToEntityMutation, { data, loading, error }] = useAddTagsToEntityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTagsToEntityMutation(baseOptions?: Apollo.MutationHookOptions<AddTagsToEntityMutation, AddTagsToEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTagsToEntityMutation, AddTagsToEntityMutationVariables>(AddTagsToEntityDocument, options);
      }
export type AddTagsToEntityMutationHookResult = ReturnType<typeof useAddTagsToEntityMutation>;
export type AddTagsToEntityMutationResult = Apollo.MutationResult<AddTagsToEntityMutation>;
export type AddTagsToEntityMutationOptions = Apollo.BaseMutationOptions<AddTagsToEntityMutation, AddTagsToEntityMutationVariables>;
export const ListTagsDocument = gql`
    query listTags {
  listTags {
    id
    name
  }
}
    `;

/**
 * __useListTagsQuery__
 *
 * To run a query within a React component, call `useListTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListTagsQuery(baseOptions?: Apollo.QueryHookOptions<ListTagsQuery, ListTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListTagsQuery, ListTagsQueryVariables>(ListTagsDocument, options);
      }
export function useListTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTagsQuery, ListTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListTagsQuery, ListTagsQueryVariables>(ListTagsDocument, options);
        }
export function useListTagsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListTagsQuery, ListTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListTagsQuery, ListTagsQueryVariables>(ListTagsDocument, options);
        }
export type ListTagsQueryHookResult = ReturnType<typeof useListTagsQuery>;
export type ListTagsLazyQueryHookResult = ReturnType<typeof useListTagsLazyQuery>;
export type ListTagsSuspenseQueryHookResult = ReturnType<typeof useListTagsSuspenseQuery>;
export type ListTagsQueryResult = Apollo.QueryResult<ListTagsQuery, ListTagsQueryVariables>;