import React, { useState } from "react";
import { Button } from "../../core/Button";
import EntityDetailsForm from "../../entities/EntityDetailsForm";
import { Formik } from "formik";
import EntityLayout from "./EntityLayout";
import { gql } from "@apollo/client";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { stripTypename } from "@apollo/client/utilities";
import { useGetEntityDetailsQuery, useUpdateEntityMutation } from "./__generated__/EditEntityDetails.generated";
import { da } from "date-fns/locale";

 export const ENTITY_FRAGMENT = gql`
  fragment EntityFragment on Entity {
    id
    legalName
    fincenId
    foreignTaxCountry
    identificationType
    entityType
    filingType
    jurisdictionState
    jurisdictionCountry
    isCreatedBeforeJan2024
    tradeNames
    taxIdentificationNumber
    jurisdictionState
    isForeignPooledInvestmentVehicle
    dateOfFormation
    address {
      id
      nameLine
      addressLine1
      addressLine2
      administrativeArea
      locality
      country
      postalCode
    }
    tags {
      id
      name
    }
  }
`;

const GET_FILING = gql`
  query getEntityDetails($id: ID!) {
    getEntity(id: $id) {
        ...EntityFragment
    }
  }
  ${ENTITY_FRAGMENT}
`;

const UPDATE_ENTITY = gql`
  mutation updateEntity($input: UpdateEntityInput!) {
    updateEntity(input: $input) {
      entity {
        ...EntityFragment
      }
      errors
    }
  }
  ${ENTITY_FRAGMENT}
`;

export default () => {
  const { entityId } = useParams();

  const { data, loading } = useGetEntityDetailsQuery({
    variables: {
      id: entityId || "",
    },
  });


  const [updateEntityAction, { loading: loadingUpdateEntity }] =
    useUpdateEntityMutation();

  const navigate = useNavigate();

  const initialValues = stripTypename(data?.getEntity);

  const handleUpdateEntity = (values) => {
    updateEntityAction({
      variables: {
        input: {
          id: values.id,
          params: { ...values, id: undefined },
        },
      },
      onCompleted: (data) => {
        if (data?.updateEntity?.errors?.length) {
          toast.error(data?.updateEntity?.errors.join(', \n'))
        } else {
          toast.success("Entity updated successfully");
          navigate(`/edit-entity/${entityId}/beneficial-owners`);
        }
      },
      onError: (error) => {
        toast.error("Something went wrong");
      },
    });
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues || {}}
        onSubmit={handleUpdateEntity}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <EntityLayout>
              <EntityLayout.Body>
                <div className="border rounded-md p-8">
                  <h1 className="text-2xl font-semibold mb-4">Entity Details</h1>
                  <EntityDetailsForm
                    values={values}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                  />
                </div>
              </EntityLayout.Body>
              <EntityLayout.Actions>
                <Button variant="outline" onClick={() => handleSubmit()}>
                  Save and Exit
                </Button>
                <Button
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Save and Continue
                </Button>
              </EntityLayout.Actions>
            </EntityLayout>
          </form>
        )}
      </Formik>
    </>
  );
};
