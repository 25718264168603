import React from "react";
import Select from "./Select";
import Label from "./Label";

interface IndentificationTypeSelectProps {
    value: string;
    handleOnChange: (value: string) => void;
    label?: string;
}

const identificationTypes = [
    { name: "State Issued Drivers License", code: "state_issued_drivers_license" },
    { name: "State/Local/Tribe Issued ID", code: "state_local_tribe_issued_id" },
    { name: "U.S. Passport", code: "us_passport" },
    { name: "Foreign Passport", code: "foreign_passport" }
];

const IdentificationTypesSelect = (props: IndentificationTypeSelectProps) => {

    const { value, handleOnChange, label } = props;

    return (
        <>
            {label && <Label>{label}</Label>}
            <Select
                options={identificationTypes.map((idType) => ({
                    value: idType.code,
                    label: idType.name,
                }))}
                value={value}
                onChange={handleOnChange}
                placeholder="Select a tax identification type"
            />
        </>
    )
}

export default IdentificationTypesSelect;