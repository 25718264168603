import React from "react";
import AppLayout from "../layout/AppLayout";
import Steps from "../core/Steps";
import Label from "../core/Label";
import Input from "../core/Input";
import Select from "../core/Select";
import { Checkbox } from "../core/Checkbox";
import { Button } from "../core/Button";
import EntityDetailsForm from "../entities/EntityDetailsForm";
import BeneficialOwnerForm from "../entities/BeneficialOwnerForm";
import { Formik } from "formik";
import CompanyApplicantFormFields from "../entities/CompanyApplicantForm";
import FilingLayout from "./FilingLayout";
import { gql } from "@apollo/client";
import { useCreateFilingMutation, useListEntitiesForFilingQuery } from "./__generated__/CreateFiling.generated";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useListFilingsQuery } from "./__generated__/ListFilings.generated";

const LIST_ENTITIES_FOR_FILING = gql`
  query listEntitiesForFiling {
    listEntities {
      id
      legalName
    }
  }
`;

const CREATE_FILING = gql`
  mutation createFiling($input: CreateFilingInput!) {
    createFiling(input: $input) {
      filing {
        id
      }
      errors
    }
  }
`;

export default () => {
  const { data: entitiesData, loading: entitiesLoading } =
    useListEntitiesForFilingQuery();

  const { data, loading, refetch } = useListFilingsQuery();

  const [createFilingAction, { loading: loadingUpdateEntity }] =
    useCreateFilingMutation();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultEntityId = searchParams.get("entityId")

  const entities = entitiesData?.listEntities || [];

  const initialValues: any = {
    entityId: defaultEntityId,
    correctedReport: false,
  };

  const handleCreateFiling = (values) => {
    createFilingAction({
      variables: {
        input: {
          filingType: values.filingType,
          entityId: values.entityId,
          correctedReport: values.correctedReport,
        },
      },
      onCompleted: (data) => {
        if (data?.createFiling?.errors?.length) {
          toast.error("Something went wrong");
        } else {
          toast.success("Filing created");
          refetch();
          const filingId = data?.createFiling?.filing?.id;
          navigate(`/edit-filing/${values.entityId}/${filingId}`)
        }
      },
      onError: (error) => {
        toast.error("Something went wrong");
      }
    })
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={handleCreateFiling}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <FilingLayout>
          <FilingLayout.Body>
            <div className="border rounded-md p-8">
              <h1 className="text-2xl font-semibold mb-4">Filing Information</h1>
              <form onSubmit={handleSubmit}>
                <div className="mt-4 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-4">
                    <Label>Filing Type *</Label>
                    <div className="mt-2">
                      <Select
                        options={[
                          {
                            label: "Initial Filing",
                            value: "initial_filing",
                          },
                          {
                            label: "Subsequent/Annual Filing",
                            value: "subsequent_filing",
                          },
                        ]}
                        value={values.filingType}
                        onChange={(value) => {
                          setFieldValue("filingType", value);
                        }}
                        placeholder="Select a filing type"
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-4 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-4">
                    <Label>Entity *</Label>
                    <div className="mt-2">
                      <Select
                        options={entities.map((e) => ({
                          label: e.legalName || "",
                          value: e.id || "",
                        }))}
                        value={values.entityId}
                        onChange={(value) => setFieldValue("entityId", value)}
                        placeholder="Select an entity"
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-8 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-4 flex items-start">
                    <Checkbox
                      id="terms"
                      checked={values.correctedReport}
                      onCheckedChange={(checked) =>
                        setFieldValue("correctedReport", checked)
                      }
                    />
                    <label
                      htmlFor="terms"
                      className="-mt-1 text-sm ml-2 font-medium peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      (Optional) Mark this as a corrected report. A corrected
                      report is allowed only if you are correcting a previous
                      report that was filed within the last 30 days.
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </FilingLayout.Body>
          <FilingLayout.Actions>
            <Button
              variant="outline"
              onClick={() => handleSubmit()}
              disabled={!values.filingType || !values.entityId}>
              Save and Exit
            </Button>
            <Button
              onClick={() => handleSubmit()}
              disabled={!values.filingType || !values.entityId}>
              Save and Continue
            </Button>
          </FilingLayout.Actions>
        </FilingLayout>
      )}
    </Formik>
  );
};
