import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  SortingState,
  getSortedRowModel,
  ColumnFiltersState,
  getFilteredRowModel,
  PaginationState,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./Table";
import React, { useMemo } from "react";
import { Button } from "./Button";
import Input from "./Input";
import { TagData } from "../tags/components/Tag";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  searchByKey?: string;
  extraControl?: React.ReactNode;
  onRowSelection?: (rowId: string, rowName: string, tags: TagData[]) => void;
  page?: number;
  onPageChange?: (page: number) => void;
  totalPages?: number;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  searchByKey,
  extraControl,
  onRowSelection,
  page,
  onPageChange,
  totalPages,
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const [rowSelection, setRowSelection] = React.useState({});

  const handlePaginationChange = (updaterOrValue: PaginationState | ((old: PaginationState) => PaginationState)) => {
    const newPaginationState = typeof updaterOrValue === 'function'
      ? updaterOrValue(table.getState().pagination)
      : updaterOrValue;
  
    console.log('New pagination state:', newPaginationState);
    onPageChange?.(newPaginationState.pageIndex);
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    enableMultiRowSelection: false,
    getRowId: (row) => row.id,
    manualPagination: true,
    onPaginationChange: handlePaginationChange,
    state: {
      sorting,
      columnFilters,
      rowSelection,
      pagination: {
        pageIndex: page ?? 0,
        pageSize: 10,
      },
    },
  });

  const searchByKeyFormatted = useMemo(() => {
    return (
      (searchByKey ?? "")
        .match(/([A-Z]?[^A-Z]*)/g)
        ?.filter(Boolean)
        .map((word) => word.toLowerCase())
        .join(" ") ?? ""
    );
  }, [searchByKey]);

  /*  const handleRowSelection = (rowId: string) => {
         if (rowSelection[rowId]) {
             delete rowSelection[rowId];
         } else {
             rowSelection[rowId] = true;
         }
         setRowSelection({ ...rowSelection });
         
     }
  */

  const handleRowSelection = (row) => {
    const rowTags = row.original.tags.map((tag) => {
      return {
        id: tag.id,
        value: tag.name,
      };
    });
    onRowSelection?.(row.id, row.original.legalName, rowTags);
  };

  return (
    <div className="grow">
      {searchByKey && <div className="flex items-center">{extraControl}</div>}

      {searchByKey && (
        <div className="flex items-center py-4">
          <Input
            placeholder={`Filter by ${searchByKeyFormatted}...`}
            value={
              (table.getColumn(searchByKey)?.getFilterValue() as string) ?? ""
            }
            onChange={(event) => {
              table.getColumn(searchByKey)?.setFilterValue(event.target.value);
            }}
            className="max-w-sm"
          />
        </div>
      )}
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  onClick={() => handleRowSelection(row)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <Button
          variant="outline"
          size="sm"
          onClick={() => table.previousPage()}
          disabled={page === 1}
        >
          Previous
        </Button>
        <Button
          variant="outline"
          size="sm"
          onClick={() => table.nextPage()}
          disabled={page === (totalPages || 1)}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
