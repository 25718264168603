import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { Spinner } from "flowbite-react";

import { useAuth } from "./auth/authContext";
import InitialLoginHandler from "./auth/InitialLoginHandler";
import Routes from "./routing/Routes";

export default () => {
  const {
    auth: { initialAuthAttempted, tokenExpired },
  } = useAuth();

  return (
    <>
      <Toaster />
      <InitialLoginHandler />
      {!initialAuthAttempted && !tokenExpired && (
        <div className="flex h-screen">
          <div className="m-auto">
            <Spinner />
          </div>
        </div>
      )}
      {initialAuthAttempted && <Routes />}
      {tokenExpired && <Routes />}
    </>
  );
};
