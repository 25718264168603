import React from "react";
import { Address } from "../../utils/address";
import Input from "./Input";
import UsaStateSelect from "./UsaStateSelect";
import CountrySelect from "./CountrySelect";

type AddressInputProps = {
  value: Address;
  onChange: (value: Address) => void;
};

export default ({ value, onChange }: AddressInputProps) => {
  const handleChange = (attribute) => {
    return (event) => {
      console.log({ attribute, event: event.target });
      onChange({
        ...value,
        [attribute]: event.target.value,
      });
    };
  };

  return (
    <div className="flex flex-col gap-2">
      <Input
        id="addressLine1"
        value={value.addressLine1}
        onChange={handleChange("addressLine1")}
        name="addressLine1"
        type="text"
        label=""
        placeholder="Address Line 1 *"
      />
      <Input
        id="addressLine2"
        value={value.addressLine2}
        onChange={handleChange("addressLine2")}
        name="addressLine2"
        type="text"
        label=""
        placeholder="Address Line 2"
      />
      <div className="flex flex-row gap-2">
        <Input
          id="postalCode"
          value={value.postalCode}
          onChange={handleChange("postalCode")}
          name="postalCode"
          type="text"
          label=""
          className="flex-2"
          placeholder="Zip Code *"
        />
        <Input
          id="locality"
          value={value.locality}
          onChange={handleChange("locality")}
          name="locality"
          type="text"
          label=""
          className="flex-2"
          placeholder="City *"
        />
        {value.country === "US" && (
          <UsaStateSelect
            // label="State"
            disabled={value.country !== "US"}
            value={value.administrativeArea || ""}
            handleOnChange={(_value) =>
              handleChange("administrativeArea")({ target: { value: _value } })
            }
          />
        )}

        <CountrySelect
          label=""
          value={value.country || ""}
          handleOnChange={(country) =>
            onChange({
              ...value,
              country: country,
            })
          }
        />

        {/* <Input
          id="administrativeArea"
          value={value.administrativeArea}
          onChange={handleChange('administrativeArea')}
          name="administrativeArea"
          type="text"
          label=""
          className="flex-3"
          placeholder="State *"
        /> */}
      </div>
    </div>
  );
};
