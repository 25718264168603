import React from "react";

import { HiCheck, HiSelector } from "react-icons/hi";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

type Step = {
  label: string;
  onClick: () => void;
  status: "complete" | "incomplete";
  active: boolean;
  disabled?: boolean;
};

type StepsProps = {
  steps: Step[];
};

const Step = ({
  stepIdx,
  name,
  onClick,
  status,
  isCurrent = false,
  isLast = false,
  disabled = false,
}) => {
  const isComplete = status === "complete";
  const textColor = isCurrent
    ? "text-indigo-600"
    : isComplete
    ? "text-gray-700"
    : "text-gray-500";

  const cursor = disabled ? "cursor-not-allowed" : "cursor-pointer";

  if (status == "complete") {
    return (
      <>
        {isLast ? (
          <div
            className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-indigo-600"
            aria-hidden="true"
          />
        ) : null}
        <a
          onClick={onClick}
          className={`group relative flex items-center ${cursor}`}
        >
          <span className="flex h-9 items-center">
            <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
              <HiCheck className="h-5 w-5 text-white" aria-hidden="true" />
            </span>
          </span>
          <span className="ml-4 flex min-w-0 flex-col">
            <span className={`text-sm font-medium ${textColor}`}>{name}</span>
          </span>
        </a>
      </>
    );
  }

  if (isCurrent) {
    return (
      <>
        {isLast ? (
          <div
            className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
            aria-hidden="true"
          />
        ) : null}
        <a
          onClick={onClick}
          className={`group relative flex items-center ${cursor}`}
          aria-current="step"
        >
          <span className="flex h-9 items-center" aria-hidden="true">
            <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white">
              <span className="h-2.5 w-2.5 rounded-full bg-indigo-600" />
            </span>
          </span>
          <span className="ml-4 flex min-w-0 flex-col">
            <span className={`text-sm font-medium ${textColor}`}>{name}</span>
          </span>
        </a>
      </>
    );
  }

  return (
    <>
      {isLast ? (
        <div
          className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
          aria-hidden="true"
        />
      ) : null}
      <a
        onClick={onClick}
        className={`group relative flex items-center ${cursor}`}
      >
        <span className="flex h-9 items-center" aria-hidden="true">
          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
            <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
          </span>
        </span>
        <span className="ml-4 flex min-w-0 flex-col">
          <span className={`text-sm font-medium ${textColor}`}>{name}</span>
        </span>
      </a>
    </>
  );
};

export default (props: StepsProps) => {
  return (
    <nav aria-label="Progress">
      <ol role="list" className="overflow-hidden">
        {props.steps.map((step, stepIdx) => (
          <li
            key={step.label}
            className={classNames(
              stepIdx !== props.steps.length - 1 ? "pb-10" : "",
              "relative"
            )}
          >
            <Step
              stepIdx={stepIdx}
              name={step.label}
              onClick={() => {!step.disabled && step.onClick()}}
              status={step.status}
              isCurrent={step.active}
              isLast={stepIdx !== props.steps.length - 1}
            />
          </li>
        ))}
      </ol>
    </nav>
  );
};
