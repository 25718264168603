import React from 'react';
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../auth/authContext';
import AppPublicLayout from '../layout/AppPublicLayout';
const UnauthenticatedRoutes = () => {
  const { auth } = useAuth();

  return (
    <AppPublicLayout>{auth.authenticated ? <Navigate to='/' /> : <Outlet />}</AppPublicLayout>
  )
}
export default UnauthenticatedRoutes;