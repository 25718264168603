import React, { PropsWithChildren } from "react";
import Sidebar from "./Sidebar";

const AppLayout = (props: PropsWithChildren) => {
  return (
    <main className="flex flex-col h-screen">
      <div className="flex flex-1">
        <div className="flex">
          <Sidebar />
        </div>
        <div className="flex flex-1 flex-col">
          <div className="flex flex-1 overflow-y-auto paragraph px-4 py-4">
            <div className="p-4 w-full">{props.children}</div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AppLayout;
