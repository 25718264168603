import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetEntityForLayoutQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetEntityForLayoutQuery = { __typename?: 'Query', getEntity: { __typename?: 'Entity', entityDetailsComplete: boolean, companyApplicantsComplete: boolean, beneficialOwnersComplete: boolean } };


export const GetEntityForLayoutDocument = gql`
    query getEntityForLayout($id: ID!) {
  getEntity(id: $id) {
    entityDetailsComplete
    companyApplicantsComplete
    beneficialOwnersComplete
  }
}
    `;

/**
 * __useGetEntityForLayoutQuery__
 *
 * To run a query within a React component, call `useGetEntityForLayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntityForLayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntityForLayoutQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEntityForLayoutQuery(baseOptions: Apollo.QueryHookOptions<GetEntityForLayoutQuery, GetEntityForLayoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEntityForLayoutQuery, GetEntityForLayoutQueryVariables>(GetEntityForLayoutDocument, options);
      }
export function useGetEntityForLayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEntityForLayoutQuery, GetEntityForLayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEntityForLayoutQuery, GetEntityForLayoutQueryVariables>(GetEntityForLayoutDocument, options);
        }
export function useGetEntityForLayoutSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetEntityForLayoutQuery, GetEntityForLayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEntityForLayoutQuery, GetEntityForLayoutQueryVariables>(GetEntityForLayoutDocument, options);
        }
export type GetEntityForLayoutQueryHookResult = ReturnType<typeof useGetEntityForLayoutQuery>;
export type GetEntityForLayoutLazyQueryHookResult = ReturnType<typeof useGetEntityForLayoutLazyQuery>;
export type GetEntityForLayoutSuspenseQueryHookResult = ReturnType<typeof useGetEntityForLayoutSuspenseQuery>;
export type GetEntityForLayoutQueryResult = Apollo.QueryResult<GetEntityForLayoutQuery, GetEntityForLayoutQueryVariables>;