import React from 'react';
import { Field } from 'formik';
import { Label } from 'flowbite-react';

const FormikField = ({ fieldName, ...props }) => {
    return (
        <>  
            <Field
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                id={fieldName}
                name={fieldName}
                type="text"
                {...props}
            >
                {props.children}
            </Field>
            {props.errors[fieldName] && props.touched[fieldName] ? (
                <Label style={{ margin: '10px 0 0 10px' }}
                    className='text-red-500 text-xs text-left block'>
                    {props.errors[fieldName]}
                </Label>
            ) : null}

        </>
    );
};

export default FormikField;