import React, { useState } from "react";
import { Button } from "../../core/Button";
import { Formik } from "formik";
import EntityLayout from "./EntityLayout";
import { gql } from "@apollo/client";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useUpdateEntityMutation } from "./__generated__/EditEntityDetails.generated";
import { stripTypename } from "@apollo/client/utilities";
import BeneficialOwnerForm from "../../entities/BeneficialOwnerForm";
import { INDIVIDUAL_FRAGMENT, trimMetadata } from "../../entities/IndividualForm";
import { useGetEntityBeneficialOwnerDetailsQuery } from "./__generated__/EditBeneficialOwners.generated";

const ENTITY_FRAGMENT = gql`
  fragment BeneficialOwnerFormEntityFragment on Entity {
    id
    beneficialOwners {
      ...IndividualFormIndividualFragment
    }
  }
  ${INDIVIDUAL_FRAGMENT}
`;

const GET_ENTITY = gql`
  query getEntityBeneficialOwnerDetails($id: ID!) {
    getEntity(id: $id) {
        ...BeneficialOwnerFormEntityFragment
    }
  }
  ${ENTITY_FRAGMENT}
`;

const UPDATE_ENTITY = gql`
  mutation updateEntityForBeneficialOwnerForm($input: UpdateEntityInput!) {
    updateEntity(input: $input) {
      entity {
        ...BeneficialOwnerFormEntityFragment
      }
      errors
    }
  }
  ${ENTITY_FRAGMENT}
`;

export const LIST_INDIVIDUALS = gql`
    query listIndividuals($individualType:String!, $searchParam: String, $entityId: ID, $page: Int, $limit: Int, ) {
      listIndividuals(individualType: $individualType, searchParam: $searchParam, entityId: $entityId, page: $page, limit: $limit) {
        collection {
          ...IndividualFormIndividualFragment
        }
        metadata {
          totalPages
          totalCount
          currentPage
          limitValue
        }
      }
      ${INDIVIDUAL_FRAGMENT}
    }
`;


export default () => {
  const { entityId } = useParams();

  const { data, loading, refetch } = useGetEntityBeneficialOwnerDetailsQuery({
    variables: {
      id: entityId || "",
    },
  });

  const [isAddedNewOwner, setIsAddedNewOwner] = useState(false);

  const [updateEntityAction, { loading: loadingUpdateEntity }] =
    useUpdateEntityMutation();

  const navigate = useNavigate();

  const initialValues = stripTypename(data?.getEntity);

  const handleUpdateEntity = (values) => {

    if (values.beneficialOwners.length === 0) {
      toast.error("Please add at least one beneficial owner");
      return;
    }

    let params = {
      ...values,
      id: undefined,
      beneficialOwners: values.beneficialOwners.map(trimMetadata),
    };

    updateEntityAction({
      variables: {
        input: {
          id: values.id,
          params: params,
        },
      },
      onCompleted: (data) => {
        if (data?.updateEntity?.errors?.length) {
          toast.error(data?.updateEntity?.errors.join(', \n'));
        } else {
          toast.success("Entity updated successfully");
          if (!isAddedNewOwner) {
            navigate(`/edit-entity/${entityId}/company-applicants`);
          }
          refetch();
        }
      },
      onError: (error) => {
        toast.error("Something went wrong");
      },
    });

    setIsAddedNewOwner(false);
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues || {}}
      onSubmit={handleUpdateEntity}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <EntityLayout>
            <EntityLayout.Body>
              <div className="border rounded-md p-8">
                <h1 className="text-2xl font-semibold mb-4">Beneficial Owners</h1>
                <BeneficialOwnerForm
                  values={values}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  onAddOwner={(isNewOwner) => {
                    setIsAddedNewOwner(isNewOwner);
                    handleSubmit()
                  }}
                />
              </div>
            </EntityLayout.Body>
            <EntityLayout.Actions>
              <Button variant="outline" onClick={() => handleSubmit()}>
                Save and Exit
              </Button>
              <Button
                onClick={() => {
                  handleSubmit();
                }}
              >
                Save and Continue
              </Button>
            </EntityLayout.Actions>
          </EntityLayout>
        </form>
      )}
    </Formik>
  );
};
