import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { AuthUserTypeFragmentDoc } from '../../../auth/types/__generated__/UserFragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListOrganizationsMembersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListOrganizationsMembersQuery = { __typename?: 'Query', listOrganizationMembers: Array<{ __typename?: 'User', id: string, email: string, createdAt: any, role: string, authenticationToken?: string | null, fullName?: string | null, organization: { __typename?: 'Organization', name?: string | null }, invitation?: { __typename?: 'Invitation', status: string } | null }> };


export const ListOrganizationsMembersDocument = gql`
    query listOrganizationsMembers {
  listOrganizationMembers {
    ...AuthUserType
  }
}
    ${AuthUserTypeFragmentDoc}`;

/**
 * __useListOrganizationsMembersQuery__
 *
 * To run a query within a React component, call `useListOrganizationsMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOrganizationsMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOrganizationsMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useListOrganizationsMembersQuery(baseOptions?: Apollo.QueryHookOptions<ListOrganizationsMembersQuery, ListOrganizationsMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListOrganizationsMembersQuery, ListOrganizationsMembersQueryVariables>(ListOrganizationsMembersDocument, options);
      }
export function useListOrganizationsMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOrganizationsMembersQuery, ListOrganizationsMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListOrganizationsMembersQuery, ListOrganizationsMembersQueryVariables>(ListOrganizationsMembersDocument, options);
        }
export function useListOrganizationsMembersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListOrganizationsMembersQuery, ListOrganizationsMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListOrganizationsMembersQuery, ListOrganizationsMembersQueryVariables>(ListOrganizationsMembersDocument, options);
        }
export type ListOrganizationsMembersQueryHookResult = ReturnType<typeof useListOrganizationsMembersQuery>;
export type ListOrganizationsMembersLazyQueryHookResult = ReturnType<typeof useListOrganizationsMembersLazyQuery>;
export type ListOrganizationsMembersSuspenseQueryHookResult = ReturnType<typeof useListOrganizationsMembersSuspenseQuery>;
export type ListOrganizationsMembersQueryResult = Apollo.QueryResult<ListOrganizationsMembersQuery, ListOrganizationsMembersQueryVariables>;