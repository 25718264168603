import React from 'react';

type DetailsFieldProps = {
  label: string;
  value?: React.ReactNode | null;
}

export default ({
  label,
  value
}: DetailsFieldProps) => {
  return (
    <dd className="mt-2 text-gray-500">
      <span className="font-medium text-gray-900">
        {label}
      </span>
      <br />
      {!!value ? value : <span className="italic text-xs">Not provided</span>}
    </dd>
  )
}