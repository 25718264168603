import React from "react";
import * as Yup from 'yup';
import { gql } from "@apollo/client";
import Heading from "../core/Heading";
import { Form, Formik, FormikHelpers } from "formik";
import { Button } from "../core/Button";
import Label from "../core/Label";
import { Link } from "react-router-dom";
import FormikPassword from "./components/FormikPassword";
import FormikField from "./components/FormikField";
import { USER_FRAGMENT } from "./types/UserFragment";
import { useSignUpMutation } from "./__generated__/SignUp.generated";
import { signInUser } from "../../utils/auth";
import { useAuth } from "./authContext";
import toast from "react-hot-toast";

interface FormikValues {
    fullName: string;
    email: string;
    organizationName: string;
    password: string;
    repeatPassword: string;
}

const SignUpSchema = Yup.object().shape({
    fullName: Yup.string()
        .min(2, 'Full name should be at least 2 characters')
        .max(70, 'Full name should not exceed 70 characters')
        .required('Required')
    ,
    email: Yup.string()
        .email('Invalid email address')
        .required('Required')
    ,
    organizationName: Yup.string()
        .min(2, 'Organization name should be at least 2 characters')
        .max(30, 'Organization name should not exceed 30 characters')
        .required('Required')
    ,
    password: Yup.string()
        .min(8, 'Password should be at least 8 characters')
        .required('Required')
    ,
    repeatPassword: Yup.string()
        .oneOf([Yup.ref('password'), undefined], "Passwords must match")
        .required('Required')
});

const SIGN_UP_MUTATION = gql`
  mutation signUp($input: SignUpInput!) {
    signUp(input: $input) {
        user {
            ...AuthUserType
          }
          errors
    }
    ${USER_FRAGMENT}
  }
`;

const SignUp = () => {

    const [signUpAction, { loading }] = useSignUpMutation();
    const { setAuthStatus, setUserData } = useAuth();

    const handleOnSubmit = (
        values: { fullName: string; email: string; organizationName: string; password: string; repeatPassword: string; },
        setSubmitting: (isSubmitting: boolean) => void
    ) => {
        signUpAction({
            variables: {
                input: {
                    user: {
                        fullName: values.fullName,
                        email: values.email,
                        password: values.password
                    },
                    organization: {
                        name: values.organizationName
                    }
                }
            },
            onCompleted: (response) => {
                setSubmitting(false);
                if (response.signUp?.errors?.length) {
                    toast.error("Something went wrong");
                } else {
                    toast.success('Sign up successful')
                    signInUser(response.signUp?.user?.authenticationToken);
                    setAuthStatus(true);
                    setUserData(response.signUp?.user)
                }
            },
        })
    };

    return (
        <div className="sign-up-container">
            <Heading className="text-center" text="Sign up new account" />
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <Formik
                    initialValues={{
                        fullName: '',
                        email: '',
                        organizationName: '',
                        password: '',
                        repeatPassword: ''
                    }}
                    validationSchema={SignUpSchema}
                    onSubmit={(
                        values: FormikValues,
                        { setSubmitting }: FormikHelpers<FormikValues>
                    ) => {
                        handleOnSubmit(values, setSubmitting);
                    }}
                >
                    {({ errors, isSubmitting, resetForm, submitForm, touched, values }) => (
                        <Form className="space-y-6 text-center">
                            <FormikField
                                fieldName="fullName"
                                errors={errors}
                                touched={touched}
                                placeholder="Full Name"
                                required
                            />

                            <FormikField
                                fieldName="email"
                                errors={errors}
                                touched={touched}
                                placeholder="Email"
                                type="email"
                                required
                            />

                            <FormikField
                                fieldName="organizationName"
                                errors={errors}
                                touched={touched}
                                placeholder="Organization Name"
                                required
                            />

                            <FormikPassword fieldName="password"
                                errors={errors}
                                touched={touched}
                                placeholder="Password"
                                required
                            />

                            <FormikPassword fieldName="repeatPassword"
                                errors={errors}
                                touched={touched}
                                placeholder="Repeat Password"
                                required
                            />

                            <Button
                                size='lg'
                                className="w-full"
                                type="submit"
                                disabled={isSubmitting || Object.keys(errors).length > 0 || loading}
                            >
                                Sign Up
                            </Button>
                            <Label className="text-center">
                                <Link className="underline" to='/login'>Back to Sign in</Link>
                            </Label>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default SignUp
