import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetFilingForLayoutQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetFilingForLayoutQuery = { __typename?: 'Query', getFiling: { __typename?: 'Filing', id: string, entity: { __typename?: 'Entity', entityDetailsComplete: boolean, companyApplicantsComplete: boolean, beneficialOwnersComplete: boolean } } };


export const GetFilingForLayoutDocument = gql`
    query getFilingForLayout($id: ID!) {
  getFiling(id: $id) {
    id
    entity {
      entityDetailsComplete
      companyApplicantsComplete
      beneficialOwnersComplete
    }
  }
}
    `;

/**
 * __useGetFilingForLayoutQuery__
 *
 * To run a query within a React component, call `useGetFilingForLayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilingForLayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilingForLayoutQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFilingForLayoutQuery(baseOptions: Apollo.QueryHookOptions<GetFilingForLayoutQuery, GetFilingForLayoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFilingForLayoutQuery, GetFilingForLayoutQueryVariables>(GetFilingForLayoutDocument, options);
      }
export function useGetFilingForLayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFilingForLayoutQuery, GetFilingForLayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFilingForLayoutQuery, GetFilingForLayoutQueryVariables>(GetFilingForLayoutDocument, options);
        }
export function useGetFilingForLayoutSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetFilingForLayoutQuery, GetFilingForLayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetFilingForLayoutQuery, GetFilingForLayoutQueryVariables>(GetFilingForLayoutDocument, options);
        }
export type GetFilingForLayoutQueryHookResult = ReturnType<typeof useGetFilingForLayoutQuery>;
export type GetFilingForLayoutLazyQueryHookResult = ReturnType<typeof useGetFilingForLayoutLazyQuery>;
export type GetFilingForLayoutSuspenseQueryHookResult = ReturnType<typeof useGetFilingForLayoutSuspenseQuery>;
export type GetFilingForLayoutQueryResult = Apollo.QueryResult<GetFilingForLayoutQuery, GetFilingForLayoutQueryVariables>;