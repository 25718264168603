import React from "react";
import { HiUser } from "react-icons/hi";

export default ({
  message = "No results found",
  tagline = "",
}) => {
  return (
    <div className="border rounded-md p-8 text-center">
      <HiUser className="mx-auto h-12 w-12 text-gray-400" />
      <h3 className="mt-2 text-sm font-semibold text-gray-900">
        {message}
      </h3>
      {tagline.length !== 0 && <p className="mt-1 text-sm text-gray-500">
        {tagline}
      </p>}
    </div>
  );
};
