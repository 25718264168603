
import { X } from "lucide-react";

import { Badge } from "../../core/Badge";
import {
    Command,
    CommandGroup,
    CommandItem,
} from "../../core/Command";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Input from "../../core/Input";
import Label from "../../core/Label";
import { TagData } from "./Tag";

interface ComboboxMultiSelectProps {
    tags?: TagData[];
    onChangeTags?: (tags: TagData[]) => void;
}

export function ComboboxMultiSelect(props: ComboboxMultiSelectProps) {

    const { tags, onChangeTags } = props;

    const inputRef = useRef<HTMLInputElement>(null);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<TagData[]>([]);
    const [inputValue, setInputValue] = useState("");

    const selectables = tags?.filter(tag => !selected.includes(tag));

    const handleUnselect = useCallback((tag: TagData) => {
        setSelected(prev => prev.filter(s => s.value !== tag.value));
    }, []);

    const handleSelect = useCallback((tag: TagData) => {
        setInputValue("")
        setSelected(prev => [...prev, tag])

    }, []);

    useEffect(() => {
        onChangeTags?.(selected);
    }, [selected]);

    const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
        const input = inputRef.current
        if (input) {
            if (e.key === "Delete" || e.key === "Backspace") {
                if (input.value === "") {
                    setSelected(prev => {
                        const newSelected = [...prev];
                        newSelected.pop();
                        return newSelected;
                    })
                }
            }
            // This is not a default behaviour of the <input /> field
            if (e.key === "Escape") {
                input.blur();
            }
        }
    }, []);

    return (
        <Command onKeyDown={handleKeyDown} className="overflow-visible bg-transparent">
            <div
                className="group border border-input px-2 py-2 text-sm ring-offset-background rounded-md focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2"
            >   <Label>Entities Tags:</Label>
                <div className="flex items-center py-2">
                    <Input
                        placeholder="Search tags..."
                        value={inputValue}
                        ref={inputRef}
                        onChange={(e) => setInputValue(e.target.value)}
                        onBlur={() => setOpen(false)}
                        onFocus={() => setOpen(true)}
                        className="max-w-sm"

                    />
                </div>
                <div className="flex flex-row grow flex-wrap space-x-1">
                    {selected.map((tag) => {
                        return (
                            <Badge key={tag.value} variant="secondary">
                                {tag.value}
                                <button
                                    className="ml-1 ring-offset-background rounded-full outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            handleUnselect(tag);
                                        }
                                    }}
                                    onMouseDown={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                    onClick={() => handleUnselect(tag)}
                                >
                                    <X className="h-3 w-3 text-muted-foreground hover:text-foreground" />
                                </button>
                            </Badge>
                        )
                    })}
                </div>
            </div>
            <div className="relative mt-2">
                {open && selectables && selectables?.length > 0 ?
                    <div className="absolute w-full  max-w-sm z-10 top-0 rounded-md border bg-popover text-popover-foreground shadow-md outline-none animate-in">
                        <CommandGroup className="overflow-auto max-w-sm" style={{ maxHeight: 250 }}>
                            {selectables.map((tag) => {
                                return (
                                    <CommandItem
                                        key={tag.value}
                                        onMouseDown={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                        onSelect={() => handleSelect(tag)}
                                        className={"cursor-pointer"}
                                    >
                                        {tag.value}
                                    </CommandItem>
                                );
                            })}
                        </CommandGroup>
                    </div>
                    : null}
            </div>
        </Command >
    )
}