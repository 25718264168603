import React from "react";

import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import { Button } from "../core/Button";
import FormikField from "../auth/components/FormikField";
import DatePicker from "../core/DatePicker";
import Input from "../core/Input";
import { Individual } from "../../types";
import { trimTime } from "../../utils/time";
import { parseISO } from "date-fns";
import { Checkbox } from "../core/Checkbox";
import CountrySelect from "../core/CountrySelect";
import UsaStateSelect from "../core/UsaStateSelect";
import IdentificationTypeSelect from "../core/IdentificationTypeSelect";

interface ISharedIndividualFormProps {
    ownerBoi: Partial<Individual>;
    onSubmit: (values: Object) => void;
}

const SharedIndividualForm = (props: ISharedIndividualFormProps) => {

    const { ownerBoi, onSubmit } = props;

    const dateFormatted = parseISO(trimTime(ownerBoi.dateOfBirth));

    const handleOnSubmit = (
        values,
        setSubmitting: (isSubmitting: boolean) => void
    ) => {

        const address = {
            addressLine1: values.addressLine1,
            addressLine2: values.addressLine2,
            administrativeArea: values.administrativeArea,
            country: values.country,
            locality: values.locality,
            postalCode: values.postalCode
        };

        const formmatedValues = {
            firstName: values.firstName,
            dateOfBirth: values.dateOfBirth,
            identificationDocument: values.identificationDocument ?? null,
            identificationNumber: values.identificationNumber,
            identificationIssuingJurisdiction: values.identificationIssuingJurisdiction,
            isParentGuardianInfo: values.isParentGuardianInfo,
            dateOfBirthUnknown: values.dateOfBirthUnknown,
            fincenId: values.fincenId,
            identificationType: values.identificationType,
            issuingState: values.issuingState,
            middleName: values.middleName,
            lastName: values.lastName,
            suffix: values.suffix,
            address: address
        }

        onSubmit(formmatedValues);
        setSubmitting(false);
    };

    return (

        <Formik
            initialValues={{
                firstName: ownerBoi.firstName ?? '',
                dateOfBirth: ownerBoi.dateOfBirth ? dateFormatted : new Date(),
                identificationDocument: ownerBoi.identificationDocument ?? null,
                identificationNumber: ownerBoi.identificationNumber ?? '',
                identificationIssuingJurisdiction: ownerBoi.identificationIssuingJurisdiction ?? '',
                addressLine1: ownerBoi.address?.addressLine1 ?? '',
                addressLine2: ownerBoi.address?.addressLine2 ?? '',
                administrativeArea: ownerBoi.address?.administrativeArea ?? '',
                locality: ownerBoi.address?.locality ?? '',
                postalCode: ownerBoi.address?.postalCode ?? '',
                country: ownerBoi.address?.country ?? '',
                isParentGuardianInfo: ownerBoi.isParentGuardianInfo ?? false,
                dateOfBirthUnknown: ownerBoi.dateOfBirthUnknown ?? false,
                fincenId: ownerBoi.fincenId ?? '',
                identificationType: ownerBoi.identificationType ?? '',
                issuingState: ownerBoi.issuingState ?? '',
                middleName: ownerBoi.middleName ?? '',
                lastName: ownerBoi.lastName ?? '',
                suffix: ownerBoi.suffix ?? '',
            }}
            onSubmit={(
                values: FormikValues,
                { setSubmitting }: FormikHelpers<FormikValues>
            ) => {
                handleOnSubmit(values, setSubmitting);
            }}
        >
            {({ errors, isSubmitting, resetForm, submitForm, touched, values }) => (
                <Form className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8">

                    <div className="sm:col-span-4">
                        <h3 className="text-lg font-semibold leading-9 tracking-tight text-gray-900 pb-2">
                            FinCEN ID
                        </h3>
                        <label htmlFor="fincenId" className="text-sm font-medium text-gray-900">FinCEN ID Number </label>
                        <div>
                            <FormikField
                                fieldName="fincenId"
                                errors={errors}
                                touched={touched}
                                placeholder="FinCEN ID"
                            />
                            <p className="text-xs pb-2">If you do not have a FinCEN ID Number, please provide identifying document and personal information below</p>
                        </div>
                    </div>

                    <div className="sm:col-span-4">
                        <h3 className="text-lg font-semibold leading-9 tracking-tight text-gray-900 pb-2">
                            Form of Identification
                        </h3>

                        <label htmlFor="identificationDocument" className="text-sm font-medium text-gray-900">
                            Identification Document
                        </label>
                        <p className="text-xs pb-2">Only .jpg, .png, pdf files, 500kb max file size</p>
                        <FormikField
                            fieldName="identificationDocument"
                            errors={errors}
                            touched={touched}
                            placeholder="Identification Document"
                            className="hidden"
                        >
                            {({ field, form }) => (
                                <div className="block mt-2">
                                    <Input
                                        id="identificationDocument"
                                        onChange={(e) => {
                                            form.setFieldValue(field.name, e.currentTarget.files?.[0]);
                                        }}
                                        name="identificationDocument"
                                        type="file"
                                        className="hidden"
                                    />
                                    <div className="mb-2">
                                        {values.identificationDocument?.filename ? (
                                            <a
                                                href={values.identificationDocument?.url}
                                                target="_blank"
                                                className="text-blue-500 hover:text-blue-800"
                                            >
                                                {values.identificationDocument.filename}
                                            </a>
                                        ) : (
                                            <span className="mb-2">{field.value?.name}</span>
                                        )}
                                    </div>
                                    <label htmlFor='identificationDocument'>
                                        <div className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium
                                      ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 
                                      focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50
                                      bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2">
                                            Upload a file
                                        </div>
                                    </label>
                                </div>
                            )}
                        </FormikField>
                    </div>

                    <div className="sm:col-span-4">
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-row gap-2">
                                <div className="w-1/2">
                                    <FormikField
                                        fieldName="identificationType"
                                        errors={errors}
                                        touched={touched}
                                        placeholder="Identification Type"
                                    >
                                        {({ field, form }) => (
                                            <IdentificationTypeSelect
                                                label="Identification Type"
                                                value={field.value}
                                                handleOnChange={(value) =>
                                                    form.setFieldValue(field.name, value)
                                                }
                                            />
                                        )}
                                    </FormikField>
                                </div>
                                <div className="w-1/2">
                                    <label htmlFor="identificationNumber" className="text-sm font-medium text-gray-900">
                                        Identification Number
                                    </label>
                                    <FormikField
                                        fieldName="identificationNumber"
                                        errors={errors}
                                        touched={touched}
                                        placeholder="Identification Number"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-row gap-2">
                                <div className="w-1/2">
                                    <FormikField
                                        fieldName="identificationIssuingJurisdiction"
                                        errors={errors}
                                        touched={touched}
                                        placeholder="Issuing Country/Jurisdiction *"
                                    >
                                        {({ field, form }) => (
                                            <CountrySelect
                                                label="Issuing Country/Jurisdiction"
                                                value={field.value}
                                                handleOnChange={(value) =>
                                                    form.setFieldValue(field.name, value)
                                                }
                                            />
                                        )}
                                    </FormikField>
                                </div>
                                {values.identificationIssuingJurisdiction === "US" && (
                                    <div className="w-1/2">
                                        <FormikField
                                            fieldName="issuingState"
                                            errors={errors}
                                            touched={touched}
                                            placeholder="Issuing State"
                                        >
                                            {({ field, form }) => (
                                                <UsaStateSelect
                                                    label="Issuing State"
                                                    value={field.value}
                                                    handleOnChange={(value) =>
                                                        form.setFieldValue(field.name, value)
                                                    }
                                                />
                                            )}
                                        </FormikField>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="sm:col-span-4">
                        <h3 className="text-lg font-semibold leading-9 tracking-tight text-gray-900 pb-2">
                            Personally Identifiable Information
                        </h3>
                        <div className="flex flex-row gap-2 items-center mt-2.5 mb-4">
                            <FormikField
                                fieldName='isParentGuardianInfo'
                                errors={errors}
                                touched={touched}
                            >
                                {({ field, form }) => (
                                    <>
                                        <Checkbox
                                            id="isParentGuardianInfo"
                                            {...field}
                                            checked={field.value}
                                            onCheckedChange={(checked) =>
                                                form.setFieldValue(field.name, checked)
                                            }
                                        />
                                        <label
                                            htmlFor="isParentGuardianInfo"
                                            className="text-sm font-medium text-gray-900"
                                        >
                                            Parent/Guardian Information instead of Minor Child
                                        </label>
                                    </>
                                )}
                            </FormikField>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="flex flex-row gap-2">
                                <div>
                                    <label htmlFor="firstName" className="text-sm font-medium text-gray-900">
                                        First Name
                                    </label>
                                    <FormikField
                                        fieldName="firstName"
                                        errors={errors}
                                        touched={touched}
                                        placeholder="First Name"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="middleName" className="text-sm font-medium text-gray-900">
                                        Middle Name
                                    </label>
                                    <FormikField
                                        fieldName="middleName"
                                        errors={errors}
                                        touched={touched}
                                        placeholder="Middle Name"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="lastName" className="text-sm font-medium text-gray-900">
                                        Last Name
                                    </label>
                                    <FormikField
                                        fieldName="lastName"
                                        errors={errors}
                                        touched={touched}
                                        placeholder="Last Name"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="suffix" className="text-sm font-medium text-gray-900">
                                        Suffix
                                    </label>
                                    <FormikField
                                        fieldName="suffix"
                                        errors={errors}
                                        touched={touched}
                                        placeholder="Suffix"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="sm:col-span-4">
                        <div className="flex flex-col gap-2">
                            <div className="flex flex-row gap-2">
                                <div className="flex flex-col flex-1">
                                    <label htmlFor="date" className="text-sm font-medium text-gray-900">
                                        Date of Birth
                                    </label>
                                    <FormikField
                                        fieldName='dateOfBirth'
                                        label="Date"
                                        errors={errors}
                                        touched={touched}
                                        placeholder="Name"
                                    >
                                        {({ field, form }) => (
                                            <DatePicker
                                                id="date"
                                                {...field}
                                                value={field.value ?? new Date()}
                                                onChange={(date) => form.setFieldValue(field.name, date)}
                                            />
                                        )}
                                    </FormikField>
                                </div>
                                <div className="flex flex-col flex-2 mt-auto mb-0">
                                    <div className="flex flex-row gap-2 items-center mt-2.5 mb-4">
                                        <FormikField
                                            fieldName='dateOfBirthUnknown'
                                            errors={errors}
                                            touched={touched}
                                        >
                                            {({ field, form }) => (
                                                <>
                                                    <Checkbox
                                                        id="dateOfBirthUnknown"
                                                        {...field}
                                                        checked={field.value}
                                                        onCheckedChange={(checked) =>
                                                            form.setFieldValue(field.name, checked)
                                                        }
                                                    />
                                                    <label
                                                        htmlFor="dateOfBirthUnknown"
                                                        className="text-sm font-medium text-gray-900"
                                                    >
                                                        Date of Birth Unknown
                                                    </label>
                                                </>
                                            )}
                                        </FormikField>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="sm:col-span-4">
                        <label htmlFor="" className="text-sm font-medium text-gray-900">
                            Address
                        </label>
                        <div className="flex flex-col gap-2">
                            <FormikField
                                fieldName="country"
                                errors={errors}
                                touched={touched}
                                placeholder="Country"
                            />
                            <FormikField
                                fieldName="addressLine1"
                                errors={errors}
                                touched={touched}
                                placeholder="Address Line 1"
                            />
                            <FormikField
                                fieldName="addressLine2"
                                errors={errors}
                                touched={touched}
                                placeholder="Address Line 2"
                            />
                            <div className="flex flex-row gap-2">
                                <FormikField
                                    fieldName="postalCode"
                                    errors={errors}
                                    touched={touched}
                                    placeholder="Zip Code"
                                />
                                <FormikField
                                    fieldName="administrativeArea"
                                    errors={errors}
                                    touched={touched}
                                    placeholder="City"
                                />
                                <FormikField
                                    fieldName="locality"
                                    errors={errors}
                                    touched={touched}
                                    placeholder="State"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="sm:col-span-4 text-center">
                        <Button
                            size='sm'
                            className="w-1/2"
                            type="submit"
                            disabled={isSubmitting || Object.keys(errors).length > 0}
                        >
                            Submit
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>

    )
}

export default SharedIndividualForm;