import React, { useEffect } from "react";
import { Check, ChevronsUpDown, Edit2 } from "lucide-react";
import { cn } from "../../../utils/styles"

import { Button } from "../../core/Button";
import {
    Command,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandSeparator,
} from "../../core/Command";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "../../core/Popover";
import { useRef, useState } from "react";
import TagElement from "./Tag";
import { TagData } from "./Tag";

interface MultiSelectProps {
    orgTags?: TagData[];
    entityTags?: TagData[];
    showFooter?: boolean;
    onChangeTags?: (tags: TagData[]) => void;
}

export function MultiSelect(props: MultiSelectProps) {

    const { orgTags, entityTags, showFooter = false, onChangeTags } = props;

    const orgTagsList = [...(orgTags || [])];
    const entityTagsList = [...(entityTags || [])];

    const inputRef = useRef<HTMLInputElement>(null);
    const [tagsList, setTagsList] = useState<TagData[]>(orgTagsList);
    const [selectedValues, setSelectedValues] = useState<TagData[]>(entityTagsList);
    const [openCombobox, setOpenCombobox] = useState(false);
    const [inputValue, setInputValue] = useState<string>("");

    const createTag = (name: string) => {
        const newTag: TagData = {
            value: name.toLowerCase(),
        };
        setTagsList((prev) => [...prev, newTag]);
        setSelectedValues((prev) => [...prev, newTag]);
    };

    const toggleTag = (tag: TagData) => {
        setSelectedValues((currentTags) =>
            !currentTags.includes(tag)
                ? [...currentTags, tag]
                : currentTags.filter((l) => l.value !== tag.value)
        );
        inputRef?.current?.focus();
    };

    const onComboboxOpenChange = (value: boolean) => {
        inputRef.current?.blur(); // HACK: otherwise, would scroll automatically to the bottom of page
        setOpenCombobox(value);
    };

    useEffect(() => {
        onChangeTags?.(selectedValues);
    }, [selectedValues]);

    return (
        <div className="multi-select">
            <Popover open={openCombobox} onOpenChange={onComboboxOpenChange}>
                <PopoverTrigger asChild>
                    <Button
                        variant="outline"
                        role="combobox"
                        aria-expanded={openCombobox}
                        className="w-full justify-between text-foreground"
                    >
                        <span className="truncate">Search or add new tags...</span>
                        <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-[200px] p-0">
                    <Command loop>
                        <CommandInput
                            ref={inputRef}
                            placeholder="Search tags..."
                            value={inputValue}
                            onValueChange={setInputValue}
                        />
                        <CommandGroup className="overflow-auto" style={{maxHeight: 250}}>
                            {tagsList?.map((tag) => {
                                const isActive = selectedValues.includes(tag);
                                return (
                                    <CommandItem
                                        key={tag.value}
                                        value={tag.value as string}
                                        onSelect={() => toggleTag(tag)}
                                        className="cursor-pointer"
                                    >
                                        <Check
                                            className={cn(
                                                "mr-2 h-4 w-4",
                                                isActive ? "opacity-100" : "opacity-0"
                                            )}
                                        />
                                        <div className="flex-1">{tag.value}</div>
                                        <div
                                            className="h-4 w-4 rounded-full"
                                            style={{ backgroundColor: tag.color }}
                                        />
                                    </CommandItem>
                                );
                            })}
                            <CommandItemCreate
                                onSelect={() => createTag(inputValue)}
                                inputValue={inputValue}
                                tags={entityTags || []}
                            />
                        </CommandGroup>
                        {showFooter && (
                            <>
                                <CommandSeparator alwaysRender />
                                <CommandGroup>
                                    <CommandItem
                                        value={`:${inputValue}:`} // HACK: that way, the edit button will always be shown
                                        className="text-xs text-muted-foreground"
                                    >
                                        <div className={cn("mr-2 h-4 w-4")} />
                                        <Edit2 className="mr-2 h-2.5 w-2.5" />
                                        Go to Tag Manager
                                    </CommandItem>
                                </CommandGroup>
                            </>)}
                    </Command>
                </PopoverContent>
            </Popover>
            <div className="relative mt-3 overflow-y-auto space-y-1">
                {selectedValues?.map(({ id, value }) => (
                    <TagElement key={id} value={value} />
                ))}
            </div>
        </div>
    );
}

const CommandItemCreate = ({
    inputValue,
    tags,
    onSelect,
}: {
    inputValue: string;
    tags: TagData[];
    onSelect: () => void;
}) => {
    const hasNoTag = !tags
        .map(({ value }) => value)
        .includes(`${inputValue.toLowerCase()}`);

    const render = inputValue !== "" && hasNoTag;

    if (!render) return null;

    // BUG: whenever a space is appended, the Create-Button will not be shown.
    return (
        <CommandItem
            key={`${inputValue}`}
            value={`${inputValue}`}
            className="text-xs text-muted-foreground"
            onSelect={onSelect}
        >
            <div className={cn("mr-2 h-4 w-4")} />
            Create new tag &quot;{inputValue}&quot;
        </CommandItem>
    );
};
