import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { IndividualFormIndividualFragmentFragmentDoc } from '../../entities/__generated__/IndividualForm.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FilingCompanyApplicantFormEntityFragmentFragment = { __typename?: 'Entity', id: string, dateOfFormation?: any | null, companyApplicants?: Array<{ __typename?: 'Individual', id: string, fincenId?: string | null, isParentGuardianInfo?: boolean | null, firstName?: string | null, lastName?: string | null, middleName?: string | null, suffix?: string | null, dateOfBirth?: any | null, dateOfBirthUnknown?: boolean | null, identificationType?: string | null, identificationNumber?: string | null, issuingState?: string | null, identificationIssuingJurisdiction?: string | null, addressType?: string | null, identificationDocument?: { __typename?: 'Attachment', url: string, contentType: string, filename: string } | null, address?: { __typename?: 'Address', id: string, nameLine?: string | null, addressLine1?: string | null, addressLine2?: string | null, administrativeArea?: string | null, locality?: string | null, country?: string | null, postalCode?: string | null } | null }> | null };

export type GetFilingCompanyApplicantDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetFilingCompanyApplicantDetailsQuery = { __typename?: 'Query', getFiling: { __typename?: 'Filing', entity: { __typename?: 'Entity', id: string, dateOfFormation?: any | null, companyApplicants?: Array<{ __typename?: 'Individual', id: string, fincenId?: string | null, isParentGuardianInfo?: boolean | null, firstName?: string | null, lastName?: string | null, middleName?: string | null, suffix?: string | null, dateOfBirth?: any | null, dateOfBirthUnknown?: boolean | null, identificationType?: string | null, identificationNumber?: string | null, issuingState?: string | null, identificationIssuingJurisdiction?: string | null, addressType?: string | null, identificationDocument?: { __typename?: 'Attachment', url: string, contentType: string, filename: string } | null, address?: { __typename?: 'Address', id: string, nameLine?: string | null, addressLine1?: string | null, addressLine2?: string | null, administrativeArea?: string | null, locality?: string | null, country?: string | null, postalCode?: string | null } | null }> | null } } };

export type UpdateFilingEntityForCompanyApplicantFormMutationVariables = Types.Exact<{
  input: Types.UpdateEntityInput;
}>;


export type UpdateFilingEntityForCompanyApplicantFormMutation = { __typename?: 'Mutation', updateEntity?: { __typename?: 'UpdateEntityPayload', errors?: Array<string> | null, entity?: { __typename?: 'Entity', id: string, dateOfFormation?: any | null, companyApplicants?: Array<{ __typename?: 'Individual', id: string, fincenId?: string | null, isParentGuardianInfo?: boolean | null, firstName?: string | null, lastName?: string | null, middleName?: string | null, suffix?: string | null, dateOfBirth?: any | null, dateOfBirthUnknown?: boolean | null, identificationType?: string | null, identificationNumber?: string | null, issuingState?: string | null, identificationIssuingJurisdiction?: string | null, addressType?: string | null, identificationDocument?: { __typename?: 'Attachment', url: string, contentType: string, filename: string } | null, address?: { __typename?: 'Address', id: string, nameLine?: string | null, addressLine1?: string | null, addressLine2?: string | null, administrativeArea?: string | null, locality?: string | null, country?: string | null, postalCode?: string | null } | null }> | null } | null } | null };

export const FilingCompanyApplicantFormEntityFragmentFragmentDoc = gql`
    fragment FilingCompanyApplicantFormEntityFragment on Entity {
  id
  dateOfFormation
  companyApplicants {
    ...IndividualFormIndividualFragment
  }
}
    ${IndividualFormIndividualFragmentFragmentDoc}`;
export const GetFilingCompanyApplicantDetailsDocument = gql`
    query getFilingCompanyApplicantDetails($id: ID!) {
  getFiling(id: $id) {
    entity {
      ...FilingCompanyApplicantFormEntityFragment
    }
  }
}
    ${FilingCompanyApplicantFormEntityFragmentFragmentDoc}`;

/**
 * __useGetFilingCompanyApplicantDetailsQuery__
 *
 * To run a query within a React component, call `useGetFilingCompanyApplicantDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilingCompanyApplicantDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilingCompanyApplicantDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFilingCompanyApplicantDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetFilingCompanyApplicantDetailsQuery, GetFilingCompanyApplicantDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFilingCompanyApplicantDetailsQuery, GetFilingCompanyApplicantDetailsQueryVariables>(GetFilingCompanyApplicantDetailsDocument, options);
      }
export function useGetFilingCompanyApplicantDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFilingCompanyApplicantDetailsQuery, GetFilingCompanyApplicantDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFilingCompanyApplicantDetailsQuery, GetFilingCompanyApplicantDetailsQueryVariables>(GetFilingCompanyApplicantDetailsDocument, options);
        }
export function useGetFilingCompanyApplicantDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetFilingCompanyApplicantDetailsQuery, GetFilingCompanyApplicantDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetFilingCompanyApplicantDetailsQuery, GetFilingCompanyApplicantDetailsQueryVariables>(GetFilingCompanyApplicantDetailsDocument, options);
        }
export type GetFilingCompanyApplicantDetailsQueryHookResult = ReturnType<typeof useGetFilingCompanyApplicantDetailsQuery>;
export type GetFilingCompanyApplicantDetailsLazyQueryHookResult = ReturnType<typeof useGetFilingCompanyApplicantDetailsLazyQuery>;
export type GetFilingCompanyApplicantDetailsSuspenseQueryHookResult = ReturnType<typeof useGetFilingCompanyApplicantDetailsSuspenseQuery>;
export type GetFilingCompanyApplicantDetailsQueryResult = Apollo.QueryResult<GetFilingCompanyApplicantDetailsQuery, GetFilingCompanyApplicantDetailsQueryVariables>;
export const UpdateFilingEntityForCompanyApplicantFormDocument = gql`
    mutation updateFilingEntityForCompanyApplicantForm($input: UpdateEntityInput!) {
  updateEntity(input: $input) {
    entity {
      ...FilingCompanyApplicantFormEntityFragment
    }
    errors
  }
}
    ${FilingCompanyApplicantFormEntityFragmentFragmentDoc}`;
export type UpdateFilingEntityForCompanyApplicantFormMutationFn = Apollo.MutationFunction<UpdateFilingEntityForCompanyApplicantFormMutation, UpdateFilingEntityForCompanyApplicantFormMutationVariables>;

/**
 * __useUpdateFilingEntityForCompanyApplicantFormMutation__
 *
 * To run a mutation, you first call `useUpdateFilingEntityForCompanyApplicantFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFilingEntityForCompanyApplicantFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFilingEntityForCompanyApplicantFormMutation, { data, loading, error }] = useUpdateFilingEntityForCompanyApplicantFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFilingEntityForCompanyApplicantFormMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFilingEntityForCompanyApplicantFormMutation, UpdateFilingEntityForCompanyApplicantFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFilingEntityForCompanyApplicantFormMutation, UpdateFilingEntityForCompanyApplicantFormMutationVariables>(UpdateFilingEntityForCompanyApplicantFormDocument, options);
      }
export type UpdateFilingEntityForCompanyApplicantFormMutationHookResult = ReturnType<typeof useUpdateFilingEntityForCompanyApplicantFormMutation>;
export type UpdateFilingEntityForCompanyApplicantFormMutationResult = Apollo.MutationResult<UpdateFilingEntityForCompanyApplicantFormMutation>;
export type UpdateFilingEntityForCompanyApplicantFormMutationOptions = Apollo.BaseMutationOptions<UpdateFilingEntityForCompanyApplicantFormMutation, UpdateFilingEntityForCompanyApplicantFormMutationVariables>;