import { createContext, useContext } from "react";

type UserType = any;

type AuthType = {
  authenticated: boolean,
  initialAuthAttempted: boolean,
  tokenExpired: boolean,
  user: UserType,
}

type AuthContextType = {
  auth: AuthType,
  setAuth: (auth: Partial<AuthType>) => void;
  setAuthStatus: (status: boolean) => void;
  setUserData: (user: any) => void,
  signOut: () => void,
}

export const DEFAULT_AUTH: AuthType = {
  authenticated: false,
  initialAuthAttempted: false,
  tokenExpired: false,
  user: null,
}

export const AuthContext = createContext({} as AuthContextType);

export function useAuth(): AuthContextType {
  return useContext(AuthContext);
}