import * as Types from '../../types';

import { gql } from '@apollo/client';
export type AddressFragmentFragment = { __typename?: 'Address', id: string, nameLine?: string | null, addressLine1?: string | null, addressLine2?: string | null, administrativeArea?: string | null, locality?: string | null, country?: string | null, postalCode?: string | null };

export const AddressFragmentFragmentDoc = gql`
    fragment AddressFragment on Address {
  id
  nameLine
  addressLine1
  addressLine2
  administrativeArea
  locality
  country
  postalCode
}
    `;