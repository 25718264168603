import React from "react";
import { PropsWithChildren } from "react";

interface PageLayoutProps extends PropsWithChildren {
    header?: string;
    headerNode?: React.ReactNode;
    headerActions?: React.ReactNode;
    className?: string;
};

const PageLayout = (props: PageLayoutProps) => {

    const { header, headerNode, className, headerActions, children } = props;

    return (
        <div className={className ?? ""}>
            <div className="flex w-full mx-auto items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
                {header && <h1 className='className="mt-1 text-2xl font-semibold leading-6 text-gray-900"'></h1>}
                {headerNode}
                <div className="flex items-center gap-x-4 sm:gap-x-4">
                    {headerActions}
                </div>
            </div>

            <div className="flex flex-col justify-start gap-4 h-full mt-4">
                {children}
            </div>
        </div>
    );
};
export default PageLayout;