import React from "react";
import { Button } from "../core/Button";
import EntityDetailsForm from "../entities/EntityDetailsForm";
import { Formik } from "formik";
import FilingLayout from "./FilingLayout";
import { gql } from "@apollo/client";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useGetFilingEntityDetailsQuery } from "./__generated__/EditEntityDetails.generated";
import { useUpdateEntityMutation } from "./__generated__/EditEntityDetails.generated";
import { stripTypename } from "@apollo/client/utilities";
import { ENTITY_FRAGMENT } from "../entities/new/EditEntityDetails";

const GET_FILING = gql`
  query getFilingEntityDetails($id: ID!) {
    getFiling(id: $id) {
      entity {
        ...EntityFragment
      }
    }
  }
  ${ENTITY_FRAGMENT}
`;

const UPDATE_ENTITY = gql`
  mutation updateEntity($input: UpdateEntityInput!) {
    updateEntity(input: $input) {
      entity {
        ...EntityFragment
      }
      errors
    }
  }
  ${ENTITY_FRAGMENT}
`;

export default () => {
  const {entityId, filingId } = useParams();

  const { data, loading } = useGetFilingEntityDetailsQuery({
    variables: {
      id: filingId || "",
    },
  });

  const [updateEntityAction, { loading: loadingUpdateEntity }] =
    useUpdateEntityMutation();

  const navigate = useNavigate();

  console.log({data})

  const initialValues = stripTypename(data?.getFiling?.entity);

  const handleUpdateEntity = (values) => {
    updateEntityAction({
      variables: {
        input: {
          id: values.id,
          params: { ...values, id: undefined },
        },
      },
      onCompleted: (data) => {
        if (data?.updateEntity?.errors?.length) {
          toast.error(data?.updateEntity?.errors.join(', \n'))
        } else {
          toast.success("Entity updated successfully");
          navigate(`/edit-filing/${entityId}/${filingId}/beneficial-owners`);
        }
      },
      onError: (error) => {
        toast.error("Something went wrong");
      },
    });
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues || {}}
      onSubmit={handleUpdateEntity}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <FilingLayout>
            <FilingLayout.Body>
              <div className="border rounded-md p-8">
                <h1 className="text-2xl font-semibold mb-4">Entity Details</h1>
                <EntityDetailsForm
                  values={values}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
              </div>
            </FilingLayout.Body>
            <FilingLayout.Actions>
              <Button variant="outline" onClick={() => handleSubmit()}>
                Save and Exit
              </Button>
              <Button
                onClick={() => {
                  handleSubmit();
                }}
              >
                Save and Continue
              </Button>
            </FilingLayout.Actions>
          </FilingLayout>
        </form>
      )}
    </Formik>
  );
};
