import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../core/Button";
import { gql } from "@apollo/client";
import WithLoader from "../core/WithLoader";
import { useListFilingsQuery } from "./__generated__/ListFilings.generated";
import FilingDatatable from "./FilingsDatatable";
import PageLayout from "../layout/PageLayout";
import { useAuth } from "../auth/authContext";

const LIST_FILINGS = gql`
  query listFilings($page: Int, $limit: Int) {
    listFilings(page: $page, limit: $limit) {
      collection {
        id
        filingType
        status
        fincenStatus
        createdAt
        entity {
          id
          legalName
        }
      }
      metadata {
        currentPage
        totalPages
      }
    }
  }
`;

const ListFilings = () => {
  const [page, setPage] = useState(1);

  const { data, loading, refetch } = useListFilingsQuery({
    variables: {
      page: page,
      limit: 10, // You can adjust this value or make it a state variable if needed
    },
    fetchPolicy: "network-only",
  });
  const { auth } = useAuth();
  const navigate = useNavigate();
  const filings = data?.listFilings?.collection;
  console.log({page});

  return (
    <>
      <WithLoader loading={loading}>
        <PageLayout
          className="list-fillings"
          header="Filings"
          headerActions={
            auth.user?.role === "admin" && (
              <Button onClick={() => navigate("/filings/create")}>
                Create Filing
              </Button>
            )
          }
        >
          <FilingDatatable
            data={filings}
            page={page}
            onPageChange={(page) => setPage(page)}
            totalPages={data?.listFilings?.metadata?.totalPages}
          />
        </PageLayout>
      </WithLoader>
    </>
  );
};

export default ListFilings;
