
import * as React from "react"

import { cn } from "../../utils/styles";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
          className
        )}
        ref={ref}
        {...props}
      />
    )
  }
)
Input.displayName = "Input"

export default Input;




// import React, { SyntheticEvent } from "react";

// type InputProps = {
//   id: string;
//   name: string;
//   type: string;
//   label: string;
//   autoComplete?: string;
//   required?: boolean;
//   value: string;
//   onChange: (value) => void;
//   className?: string;
//   placeholder?: string;
// };

// const Input = ({
//   id,
//   name,
//   type,
//   label,
//   autoComplete,
//   required,
//   value,
//   onChange,
//   className,
//   placeholder,
// }: InputProps) => (
//   <div className={className}>
//     <label
//       htmlFor={id}
//       className="block text-sm font-medium leading-6 text-gray-900"
//     >
//       {label}
//     </label>
//       <input
//         id={id}
//         name={name}
//         type={type}
//         value={value}
//         onChange={onChange}
//         autoComplete={autoComplete}
//         required={required}
//         placeholder={placeholder}
//         className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//       />
//   </div>
// );

// export default Input;