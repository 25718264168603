import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AddressFragmentFragmentDoc } from '../../../utils/__generated__/address.generated';
import { IndividualFragmentFragmentDoc } from './IndividualCard.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EntityDetailsEntityFragmentFragment = { __typename?: 'Entity', id: string, legalName?: string | null, jurisdictionCountry?: string | null, jurisdictionState?: string | null, dateOfFormation?: any | null, tradeNames?: Array<string> | null, taxIdentificationNumber?: string | null, isForeignPooledInvestmentVehicle?: boolean | null, visibility: string, address?: { __typename?: 'Address', id: string, nameLine?: string | null, addressLine1?: string | null, addressLine2?: string | null, administrativeArea?: string | null, locality?: string | null, country?: string | null, postalCode?: string | null } | null, beneficialOwners?: Array<{ __typename?: 'Individual', id: string, firstName?: string | null, dateOfBirth?: any | null, identificationNumber?: string | null, identificationIssuingJurisdiction?: string | null, address?: { __typename?: 'Address', id: string, nameLine?: string | null, addressLine1?: string | null, addressLine2?: string | null, administrativeArea?: string | null, locality?: string | null, country?: string | null, postalCode?: string | null } | null }> | null, companyApplicants?: Array<{ __typename?: 'Individual', id: string, firstName?: string | null, dateOfBirth?: any | null, identificationNumber?: string | null, identificationIssuingJurisdiction?: string | null, address?: { __typename?: 'Address', id: string, nameLine?: string | null, addressLine1?: string | null, addressLine2?: string | null, administrativeArea?: string | null, locality?: string | null, country?: string | null, postalCode?: string | null } | null }> | null, filings?: Array<{ __typename?: 'Filing', id: string, status: string, createdAt: any }> | null, invitedUsers?: Array<{ __typename?: 'User', fullName?: string | null, email: string, role: string }> | null };

export type GetEntityForDetailsPageQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetEntityForDetailsPageQuery = { __typename?: 'Query', getEntity: { __typename?: 'Entity', id: string, legalName?: string | null, jurisdictionCountry?: string | null, jurisdictionState?: string | null, dateOfFormation?: any | null, tradeNames?: Array<string> | null, taxIdentificationNumber?: string | null, isForeignPooledInvestmentVehicle?: boolean | null, visibility: string, address?: { __typename?: 'Address', id: string, nameLine?: string | null, addressLine1?: string | null, addressLine2?: string | null, administrativeArea?: string | null, locality?: string | null, country?: string | null, postalCode?: string | null } | null, beneficialOwners?: Array<{ __typename?: 'Individual', id: string, firstName?: string | null, dateOfBirth?: any | null, identificationNumber?: string | null, identificationIssuingJurisdiction?: string | null, address?: { __typename?: 'Address', id: string, nameLine?: string | null, addressLine1?: string | null, addressLine2?: string | null, administrativeArea?: string | null, locality?: string | null, country?: string | null, postalCode?: string | null } | null }> | null, companyApplicants?: Array<{ __typename?: 'Individual', id: string, firstName?: string | null, dateOfBirth?: any | null, identificationNumber?: string | null, identificationIssuingJurisdiction?: string | null, address?: { __typename?: 'Address', id: string, nameLine?: string | null, addressLine1?: string | null, addressLine2?: string | null, administrativeArea?: string | null, locality?: string | null, country?: string | null, postalCode?: string | null } | null }> | null, filings?: Array<{ __typename?: 'Filing', id: string, status: string, createdAt: any }> | null, invitedUsers?: Array<{ __typename?: 'User', fullName?: string | null, email: string, role: string }> | null } };

export type DeleteEntityMutationVariables = Types.Exact<{
  input: Types.DeleteEntityInput;
}>;


export type DeleteEntityMutation = { __typename?: 'Mutation', deleteEntity?: { __typename?: 'DeleteEntityPayload', id?: string | null, errors?: Array<string> | null } | null };

export type InviteUsersToEntityMutationVariables = Types.Exact<{
  input: Types.InviteUsersToEntityInput;
}>;


export type InviteUsersToEntityMutation = { __typename?: 'Mutation', inviteUsersToEntity?: { __typename?: 'InviteUsersToEntityPayload', errors?: Array<string> | null, entity?: { __typename?: 'Entity', legalName?: string | null, visibility: string, invitedUsers?: Array<{ __typename?: 'User', fullName?: string | null, email: string, role: string }> | null } | null } | null };

export type DeleteIndividualMutationVariables = Types.Exact<{
  input: Types.DeleteIndividualInput;
}>;


export type DeleteIndividualMutation = { __typename?: 'Mutation', deleteIndividual?: { __typename?: 'DeleteIndividualPayload', id?: string | null, errors?: Array<string> | null } | null };

export const EntityDetailsEntityFragmentFragmentDoc = gql`
    fragment EntityDetailsEntityFragment on Entity {
  id
  legalName
  jurisdictionCountry
  jurisdictionState
  dateOfFormation
  tradeNames
  taxIdentificationNumber
  address {
    ...AddressFragment
  }
  isForeignPooledInvestmentVehicle
  beneficialOwners {
    ...IndividualFragment
  }
  companyApplicants {
    ...IndividualFragment
  }
  filings {
    id
    status
    createdAt
  }
  visibility
  invitedUsers {
    fullName
    email
    role
  }
}
    ${AddressFragmentFragmentDoc}
${IndividualFragmentFragmentDoc}`;
export const GetEntityForDetailsPageDocument = gql`
    query getEntityForDetailsPage($id: ID!) {
  getEntity(id: $id) {
    ...EntityDetailsEntityFragment
  }
}
    ${EntityDetailsEntityFragmentFragmentDoc}`;

/**
 * __useGetEntityForDetailsPageQuery__
 *
 * To run a query within a React component, call `useGetEntityForDetailsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntityForDetailsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntityForDetailsPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEntityForDetailsPageQuery(baseOptions: Apollo.QueryHookOptions<GetEntityForDetailsPageQuery, GetEntityForDetailsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEntityForDetailsPageQuery, GetEntityForDetailsPageQueryVariables>(GetEntityForDetailsPageDocument, options);
      }
export function useGetEntityForDetailsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEntityForDetailsPageQuery, GetEntityForDetailsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEntityForDetailsPageQuery, GetEntityForDetailsPageQueryVariables>(GetEntityForDetailsPageDocument, options);
        }
export function useGetEntityForDetailsPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetEntityForDetailsPageQuery, GetEntityForDetailsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEntityForDetailsPageQuery, GetEntityForDetailsPageQueryVariables>(GetEntityForDetailsPageDocument, options);
        }
export type GetEntityForDetailsPageQueryHookResult = ReturnType<typeof useGetEntityForDetailsPageQuery>;
export type GetEntityForDetailsPageLazyQueryHookResult = ReturnType<typeof useGetEntityForDetailsPageLazyQuery>;
export type GetEntityForDetailsPageSuspenseQueryHookResult = ReturnType<typeof useGetEntityForDetailsPageSuspenseQuery>;
export type GetEntityForDetailsPageQueryResult = Apollo.QueryResult<GetEntityForDetailsPageQuery, GetEntityForDetailsPageQueryVariables>;
export const DeleteEntityDocument = gql`
    mutation deleteEntity($input: DeleteEntityInput!) {
  deleteEntity(input: $input) {
    id
    errors
  }
}
    `;
export type DeleteEntityMutationFn = Apollo.MutationFunction<DeleteEntityMutation, DeleteEntityMutationVariables>;

/**
 * __useDeleteEntityMutation__
 *
 * To run a mutation, you first call `useDeleteEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEntityMutation, { data, loading, error }] = useDeleteEntityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEntityMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEntityMutation, DeleteEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEntityMutation, DeleteEntityMutationVariables>(DeleteEntityDocument, options);
      }
export type DeleteEntityMutationHookResult = ReturnType<typeof useDeleteEntityMutation>;
export type DeleteEntityMutationResult = Apollo.MutationResult<DeleteEntityMutation>;
export type DeleteEntityMutationOptions = Apollo.BaseMutationOptions<DeleteEntityMutation, DeleteEntityMutationVariables>;
export const InviteUsersToEntityDocument = gql`
    mutation inviteUsersToEntity($input: InviteUsersToEntityInput!) {
  inviteUsersToEntity(input: $input) {
    errors
    entity {
      legalName
      visibility
      invitedUsers {
        fullName
        email
        role
      }
    }
  }
}
    `;
export type InviteUsersToEntityMutationFn = Apollo.MutationFunction<InviteUsersToEntityMutation, InviteUsersToEntityMutationVariables>;

/**
 * __useInviteUsersToEntityMutation__
 *
 * To run a mutation, you first call `useInviteUsersToEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUsersToEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUsersToEntityMutation, { data, loading, error }] = useInviteUsersToEntityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUsersToEntityMutation(baseOptions?: Apollo.MutationHookOptions<InviteUsersToEntityMutation, InviteUsersToEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteUsersToEntityMutation, InviteUsersToEntityMutationVariables>(InviteUsersToEntityDocument, options);
      }
export type InviteUsersToEntityMutationHookResult = ReturnType<typeof useInviteUsersToEntityMutation>;
export type InviteUsersToEntityMutationResult = Apollo.MutationResult<InviteUsersToEntityMutation>;
export type InviteUsersToEntityMutationOptions = Apollo.BaseMutationOptions<InviteUsersToEntityMutation, InviteUsersToEntityMutationVariables>;
export const DeleteIndividualDocument = gql`
    mutation deleteIndividual($input: DeleteIndividualInput!) {
  deleteIndividual(input: $input) {
    id
    errors
  }
}
    `;
export type DeleteIndividualMutationFn = Apollo.MutationFunction<DeleteIndividualMutation, DeleteIndividualMutationVariables>;

/**
 * __useDeleteIndividualMutation__
 *
 * To run a mutation, you first call `useDeleteIndividualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIndividualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIndividualMutation, { data, loading, error }] = useDeleteIndividualMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteIndividualMutation(baseOptions?: Apollo.MutationHookOptions<DeleteIndividualMutation, DeleteIndividualMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteIndividualMutation, DeleteIndividualMutationVariables>(DeleteIndividualDocument, options);
      }
export type DeleteIndividualMutationHookResult = ReturnType<typeof useDeleteIndividualMutation>;
export type DeleteIndividualMutationResult = Apollo.MutationResult<DeleteIndividualMutation>;
export type DeleteIndividualMutationOptions = Apollo.BaseMutationOptions<DeleteIndividualMutation, DeleteIndividualMutationVariables>;