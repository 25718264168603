import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListEntitiesForFilingQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListEntitiesForFilingQuery = { __typename?: 'Query', listEntities?: Array<{ __typename?: 'Entity', id: string, legalName?: string | null }> | null };

export type GetFilingInformationQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetFilingInformationQuery = { __typename?: 'Query', getFiling: { __typename?: 'Filing', id: string, filingType: string, correctedReport: boolean, entity: { __typename?: 'Entity', id: string } } };

export type UpdateFilingInformationMutationVariables = Types.Exact<{
  input: Types.UpdateFilingInput;
}>;


export type UpdateFilingInformationMutation = { __typename?: 'Mutation', updateFiling?: { __typename?: 'UpdateFilingPayload', errors?: Array<string> | null, filing?: { __typename?: 'Filing', id: string, filingType: string, correctedReport: boolean } | null } | null };


export const ListEntitiesForFilingDocument = gql`
    query listEntitiesForFiling {
  listEntities {
    id
    legalName
  }
}
    `;

/**
 * __useListEntitiesForFilingQuery__
 *
 * To run a query within a React component, call `useListEntitiesForFilingQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEntitiesForFilingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEntitiesForFilingQuery({
 *   variables: {
 *   },
 * });
 */
export function useListEntitiesForFilingQuery(baseOptions?: Apollo.QueryHookOptions<ListEntitiesForFilingQuery, ListEntitiesForFilingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListEntitiesForFilingQuery, ListEntitiesForFilingQueryVariables>(ListEntitiesForFilingDocument, options);
      }
export function useListEntitiesForFilingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListEntitiesForFilingQuery, ListEntitiesForFilingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListEntitiesForFilingQuery, ListEntitiesForFilingQueryVariables>(ListEntitiesForFilingDocument, options);
        }
export function useListEntitiesForFilingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListEntitiesForFilingQuery, ListEntitiesForFilingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListEntitiesForFilingQuery, ListEntitiesForFilingQueryVariables>(ListEntitiesForFilingDocument, options);
        }
export type ListEntitiesForFilingQueryHookResult = ReturnType<typeof useListEntitiesForFilingQuery>;
export type ListEntitiesForFilingLazyQueryHookResult = ReturnType<typeof useListEntitiesForFilingLazyQuery>;
export type ListEntitiesForFilingSuspenseQueryHookResult = ReturnType<typeof useListEntitiesForFilingSuspenseQuery>;
export type ListEntitiesForFilingQueryResult = Apollo.QueryResult<ListEntitiesForFilingQuery, ListEntitiesForFilingQueryVariables>;
export const GetFilingInformationDocument = gql`
    query getFilingInformation($id: ID!) {
  getFiling(id: $id) {
    id
    filingType
    correctedReport
    entity {
      id
    }
  }
}
    `;

/**
 * __useGetFilingInformationQuery__
 *
 * To run a query within a React component, call `useGetFilingInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilingInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilingInformationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFilingInformationQuery(baseOptions: Apollo.QueryHookOptions<GetFilingInformationQuery, GetFilingInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFilingInformationQuery, GetFilingInformationQueryVariables>(GetFilingInformationDocument, options);
      }
export function useGetFilingInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFilingInformationQuery, GetFilingInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFilingInformationQuery, GetFilingInformationQueryVariables>(GetFilingInformationDocument, options);
        }
export function useGetFilingInformationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetFilingInformationQuery, GetFilingInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetFilingInformationQuery, GetFilingInformationQueryVariables>(GetFilingInformationDocument, options);
        }
export type GetFilingInformationQueryHookResult = ReturnType<typeof useGetFilingInformationQuery>;
export type GetFilingInformationLazyQueryHookResult = ReturnType<typeof useGetFilingInformationLazyQuery>;
export type GetFilingInformationSuspenseQueryHookResult = ReturnType<typeof useGetFilingInformationSuspenseQuery>;
export type GetFilingInformationQueryResult = Apollo.QueryResult<GetFilingInformationQuery, GetFilingInformationQueryVariables>;
export const UpdateFilingInformationDocument = gql`
    mutation updateFilingInformation($input: UpdateFilingInput!) {
  updateFiling(input: $input) {
    filing {
      id
      filingType
      correctedReport
    }
    errors
  }
}
    `;
export type UpdateFilingInformationMutationFn = Apollo.MutationFunction<UpdateFilingInformationMutation, UpdateFilingInformationMutationVariables>;

/**
 * __useUpdateFilingInformationMutation__
 *
 * To run a mutation, you first call `useUpdateFilingInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFilingInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFilingInformationMutation, { data, loading, error }] = useUpdateFilingInformationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFilingInformationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFilingInformationMutation, UpdateFilingInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFilingInformationMutation, UpdateFilingInformationMutationVariables>(UpdateFilingInformationDocument, options);
      }
export type UpdateFilingInformationMutationHookResult = ReturnType<typeof useUpdateFilingInformationMutation>;
export type UpdateFilingInformationMutationResult = Apollo.MutationResult<UpdateFilingInformationMutation>;
export type UpdateFilingInformationMutationOptions = Apollo.BaseMutationOptions<UpdateFilingInformationMutation, UpdateFilingInformationMutationVariables>;