
import { Modal } from "flowbite-react";
import React from "react";
import { Button } from "./Button";

interface AlertDialog {
  header: string;
  content: string | TrustedHTML;
  visible: boolean;
  loading?: boolean
  onClose: () => void;
  onConfirm: () => void;
};

const AlertDialog = (props: AlertDialog) => {

  const { header, content, loading, visible, onClose, onConfirm } = props;

  return (
    <Modal
      dismissible
      size='md'
      show={visible}
      onClose={onClose}
    >
      <Modal.Header>{header}</Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          <div className="text-base leading-relaxed text-gray-500 dark:text-gray-400"
            dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-between">
        <Button onClick={props.onClose}>
          Cancel
        </Button>
        <Button
          variant="destructive"
          onClick={onConfirm}
          loading={loading}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AlertDialog.defaultProps = {
  header: 'Delete confirmation'
}

export default AlertDialog;
