import React, { useState } from "react";
import PageLayout from "../layout/PageLayout";
import Tabs from "../core/Tabs";
import UsersSettings from "./components/UserSettings";
import Team from "./components/Team";
import { Button } from "../core/Button";
import InviteModal from "./components/InviteModal";
import { gql } from "@apollo/client";
import { useInviteUsersMutation } from "./__generated__/Settings.generated";
import toast from "react-hot-toast";
import { useAuth } from "../auth/authContext";

const INVITE_TEAMMATE = gql`
  mutation inviteUsers($input: InviteUsersInput!) {
    inviteUsers(input: $input) {
      errors
      invitations {
        email
        expireAt
        status
        organization {
            name
        }
      }
    }
  }`;

const Settings = () => {

    const [inviteAction, { loading }] = useInviteUsersMutation();
    const { auth } = useAuth();

    const [currentTab, setCurrentTab] = useState("User Settings");
    const [openInviteModal, setOpenInviteModal] = useState(false);

    const handleOnInvite = (values: { email: string, role: string }[]) => {

        inviteAction({
            variables: {
                input: {
                    invitations: values
                },
            },
            onCompleted: (response) => {
                if (response?.inviteUsers?.errors?.length) {
                    toast.error("Failed to invite user");
                    return;
                }
                toast.success("Invitation sent successfully");
                setOpenInviteModal((prev) => !prev);
            },
        })
    };

    return (
        <>
            <PageLayout
                className="user-settings"
                header="Settings"
                headerActions={auth.user?.role === "admin" &&
                    <Button onClick={() => setOpenInviteModal((prev) => !prev)}>
                        Invite Teammate
                    </Button>
                }
            >
                <Tabs
                    tabs={[
                        {
                            name: "User Settings",
                            current: currentTab === "User Settings",
                            onClick: () => setCurrentTab("User Settings"),
                        },
                        {
                            name: "Team",
                            current: currentTab === "Team",
                            onClick: () => setCurrentTab("Team"),
                        },
                    ]}
                >
                </Tabs>
                {currentTab === "User Settings" && (<UsersSettings />)}
                {currentTab === "Team" && (<Team />)}
            </PageLayout>
            {openInviteModal && (
                <InviteModal
                    visible={openInviteModal}
                    onClose={() => setOpenInviteModal((prev) => !prev)}
                    onSubmit={handleOnInvite}
                />
            )}
        </>
    )
};

export default Settings;