import { Filing } from "../../types";
import { DataTable } from "../core/DataTable";
import { ColumnDef } from "@tanstack/react-table";
import { HiOutlineSwitchVertical } from "react-icons/hi";
import React, { useState } from "react";
import { Button } from "../core/Button";
import IconMenu from "../core/IconMenu";
import { useNavigate } from "react-router-dom";
import AlertDialog from "../core/AlertDialog";
import { gql } from "@apollo/client";
import toast from "react-hot-toast";
import { useListFilingsQuery } from "./__generated__/ListFilings.generated";
import { Badge } from "../core/Badge";
import {
  useDeleteDraftFilingMutation,
  useSubmitFilingMutation,
} from "./__generated__/FilingsDatatable.generated";
import { titlecase } from "../../utils/strings";

const DELETE_DRAFT_FILING = gql`
  mutation deleteDraftFiling($input: DeleteDraftFilingInput!) {
    deleteDraftFiling(input: $input) {
      id
      errors
    }
  }
`;

const SUBMIT_FILING = gql`
  mutation submitFiling($input: SubmitFilingInput!) {
    submitFiling(input: $input) {
      filing {
        id
        status
        entity {
          id
        }
      }
      errors
    }
  }
`;

interface IFilingDatatableProps {
  data?: Filing[];
  page?: number;
  onPageChange?: (page: number) => void;
  totalPages?: number;
}

const FilingDatatable = (props: IFilingDatatableProps) => {
  const { data } = props;
  const navigate = useNavigate();
  const [createFilingAction, { loading: loadingUpdateEntity }] =
    useDeleteDraftFilingMutation();
  const [submitFilingAction, { loading: loadingCreateFiling }] =
    useSubmitFilingMutation();
  const { refetch } = useListFilingsQuery();
  const [selectedFiling, setSelectedFiling] = useState<Filing | null>(null);
  const [showAlertModal, setShowAlertModal] = useState(false);

  const columns: ColumnDef<Filing>[] = [
    {
      accessorKey: "id",
      header: "ID",
    },
    {
      id: "legalName",
      accessorFn: (row) => row.entity.legalName,
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            className="p-0"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Entity
            <HiOutlineSwitchVertical className="h-4 w-4" />
          </Button>
        );
      },
    },
    {
      accessorKey: "filingType",
      header: "Filing Type",
    },
    {
      accessorKey: "createdAt",
      header: "Created At",
      accessorFn: (row) => new Date(row.createdAt).toLocaleDateString(),
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: ({ row }) => <Badge>{titlecase(row.original.status)}</Badge>,
    },
    {
      accessorKey: "fincenStatus",
      header: "FinCEN Status",
      cell: ({ row }) => (
        <Badge variant={row.original.fincenStatus === 'submission_rejected' || row.original.fincenStatus === 'submission_validation_failed' ? 'destructive' : 'default'}>{titlecase(row.original.fincenStatus || "N/A")}</Badge>
      ),
    },
    {
      accessorKey: "actions",
      header: () => <span className="flex justify-end">Actions</span>,
      cell: ({ row }) => {
        return (
          <div className="flex justify-end">
            <IconMenu
              isDisabled={row.original.status === "draft"}
              options={[
                {
                  key: "submit",
                  text: "Submit",
                },
              ]}
              onSelectedOption={(action: string) => {
                setSelectedFiling(row.original);
                handleActionCell(row.original, action);
              }}
            />
          </div>
        );
      },
    },
  ];

  const handleActionCell = (filing: Filing, action: string) => {
    switch (action) {
      case "edit":
        navigate(`/edit-filing/${filing.entity.id}/${filing.id}`);
        break;
      case "delete":
        handleShowAlertModal();
        break;
      case "submit":
        submitFilingAction({
          variables: {
            input: {
              id: filing.id,
            },
          },
        }).then(() => {
          toast.success("Filing submitted");
        });
      default:
        break;
    }
  };

  const handleShowAlertModal = () => {
    setShowAlertModal((prev) => !prev);
  };

  const handleDeleteFiling = () => {
    if (!selectedFiling?.id) return;
    createFilingAction({
      variables: {
        input: {
          id: selectedFiling?.id,
        },
      },
      onCompleted: (data) => {
        if (data?.errors?.length) {
          toast.error("Something went wrong");
        } else {
          toast.success("Filing removed");
          refetch();
          setShowAlertModal((prev) => !prev);
          setSelectedFiling(null);
        }
      },
      onError: (error) => {
        toast.error("Something went wrong");
      },
    });
  };

  return (
    <>
      <DataTable
        columns={columns}
        data={data ?? []}
        searchByKey="legalName"
        page={props.page}
        onPageChange={props.onPageChange}
        totalPages={props.totalPages}
      />
      <AlertDialog
        visible={showAlertModal}
        onClose={handleShowAlertModal}
        content={`Are you sure you want to remove this filing from Entity: <b>${selectedFiling?.entity?.legalName}</b>?`}
        onConfirm={handleDeleteFiling}
      />
    </>
  );
};

export default FilingDatatable;
