import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EntityDetailsFilingEntityFragmentFragment = { __typename?: 'Entity', id: string, legalName?: string | null, filings?: Array<{ __typename?: 'Filing', id: string, filingType: string, createdAt: any, status: string }> | null };

export type ListEntityFilingsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filters?: Types.InputMaybe<Types.FilterParams>;
}>;


export type ListEntityFilingsQuery = { __typename?: 'Query', listFilings: { __typename?: 'FilingCollection', collection: Array<{ __typename?: 'Filing', id: string, filingType: string, status: string, fincenStatus?: string | null, createdAt: any, entity: { __typename?: 'Entity', id: string, legalName?: string | null } }>, metadata: { __typename?: 'CollectionMetadata', currentPage: number, totalPages: number } } };

export type GetEntityForFilingsPageQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetEntityForFilingsPageQuery = { __typename?: 'Query', getEntity: { __typename?: 'Entity', id: string, legalName?: string | null, filings?: Array<{ __typename?: 'Filing', id: string, filingType: string, createdAt: any, status: string }> | null } };

export const EntityDetailsFilingEntityFragmentFragmentDoc = gql`
    fragment EntityDetailsFilingEntityFragment on Entity {
  id
  legalName
  filings {
    id
    filingType
    createdAt
    status
  }
}
    `;
export const ListEntityFilingsDocument = gql`
    query listEntityFilings($page: Int, $limit: Int, $filters: FilterParams) {
  listFilings(page: $page, limit: $limit, filters: $filters) {
    collection {
      id
      filingType
      status
      fincenStatus
      createdAt
      entity {
        id
        legalName
      }
    }
    metadata {
      currentPage
      totalPages
    }
  }
}
    `;

/**
 * __useListEntityFilingsQuery__
 *
 * To run a query within a React component, call `useListEntityFilingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEntityFilingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEntityFilingsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useListEntityFilingsQuery(baseOptions?: Apollo.QueryHookOptions<ListEntityFilingsQuery, ListEntityFilingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListEntityFilingsQuery, ListEntityFilingsQueryVariables>(ListEntityFilingsDocument, options);
      }
export function useListEntityFilingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListEntityFilingsQuery, ListEntityFilingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListEntityFilingsQuery, ListEntityFilingsQueryVariables>(ListEntityFilingsDocument, options);
        }
export function useListEntityFilingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListEntityFilingsQuery, ListEntityFilingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListEntityFilingsQuery, ListEntityFilingsQueryVariables>(ListEntityFilingsDocument, options);
        }
export type ListEntityFilingsQueryHookResult = ReturnType<typeof useListEntityFilingsQuery>;
export type ListEntityFilingsLazyQueryHookResult = ReturnType<typeof useListEntityFilingsLazyQuery>;
export type ListEntityFilingsSuspenseQueryHookResult = ReturnType<typeof useListEntityFilingsSuspenseQuery>;
export type ListEntityFilingsQueryResult = Apollo.QueryResult<ListEntityFilingsQuery, ListEntityFilingsQueryVariables>;
export const GetEntityForFilingsPageDocument = gql`
    query getEntityForFilingsPage($id: ID!) {
  getEntity(id: $id) {
    ...EntityDetailsFilingEntityFragment
  }
}
    ${EntityDetailsFilingEntityFragmentFragmentDoc}`;

/**
 * __useGetEntityForFilingsPageQuery__
 *
 * To run a query within a React component, call `useGetEntityForFilingsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntityForFilingsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntityForFilingsPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEntityForFilingsPageQuery(baseOptions: Apollo.QueryHookOptions<GetEntityForFilingsPageQuery, GetEntityForFilingsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEntityForFilingsPageQuery, GetEntityForFilingsPageQueryVariables>(GetEntityForFilingsPageDocument, options);
      }
export function useGetEntityForFilingsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEntityForFilingsPageQuery, GetEntityForFilingsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEntityForFilingsPageQuery, GetEntityForFilingsPageQueryVariables>(GetEntityForFilingsPageDocument, options);
        }
export function useGetEntityForFilingsPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetEntityForFilingsPageQuery, GetEntityForFilingsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEntityForFilingsPageQuery, GetEntityForFilingsPageQueryVariables>(GetEntityForFilingsPageDocument, options);
        }
export type GetEntityForFilingsPageQueryHookResult = ReturnType<typeof useGetEntityForFilingsPageQuery>;
export type GetEntityForFilingsPageLazyQueryHookResult = ReturnType<typeof useGetEntityForFilingsPageLazyQuery>;
export type GetEntityForFilingsPageSuspenseQueryHookResult = ReturnType<typeof useGetEntityForFilingsPageSuspenseQuery>;
export type GetEntityForFilingsPageQueryResult = Apollo.QueryResult<GetEntityForFilingsPageQuery, GetEntityForFilingsPageQueryVariables>;