import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ShareLinkMutationVariables = Types.Exact<{
  input: Types.ShareLinkInput;
}>;


export type ShareLinkMutation = { __typename?: 'Mutation', shareLink?: { __typename?: 'ShareLinkPayload', sharedLink?: string | null, errors?: Array<string> | null } | null };


export const ShareLinkDocument = gql`
    mutation shareLink($input: ShareLinkInput!) {
  shareLink(input: $input) {
    sharedLink
    errors
  }
}
    `;
export type ShareLinkMutationFn = Apollo.MutationFunction<ShareLinkMutation, ShareLinkMutationVariables>;

/**
 * __useShareLinkMutation__
 *
 * To run a mutation, you first call `useShareLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareLinkMutation, { data, loading, error }] = useShareLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShareLinkMutation(baseOptions?: Apollo.MutationHookOptions<ShareLinkMutation, ShareLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShareLinkMutation, ShareLinkMutationVariables>(ShareLinkDocument, options);
      }
export type ShareLinkMutationHookResult = ReturnType<typeof useShareLinkMutation>;
export type ShareLinkMutationResult = Apollo.MutationResult<ShareLinkMutation>;
export type ShareLinkMutationOptions = Apollo.BaseMutationOptions<ShareLinkMutation, ShareLinkMutationVariables>;