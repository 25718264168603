import React from "react";
import { ADDRESS_FRAGMENT } from "../../utils/address";
import { gql } from "@apollo/client";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../core/Accordion";
import IndividualFormContent from "./IndividualFormContent";

export const INDIVIDUAL_FRAGMENT = gql`
  fragment IndividualFormIndividualFragment on Individual {
    id
    fincenId
    isParentGuardianInfo
    firstName
    lastName
    middleName
    suffix
    dateOfBirth
    dateOfBirthUnknown
    identificationType
    identificationNumber
    issuingState
    identificationIssuingJurisdiction
    identificationDocument {
      url
      contentType
      filename
    }
    address {
      ...AddressFragment
    }
    addressType
  }
  ${ADDRESS_FRAGMENT}
`;

type IndividualFormProps = {
  title: string
  value: any;
  shareButton?: React.ReactNode;
  onChange: (value: any) => void;
  onDelete?: (id: string) => void;
};

export const trimMetadata = (_individual) => {
  const individual = { ..._individual };
  if (individual.isNewRecord) {
    delete individual.isNewRecord;
    delete individual.id;
  }

  if (!individual.uploadChanged) {
    delete individual.identificationDocument;
  }

  delete individual.uploadChanged;

  return individual;
};

export const generateNewIndividual = () => {
  const id = Math.random().toString(36).substring(2, 15);
  return { id: id, isNewRecord: true, uploadChanged: false };
};

export default (props: IndividualFormProps) => {
  const { value, title, onChange, onDelete, shareButton } = props || {};

  return (
    <Accordion type="multiple">
      <AccordionItem value={`item-${value.id}`}>
        <AccordionTrigger>
          <div className="w-full flex justify-start mr-4 items-center">
            <span>{title}</span>
            {shareButton}
          </div>
        </AccordionTrigger>
        <AccordionContent>
          <IndividualFormContent value={value} onChange={onChange} onDelete={onDelete} />
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
