import React, { SyntheticEvent } from "react";

type SmallButtonProps = {
  onClick?: (event: SyntheticEvent) => void;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
};

export default (props: SmallButtonProps) => {
  return (
    <button
      type="button"
      disabled={props.disabled}
      className={`
        ${props.className || ""}
        rounded-md
        bg-white
        px-2.5
        py-1.5
        text-sm
        font-semibold
        text-gray-900
        shadow-sm
        ring-1
        ring-inset
        ring-gray-300
        hover:bg-gray-50
      `}
      onClick={props.onClick}
      >
      {props.children}
    </button>
  );
};
