import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import SignIn from '../auth/SignIn';
import ListEntities from '../entities/ListEntities';
import PrivateRoutes from './PrivateRoutes';
import UnauthenticatedRoutes from './UnauthenticatedRoutes';
import EditEntity from '../entities/new/EditEntity';
import EntityDetails from '../entities/EntityDetails';
import CreateFiling from '../filings/CreateFiling';
import EditFiling from '../filings/EditFiling';
import EntityFilings from '../entities/EntityFilings';
import ListFilings from '../filings/ListFilings';
import SignUp from '../auth/SignUp';
import SharedBoi from '../entities/SharedBoi';
import Settings from '../settings/Settings';
import UsersSettings from '../settings/components/UserSettings';
import Team from '../settings/components/Team';
import SignUpInvite from '../auth/SignUpInvite';

export default () => {
  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        <Route path="/" element={<Navigate to="/entities" replace />} />
        <Route path="/entities" element={<ListEntities />} />
        <Route path="/entities/:entityId/filings" element={<EntityFilings />} />
        <Route path="/entities/:entityId/entity-details" element={<EntityDetails />} />
        <Route path="/entities/:entityId" element={<Navigate to="entity-details" />} />
        <Route path="/edit-entity/:entityId" element={<EditEntity />} />
        <Route path="/edit-entity/:entityId/*" element={<EditEntity />} />
        <Route path="/filings" element={<ListFilings />} />
        <Route path="/filings/create" element={<CreateFiling />} />
        <Route path="/edit-filing/:entityId/:filingId" element={<EditFiling />} />
        <Route path="/edit-filing/:entityId/:filingId/*" element={<EditFiling />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/settings/user" element={<UsersSettings />} />
        <Route path="/settings/team" element={<Team />} />
      </Route>
      <Route element={<UnauthenticatedRoutes />}>
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/login" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/sign-up-invite" element={<SignUpInvite />} />
        <Route path="/beneficial-owners-form" element={<SharedBoi />} />
      </Route>
    </Routes>
  )
}