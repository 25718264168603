import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EntityFragmentFragment = { __typename?: 'Entity', id: string, legalName?: string | null, fincenId?: string | null, foreignTaxCountry?: string | null, identificationType?: string | null, entityType?: string | null, filingType?: string | null, jurisdictionState?: string | null, jurisdictionCountry?: string | null, isCreatedBeforeJan2024?: boolean | null, tradeNames?: Array<string> | null, taxIdentificationNumber?: string | null, isForeignPooledInvestmentVehicle?: boolean | null, dateOfFormation?: any | null, address?: { __typename?: 'Address', id: string, nameLine?: string | null, addressLine1?: string | null, addressLine2?: string | null, administrativeArea?: string | null, locality?: string | null, country?: string | null, postalCode?: string | null } | null, tags?: Array<{ __typename?: 'Tag', id: string, name?: string | null }> | null };

export type GetEntityDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetEntityDetailsQuery = { __typename?: 'Query', getEntity: { __typename?: 'Entity', id: string, legalName?: string | null, fincenId?: string | null, foreignTaxCountry?: string | null, identificationType?: string | null, entityType?: string | null, filingType?: string | null, jurisdictionState?: string | null, jurisdictionCountry?: string | null, isCreatedBeforeJan2024?: boolean | null, tradeNames?: Array<string> | null, taxIdentificationNumber?: string | null, isForeignPooledInvestmentVehicle?: boolean | null, dateOfFormation?: any | null, address?: { __typename?: 'Address', id: string, nameLine?: string | null, addressLine1?: string | null, addressLine2?: string | null, administrativeArea?: string | null, locality?: string | null, country?: string | null, postalCode?: string | null } | null, tags?: Array<{ __typename?: 'Tag', id: string, name?: string | null }> | null } };

export type UpdateEntityMutationVariables = Types.Exact<{
  input: Types.UpdateEntityInput;
}>;


export type UpdateEntityMutation = { __typename?: 'Mutation', updateEntity?: { __typename?: 'UpdateEntityPayload', errors?: Array<string> | null, entity?: { __typename?: 'Entity', id: string, legalName?: string | null, fincenId?: string | null, foreignTaxCountry?: string | null, identificationType?: string | null, entityType?: string | null, filingType?: string | null, jurisdictionState?: string | null, jurisdictionCountry?: string | null, isCreatedBeforeJan2024?: boolean | null, tradeNames?: Array<string> | null, taxIdentificationNumber?: string | null, isForeignPooledInvestmentVehicle?: boolean | null, dateOfFormation?: any | null, address?: { __typename?: 'Address', id: string, nameLine?: string | null, addressLine1?: string | null, addressLine2?: string | null, administrativeArea?: string | null, locality?: string | null, country?: string | null, postalCode?: string | null } | null, tags?: Array<{ __typename?: 'Tag', id: string, name?: string | null }> | null } | null } | null };

export const EntityFragmentFragmentDoc = gql`
    fragment EntityFragment on Entity {
  id
  legalName
  fincenId
  foreignTaxCountry
  identificationType
  entityType
  filingType
  jurisdictionState
  jurisdictionCountry
  isCreatedBeforeJan2024
  tradeNames
  taxIdentificationNumber
  jurisdictionState
  isForeignPooledInvestmentVehicle
  dateOfFormation
  address {
    id
    nameLine
    addressLine1
    addressLine2
    administrativeArea
    locality
    country
    postalCode
  }
  tags {
    id
    name
  }
}
    `;
export const GetEntityDetailsDocument = gql`
    query getEntityDetails($id: ID!) {
  getEntity(id: $id) {
    ...EntityFragment
  }
}
    ${EntityFragmentFragmentDoc}`;

/**
 * __useGetEntityDetailsQuery__
 *
 * To run a query within a React component, call `useGetEntityDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntityDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntityDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEntityDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetEntityDetailsQuery, GetEntityDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEntityDetailsQuery, GetEntityDetailsQueryVariables>(GetEntityDetailsDocument, options);
      }
export function useGetEntityDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEntityDetailsQuery, GetEntityDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEntityDetailsQuery, GetEntityDetailsQueryVariables>(GetEntityDetailsDocument, options);
        }
export function useGetEntityDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetEntityDetailsQuery, GetEntityDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEntityDetailsQuery, GetEntityDetailsQueryVariables>(GetEntityDetailsDocument, options);
        }
export type GetEntityDetailsQueryHookResult = ReturnType<typeof useGetEntityDetailsQuery>;
export type GetEntityDetailsLazyQueryHookResult = ReturnType<typeof useGetEntityDetailsLazyQuery>;
export type GetEntityDetailsSuspenseQueryHookResult = ReturnType<typeof useGetEntityDetailsSuspenseQuery>;
export type GetEntityDetailsQueryResult = Apollo.QueryResult<GetEntityDetailsQuery, GetEntityDetailsQueryVariables>;
export const UpdateEntityDocument = gql`
    mutation updateEntity($input: UpdateEntityInput!) {
  updateEntity(input: $input) {
    entity {
      ...EntityFragment
    }
    errors
  }
}
    ${EntityFragmentFragmentDoc}`;
export type UpdateEntityMutationFn = Apollo.MutationFunction<UpdateEntityMutation, UpdateEntityMutationVariables>;

/**
 * __useUpdateEntityMutation__
 *
 * To run a mutation, you first call `useUpdateEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntityMutation, { data, loading, error }] = useUpdateEntityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEntityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEntityMutation, UpdateEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEntityMutation, UpdateEntityMutationVariables>(UpdateEntityDocument, options);
      }
export type UpdateEntityMutationHookResult = ReturnType<typeof useUpdateEntityMutation>;
export type UpdateEntityMutationResult = Apollo.MutationResult<UpdateEntityMutation>;
export type UpdateEntityMutationOptions = Apollo.BaseMutationOptions<UpdateEntityMutation, UpdateEntityMutationVariables>;