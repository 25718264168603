import React from "react";
import AppLayout from "../layout/AppLayout";
import Steps from "../core/Steps";
import { matchPath, useMatch, useNavigate, useParams } from "react-router-dom";
import { gql } from "@apollo/client";
import { useGetFilingForLayoutQuery } from "./__generated__/FilingLayout.generated";

const GET_FILING = gql`
  query getFilingForLayout($id: ID!) {
    getFiling(id: $id) {
      id
      entity {
        entityDetailsComplete
        companyApplicantsComplete
        beneficialOwnersComplete
      }
    }
  }
`;

const FilingLayout = ({ children }) => {
  const navigate = useNavigate();
  const { entityId, filingId } = useParams();

  const { data, loading } = useGetFilingForLayoutQuery({
    variables: { id: filingId || "" },
  });

  const childrenArray = React.Children.toArray(children);

  const Body = childrenArray.find(child => child.type.displayName === 'Body');
  const Actions = childrenArray.find(child => child.type.displayName === 'Actions');

  const steps = [{
    label: "Filing Information",
    status: "complete" as "complete",
    onClick: () => navigate(`/edit-filing/${entityId}/${filingId}/filing-information`),
    active: useMatch(`/edit-filing/:entityId/:filingId/filing-information`) !== null,
    disabled: !filingId,
  },
  {
    label: "Entity Details",
    status: data?.getFiling?.entity?.entityDetailsComplete ? "complete" : "incomplete",
    onClick: () => navigate(`/edit-filing/${entityId}/${filingId}/entity-details`),
    active: useMatch(`/edit-filing/:entityId/:filingId/entity-details`) !== null,
    disabled: !filingId,
  },
  {
    label: "Beneficial Owners",
    status: data?.getFiling?.entity?.beneficialOwnersComplete ? "complete" : "incomplete",
    onClick: () => navigate(`/edit-filing/${entityId}/${filingId}/beneficial-owners`),
    active: useMatch(`/edit-filing/:entityId/:filingId/beneficial-owners`) !== null,
    disabled: !filingId,
  },
  {
    label: "Company Applicants",
    status: data?.getFiling?.entity?.companyApplicantsComplete ? "complete" : "incomplete",
    onClick: () => navigate(`/edit-filing/${entityId}/${filingId}/company-applicants`),
    active: useMatch(`/edit-filing/:entityId/:filingId/company-applicants`) !== null,
    disabled: !filingId,
  },
  {
    label: "Review",
    status: "incomplete" as "incomplete",
    onClick: () => navigate(`/edit-filing/${entityId}/${filingId}/review`),
    active: useMatch(`/edit-filing/:entityId/:filingId/review`) !== null,
    disabled: !filingId,
  }
];

  return (
    <>
      <div className="flex flex-col -mt-4">
        <div className="flex flex-1 overflow-hidden p-4 pl-2">
          <div className="flex mr-4 mt-8">
            <Steps
              steps={steps}
            />
          </div>
          <div className="flex flex-1 flex-col h-full">
            <div className="flex flex-1 overflow-y-auto paragraph px-4">
              <div className="p-  4 h-full w-full">
                <div className="h-full">
                  {Body}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4 flex w-full flex-row justify-end items-end gap-4 border-t pr-2">
          {Actions}
        </div>
      </div>
    </>
  );
  
};

const Body = (props) => <div>{props.children}</div>;
Body.displayName = 'Body';
FilingLayout.Body = Body;

const Actions = (props) => <div className="gap-4 flex">{props.children}</div>;
Actions.displayName = 'Actions';
FilingLayout.Actions = Actions;

export default FilingLayout
