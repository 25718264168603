import React from "react";
import { DataTable } from "../../core/DataTable";
import { User } from "@/app/javascript/types";
import { ColumnDef } from "@tanstack/react-table";

interface ITeamDatableProps {
    data?: User[];
}

const TeamDatable = (props: ITeamDatableProps) => {
    const { data } = props;

    const columns: ColumnDef<User>[] = [
        {
            accessorKey: "fullName",
            header: "Full Name",
        },
        {
            accessorKey: "email",
            header: "Email",
        },
        {
            id: "status",
            accessorFn: (row) => row.invitation?.status,
            header: "Invitation Status",
        },
        {
            accessorKey: "createdAt",
            header: "Created At",
            accessorFn: (row) => new Date(row.createdAt).toLocaleDateString(),
        },
        {
            accessorKey: "role",
            header: "Role",
        }
    ];

    return (
        <DataTable
            columns={columns}
            data={data ?? []}
            searchByKey="email"
        />
    );
};

export default TeamDatable;