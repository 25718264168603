import React from "react";
import TeamDatable from "./TeamDatatable";
import { gql } from "@apollo/client";
import { useListOrganizationsMembersQuery } from "./__generated__/Team.generated";
import WithLoader from "../../core/WithLoader";
import { USER_FRAGMENT } from "../../auth/types/UserFragment";

const LIST_ORGANIZATIONS_MEMBERS = gql`
  query listOrganizationsMembers {
    listOrganizationMembers {
        ...AuthUserType
    }
    ${USER_FRAGMENT}
  }`;


const Team = () => {

    const { data, loading, refetch } = useListOrganizationsMembersQuery();

    return (
        <WithLoader loading={loading}>
            <TeamDatable data={data?.listOrganizationMembers} />
        </WithLoader>
    )
}

export default Team;
