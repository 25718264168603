import React from "react";

type LabelProps = {
  children: React.ReactNode,
  htmlFor?: string,
  className?: string
}

const Label: React.FC<LabelProps> = (props: LabelProps) => {
  return (
    <label
      htmlFor={props.htmlFor}
      className={`block text-sm font-medium leading-6 text-gray-900 ${props.className ?? ''}`}
    >
      {props.children}
    </label>
  );
};

export default Label