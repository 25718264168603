import { Modal } from "flowbite-react";
import React, { useState } from "react";
import { MultiSelect } from "./components/MultiSelect";
import { TagData } from "./components/Tag";
import { Button } from "../core/Button";
import { SelectedRowData } from "../entities/ListEntities";
import { Tag } from "../../types";

interface AddTagsModalProps {
    selectedRowsData: SelectedRowData;
    listOrgTags?: TagData[];
    visible: boolean;
    onClose: () => void;
    onSubmit: (tags: string[]) => void;
};

const AddTagsModal = (props: AddTagsModalProps) => {
    const { selectedRowsData, listOrgTags, visible, onClose, onSubmit, } = props;
    const [selectedTags, setSelectedTags] = useState<TagData[]>([]);

    const handleChangeTags = (tags: TagData[]) => {
        setSelectedTags(tags);
    };

    const handleSubmit = () => {
        onSubmit(selectedTags?.map((tag) => tag.value).filter((tag): tag is string => tag !== undefined));
    };


    return (
        <Modal
            show={visible}
            onClose={onClose}
            size='lg'
        >
            <Modal.Header>Add Tags to entities: {selectedRowsData?.name}</Modal.Header>
            <Modal.Body className="overflow-visible" style={{ paddingTop: 16, paddingBottom: 16 }}>
                <div className="space-y-4">
                    <div className="flex flex-col grow">
                        <MultiSelect
                            orgTags={listOrgTags}
                            entityTags={selectedRowsData?.tags}
                            onChangeTags={handleChangeTags}
                        ></MultiSelect>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="justify-between">
                <Button onClick={onClose} variant='outline'>Cancel</Button>
                <Button onClick={handleSubmit}>Save</Button>
            </Modal.Footer>
        </Modal>
    )
};

export default AddTagsModal;