import { Label, Spinner } from "flowbite-react";
import React from "react";

type WithLoaderProps = {
  loading: boolean;
  children: React.ReactNode;
  labelText?: string;
};

const WithLoader: React.FC<WithLoaderProps> = ({
  loading,
  children,
  labelText,
}: WithLoaderProps) => {
  return loading ? (
    <div className="flex h-screen">
      <div className="m-auto">
        <Spinner />
        {labelText && <Label>{labelText}</Label>}
      </div>
    </div>
  ) : (
    children
  );
};

export default WithLoader;
