import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AddressFragmentFragmentDoc } from '../../../utils/__generated__/address.generated';
export type IndividualFormIndividualFragmentFragment = { __typename?: 'Individual', id: string, fincenId?: string | null, isParentGuardianInfo?: boolean | null, firstName?: string | null, lastName?: string | null, middleName?: string | null, suffix?: string | null, dateOfBirth?: any | null, dateOfBirthUnknown?: boolean | null, identificationType?: string | null, identificationNumber?: string | null, issuingState?: string | null, identificationIssuingJurisdiction?: string | null, addressType?: string | null, identificationDocument?: { __typename?: 'Attachment', url: string, contentType: string, filename: string } | null, address?: { __typename?: 'Address', id: string, nameLine?: string | null, addressLine1?: string | null, addressLine2?: string | null, administrativeArea?: string | null, locality?: string | null, country?: string | null, postalCode?: string | null } | null };

export const IndividualFormIndividualFragmentFragmentDoc = gql`
    fragment IndividualFormIndividualFragment on Individual {
  id
  fincenId
  isParentGuardianInfo
  firstName
  lastName
  middleName
  suffix
  dateOfBirth
  dateOfBirthUnknown
  identificationType
  identificationNumber
  issuingState
  identificationIssuingJurisdiction
  identificationDocument {
    url
    contentType
    filename
  }
  address {
    ...AddressFragment
  }
  addressType
}
    ${AddressFragmentFragmentDoc}`;