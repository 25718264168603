import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { IndividualFormIndividualFragmentFragmentDoc } from './IndividualForm.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VerifySharedLinkQueryVariables = Types.Exact<{
  token: Types.Scalars['String']['input'];
}>;


export type VerifySharedLinkQuery = { __typename?: 'Query', verifySharedLink?: { __typename?: 'Individual', id: string, fincenId?: string | null, isParentGuardianInfo?: boolean | null, firstName?: string | null, lastName?: string | null, middleName?: string | null, suffix?: string | null, dateOfBirth?: any | null, dateOfBirthUnknown?: boolean | null, identificationType?: string | null, identificationNumber?: string | null, issuingState?: string | null, identificationIssuingJurisdiction?: string | null, addressType?: string | null, identificationDocument?: { __typename?: 'Attachment', url: string, contentType: string, filename: string } | null, address?: { __typename?: 'Address', id: string, nameLine?: string | null, addressLine1?: string | null, addressLine2?: string | null, administrativeArea?: string | null, locality?: string | null, country?: string | null, postalCode?: string | null } | null } | null };

export type UpsertIndividualMutationVariables = Types.Exact<{
  input: Types.UpsertIndividualInput;
}>;


export type UpsertIndividualMutation = { __typename?: 'Mutation', upsertIndividual?: { __typename?: 'UpsertIndividualPayload', errors?: Array<string> | null, individual?: { __typename?: 'Individual', id: string, firstName?: string | null } | null } | null };


export const VerifySharedLinkDocument = gql`
    query verifySharedLink($token: String!) {
  verifySharedLink(token: $token) {
    ...IndividualFormIndividualFragment
  }
}
    ${IndividualFormIndividualFragmentFragmentDoc}`;

/**
 * __useVerifySharedLinkQuery__
 *
 * To run a query within a React component, call `useVerifySharedLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifySharedLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifySharedLinkQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifySharedLinkQuery(baseOptions: Apollo.QueryHookOptions<VerifySharedLinkQuery, VerifySharedLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifySharedLinkQuery, VerifySharedLinkQueryVariables>(VerifySharedLinkDocument, options);
      }
export function useVerifySharedLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifySharedLinkQuery, VerifySharedLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifySharedLinkQuery, VerifySharedLinkQueryVariables>(VerifySharedLinkDocument, options);
        }
export function useVerifySharedLinkSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VerifySharedLinkQuery, VerifySharedLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VerifySharedLinkQuery, VerifySharedLinkQueryVariables>(VerifySharedLinkDocument, options);
        }
export type VerifySharedLinkQueryHookResult = ReturnType<typeof useVerifySharedLinkQuery>;
export type VerifySharedLinkLazyQueryHookResult = ReturnType<typeof useVerifySharedLinkLazyQuery>;
export type VerifySharedLinkSuspenseQueryHookResult = ReturnType<typeof useVerifySharedLinkSuspenseQuery>;
export type VerifySharedLinkQueryResult = Apollo.QueryResult<VerifySharedLinkQuery, VerifySharedLinkQueryVariables>;
export const UpsertIndividualDocument = gql`
    mutation upsertIndividual($input: UpsertIndividualInput!) {
  upsertIndividual(input: $input) {
    individual {
      id
      firstName
    }
    errors
  }
}
    `;
export type UpsertIndividualMutationFn = Apollo.MutationFunction<UpsertIndividualMutation, UpsertIndividualMutationVariables>;

/**
 * __useUpsertIndividualMutation__
 *
 * To run a mutation, you first call `useUpsertIndividualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertIndividualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertIndividualMutation, { data, loading, error }] = useUpsertIndividualMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertIndividualMutation(baseOptions?: Apollo.MutationHookOptions<UpsertIndividualMutation, UpsertIndividualMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertIndividualMutation, UpsertIndividualMutationVariables>(UpsertIndividualDocument, options);
      }
export type UpsertIndividualMutationHookResult = ReturnType<typeof useUpsertIndividualMutation>;
export type UpsertIndividualMutationResult = Apollo.MutationResult<UpsertIndividualMutation>;
export type UpsertIndividualMutationOptions = Apollo.BaseMutationOptions<UpsertIndividualMutation, UpsertIndividualMutationVariables>;