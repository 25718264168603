import { useEffect, useState } from "react";
import Label from "../core/Label";
import Input from "../core/Input";
import { Button } from "../core/Button";
import { HiOutlineDuplicate } from "react-icons/hi";
import { Modal } from "flowbite-react";
import copy from "copy-to-clipboard";
import React from "react";

interface ISharedBoiModalProps {
    shareUrl: string | null;
    visible: boolean;
    onClose: () => void;
}

const SharedBoiModal = (props: ISharedBoiModalProps) => {

    const { visible, shareUrl, onClose } = props;
    const [wasCopied, setWasCopied] = useState(false);

    const handleCopyText = () => {
        if (shareUrl) {
            copy(shareUrl);
            setWasCopied(true);
        }
    };

    useEffect(() => {
        if (wasCopied) {
            setTimeout(() => {
                setWasCopied(false);
            }, 3000);
        }
    }, [wasCopied])

    return (
        <Modal
            dismissible
            size='lg'
            show={visible}
            onClose={onClose}
        >
            <Modal.Header>Share link with Beneficial Owner</Modal.Header>
            <Modal.Body>
                <div className="space-y-6">
                    <span className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                        Anyone who has this link will be able to view and modify the Beneficial Owner form.
                    </span>
                    <div className="flex items-center space-x-2">
                        <div className="grid flex-1 gap-2">
                            <Label htmlFor="link" className="sr-only">
                                Link
                            </Label>
                            <Input
                                id="link"
                                defaultValue={shareUrl as string}
                                readOnly
                            />
                        </div>
                        <Button type="submit" onClick={handleCopyText} size="sm" className="px-3">
                            <span className="sr-only">Copy</span>
                            <HiOutlineDuplicate className="h-4 w-4" />
                        </Button>
                    </div>
                </div>
                {wasCopied && <span className="flex p-2 items-center text-green-500">Link copied successfully!!</span>}
            </Modal.Body>
            <Modal.Footer className="justify-start">
                <Button onClick={props.onClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>


    )
};

export default SharedBoiModal;