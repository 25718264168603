import React, { useEffect, useState } from "react";
import * as Yup from 'yup';
import Heading from "../core/Heading";
import toast from "react-hot-toast";
import { gql } from "@apollo/client";
import { Form, Formik, FormikHelpers } from "formik";
import { Button } from "../core/Button";
import Label from "../core/Label";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FormikPassword from "./components/FormikPassword";
import FormikField from "./components/FormikField";
import { signInUser } from "../../utils/auth";
import { useAuth } from "./authContext";
import { useCreateUserMutation, useVerifyInvitationMutation } from "./__generated__/SignUpInvite.generated";
import WithLoader from "../core/WithLoader";

interface FormikValues {
    fullName: string;
    email: string;
    organizationName: string;
    password: string;
    repeatPassword: string;
}

const SignUpSchema = Yup.object().shape({
    fullName: Yup.string()
        .min(2, 'Full name should be at least 2 characters')
        .max(70, 'Full name should not exceed 70 characters')
        .required('Required')
    ,
    email: Yup.string()
        .email('Invalid email address')
        .required('Required')
    ,
    organizationName: Yup.string()
        .min(2, 'Organization name should be at least 2 characters')
        .max(30, 'Organization name should not exceed 30 characters')
        .required('Required')
    ,
    password: Yup.string()
        .min(8, 'Password should be at least 8 characters')
        .required('Required')
    ,
    repeatPassword: Yup.string()
        .oneOf([Yup.ref('password'), undefined], "Passwords must match")
        .required('Required')
});

const CREATE_USER_MUTATION = gql`
  mutation createUser($input: CreateInput!) {
    createUser(input: $input) {
        user {
            email
            fullName
            authenticationToken
          }
          errors
    }
  }
`;

const VERIFY_INVITATION_MUTATION = gql`
  mutation verifyInvitation($input: VerifyInvitationInput!) {
    verifyInvitation(input: $input) {
    errors
    organizationName
    email
  }
 }
`;

const SignUpInvite = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tokenId = queryParams.get('invite');

    const { setAuthStatus, setUserData } = useAuth();
    const [verifyInvitationAction, { loading: isLoadingInvite, data }] = useVerifyInvitationMutation();
    const [createUserAction, { loading }] = useCreateUserMutation();

    useEffect(() => {
        verifyInvitationAction({
            variables: {
                input: {
                    invitationToken: tokenId as string,
                }
            },
            onCompleted: (response) => {
                if (response.verifyInvitation?.errors?.length) {
                    toast.error('Invalid token. Please try again.');
                    navigate('/login');
                }
            }
        })
    }, []);

    const handleOnSubmit = (
        values: { fullName: string; email: string; organizationName: string; password: string; repeatPassword: string; },
        setSubmitting: (isSubmitting: boolean) => void
    ) => {
        createUserAction({
            variables: {
                input: {
                    invitationToken: tokenId as string,
                    user: {
                        fullName: values.fullName,
                        email: values.email,    
                        password: values.password,              
                    }
                }
            },
            onCompleted: (response) => {
                setSubmitting(false);
                if (response.createUser?.errors?.length) {
                    toast.error("Something went wrong");
                } else {
                    toast.success('Sign up successful')
                    signInUser(response.createUser?.user?.authenticationToken);
                    setAuthStatus(true);
                    setUserData(response.createUser?.user)
                }
            },
        })
    };

    return (
        <WithLoader loading={isLoadingInvite}>
            <div className="sign-up-container">
                <Heading className="text-center" text="Sign up new account" />
                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <Formik
                        initialValues={{
                            fullName: '',
                            email: data?.verifyInvitation?.email as string,
                            organizationName: data?.verifyInvitation?.organizationName as string,
                            password: '',
                            repeatPassword: ''
                        }}
                        validationSchema={SignUpSchema}
                        onSubmit={(
                            values: FormikValues,
                            { setSubmitting }: FormikHelpers<FormikValues>
                        ) => {
                            handleOnSubmit(values, setSubmitting);
                        }}
                    >
                        {({ errors, isSubmitting, resetForm, submitForm, touched, values }) => (
                            <Form className="space-y-6 text-center">
                                <FormikField
                                    fieldName="fullName"
                                    errors={errors}
                                    touched={touched}
                                    placeholder="Full Name"
                                    required
                                />

                                <FormikField
                                    fieldName="email"
                                    errors={errors}
                                    touched={touched}
                                    placeholder="Email"
                                    type="email"
                                    disabled
                                />

                                <FormikField
                                    fieldName="organizationName"
                                    errors={errors}
                                    touched={touched}
                                    placeholder="Organization Name"
                                    disabled
                                />

                                <FormikPassword fieldName="password"
                                    errors={errors}
                                    touched={touched}
                                    placeholder="Password"
                                    required
                                />

                                <FormikPassword fieldName="repeatPassword"
                                    errors={errors}
                                    touched={touched}
                                    placeholder="Repeat Password"
                                    required
                                />

                                <Button
                                    size='lg'
                                    className="w-full"
                                    type="submit"
                                    disabled={isSubmitting || Object.keys(errors).length > 0 || loading}
                                >
                                    Sign Up
                                </Button>
                                <Label className="text-center">
                                    <Link className="underline" to='/login'>Back to Sign in</Link>
                                </Label>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </WithLoader>
    );
}

export default SignUpInvite
