import React from "react";
import { PropsWithChildren } from "react";
import Logo from "../core/Logo";

const AppPublicLayout = (props: PropsWithChildren) => {
    return (
        <div className="min-h-full flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="w-full space-y-8">
                <Logo />
                {props.children}
            </div>
        </div>
    );
};

export default AppPublicLayout;