import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListFilingsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type ListFilingsQuery = { __typename?: 'Query', listFilings: { __typename?: 'FilingCollection', collection: Array<{ __typename?: 'Filing', id: string, filingType: string, status: string, fincenStatus?: string | null, createdAt: any, entity: { __typename?: 'Entity', id: string, legalName?: string | null } }>, metadata: { __typename?: 'CollectionMetadata', currentPage: number, totalPages: number } } };


export const ListFilingsDocument = gql`
    query listFilings($page: Int, $limit: Int) {
  listFilings(page: $page, limit: $limit) {
    collection {
      id
      filingType
      status
      fincenStatus
      createdAt
      entity {
        id
        legalName
      }
    }
    metadata {
      currentPage
      totalPages
    }
  }
}
    `;

/**
 * __useListFilingsQuery__
 *
 * To run a query within a React component, call `useListFilingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFilingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFilingsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useListFilingsQuery(baseOptions?: Apollo.QueryHookOptions<ListFilingsQuery, ListFilingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListFilingsQuery, ListFilingsQueryVariables>(ListFilingsDocument, options);
      }
export function useListFilingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListFilingsQuery, ListFilingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListFilingsQuery, ListFilingsQueryVariables>(ListFilingsDocument, options);
        }
export function useListFilingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListFilingsQuery, ListFilingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListFilingsQuery, ListFilingsQueryVariables>(ListFilingsDocument, options);
        }
export type ListFilingsQueryHookResult = ReturnType<typeof useListFilingsQuery>;
export type ListFilingsLazyQueryHookResult = ReturnType<typeof useListFilingsLazyQuery>;
export type ListFilingsSuspenseQueryHookResult = ReturnType<typeof useListFilingsSuspenseQuery>;
export type ListFilingsQueryResult = Apollo.QueryResult<ListFilingsQuery, ListFilingsQueryVariables>;