import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListEntitiesForFilingQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListEntitiesForFilingQuery = { __typename?: 'Query', listEntities?: Array<{ __typename?: 'Entity', id: string, legalName?: string | null }> | null };

export type CreateFilingMutationVariables = Types.Exact<{
  input: Types.CreateFilingInput;
}>;


export type CreateFilingMutation = { __typename?: 'Mutation', createFiling?: { __typename?: 'CreateFilingPayload', errors?: Array<string> | null, filing?: { __typename?: 'Filing', id: string } | null } | null };


export const ListEntitiesForFilingDocument = gql`
    query listEntitiesForFiling {
  listEntities {
    id
    legalName
  }
}
    `;

/**
 * __useListEntitiesForFilingQuery__
 *
 * To run a query within a React component, call `useListEntitiesForFilingQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEntitiesForFilingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEntitiesForFilingQuery({
 *   variables: {
 *   },
 * });
 */
export function useListEntitiesForFilingQuery(baseOptions?: Apollo.QueryHookOptions<ListEntitiesForFilingQuery, ListEntitiesForFilingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListEntitiesForFilingQuery, ListEntitiesForFilingQueryVariables>(ListEntitiesForFilingDocument, options);
      }
export function useListEntitiesForFilingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListEntitiesForFilingQuery, ListEntitiesForFilingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListEntitiesForFilingQuery, ListEntitiesForFilingQueryVariables>(ListEntitiesForFilingDocument, options);
        }
export function useListEntitiesForFilingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListEntitiesForFilingQuery, ListEntitiesForFilingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListEntitiesForFilingQuery, ListEntitiesForFilingQueryVariables>(ListEntitiesForFilingDocument, options);
        }
export type ListEntitiesForFilingQueryHookResult = ReturnType<typeof useListEntitiesForFilingQuery>;
export type ListEntitiesForFilingLazyQueryHookResult = ReturnType<typeof useListEntitiesForFilingLazyQuery>;
export type ListEntitiesForFilingSuspenseQueryHookResult = ReturnType<typeof useListEntitiesForFilingSuspenseQuery>;
export type ListEntitiesForFilingQueryResult = Apollo.QueryResult<ListEntitiesForFilingQuery, ListEntitiesForFilingQueryVariables>;
export const CreateFilingDocument = gql`
    mutation createFiling($input: CreateFilingInput!) {
  createFiling(input: $input) {
    filing {
      id
    }
    errors
  }
}
    `;
export type CreateFilingMutationFn = Apollo.MutationFunction<CreateFilingMutation, CreateFilingMutationVariables>;

/**
 * __useCreateFilingMutation__
 *
 * To run a mutation, you first call `useCreateFilingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFilingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFilingMutation, { data, loading, error }] = useCreateFilingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFilingMutation(baseOptions?: Apollo.MutationHookOptions<CreateFilingMutation, CreateFilingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFilingMutation, CreateFilingMutationVariables>(CreateFilingDocument, options);
      }
export type CreateFilingMutationHookResult = ReturnType<typeof useCreateFilingMutation>;
export type CreateFilingMutationResult = Apollo.MutationResult<CreateFilingMutation>;
export type CreateFilingMutationOptions = Apollo.BaseMutationOptions<CreateFilingMutation, CreateFilingMutationVariables>;