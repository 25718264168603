import { gql } from "@apollo/client";
import { AddressFragmentFragment } from "./__generated__/address.generated";

export const ADDRESS_FRAGMENT = gql`
  fragment AddressFragment on Address {
    id
    nameLine
    addressLine1
    addressLine2
    administrativeArea
    locality
    country
    postalCode
  }
`;

export type Address = AddressFragmentFragment;

export const formatAddress = (address?: Address | null) => {
  if (!address) return null;
  if (!address.addressLine1) return null;

  return [
    address.nameLine,
    address.addressLine1,
    address.addressLine2,
    address.locality,
    address.administrativeArea,
    address.postalCode,
    address.country,
  ].filter((v) => !!v && v.length);
}
