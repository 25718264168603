import React from "react";
import { Badge } from "../../core/Badge";


export type TagData = {
    value?: string | null;
    color?: string;
    id?: string;
};

export const badgeStyle = (color: string) => ({
    borderColor: `${color}20`,
    backgroundColor: `${color}30`,
    color,
});

const Tag = (props: TagData) => {

    const { value, color } = props;

    return (
        <Badge
            variant="outline"
            style={badgeStyle(color ?? "#0f172a ")}
            className="mr-2"
        >
            {value}
        </Badge>
    );
}

export default Tag;