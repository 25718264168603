import React, { useState } from "react";
import { Button } from "../core/Button";
import { Badge } from "../core/Badge";
import { gql } from "@apollo/client";
import { ADDRESS_FRAGMENT, formatAddress } from "../../utils/address";
import { Link, useMatch, useNavigate, useParams } from "react-router-dom";
import WithLoader from "../core/WithLoader";
import { useDeleteEntityMutation, useGetEntityForDetailsPageQuery, useInviteUsersToEntityMutation } from "./__generated__/EntityDetails.generated";
import DetailsField from "../core/DetailsField";
import { parseISO } from "date-fns";
import { INDIVIDUAL_FRAGMENT } from "./IndividualForm";
import IndividualCard from "./IndividualCard";
import Tabs from "../core/Tabs";
import Empty from "../core/Empty";
import { formatTime } from "../../utils/time";
import { titlecase } from "../../utils/strings";
import toast from "react-hot-toast";
import AlertDialog from "../core/AlertDialog";
import { useListEntitiesQuery } from "./__generated__/ListEntities.generated";
import PageLayout from "../layout/PageLayout";
import { HiOutlineLockClosed } from "react-icons/hi";
import { HiOutlineGlobeAlt } from "react-icons/hi";
import SmallButton from "../core/SmallButton";
import VisibilityModal from "./VisibilityModal";
import { useAuth } from "../auth/authContext";
import EntityFilings from "./EntityFilings";

const ENTITY_FRAGMENT = gql`
  fragment EntityDetailsEntityFragment on Entity {
    id
    legalName
    jurisdictionCountry
    jurisdictionState
    dateOfFormation
    tradeNames
    taxIdentificationNumber
    address {
      ...AddressFragment
    }
    isForeignPooledInvestmentVehicle
    beneficialOwners {
      ...IndividualFragment
    }
    companyApplicants {
      ...IndividualFragment
    }
    filings {
      id
      status
      createdAt
    }
    visibility
    invitedUsers {
      fullName
      email
      role
    }
  }
  ${ADDRESS_FRAGMENT}
  ${INDIVIDUAL_FRAGMENT}
`;

const GET_ENTITY = gql`
  query getEntityForDetailsPage($id: ID!) {
    getEntity(id: $id) {
      ...EntityDetailsEntityFragment
    }
  }
  ${ENTITY_FRAGMENT}
`;

const DELETE_ENTITY = gql`
  mutation deleteEntity($input: DeleteEntityInput!) {
    deleteEntity(input: $input) {
        id
        errors
    }
  }
`;

const INVITE_USERS_TO_ENTITY = gql`
   mutation inviteUsersToEntity($input: InviteUsersToEntityInput!) {
    inviteUsersToEntity(input: $input) {
        errors
        entity {
          legalName
          visibility
          invitedUsers {
            fullName
            email
            role
          }
        }
      }
   }
`;

const DELETE_INDIVIDUAL = gql`
  mutation deleteIndividual($input: DeleteIndividualInput!) {
    deleteIndividual(input: $input) {
      id
      errors
    }
  }
`;

export default () => {
  const { entityId } = useParams();
  const { auth } = useAuth();

  const { data, loading } = useGetEntityForDetailsPageQuery({
    variables: { id: entityId || "" },
  });

  const { refetch } = useListEntitiesQuery();

  const [deleteEntityAction, { loading: loadingDeleteEntity }] = useDeleteEntityMutation();
  const [inviteUsersToEntityAction, { loading: loadingInviteUsersToEntity }] = useInviteUsersToEntityMutation();
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showVisibilityModal, setShowVisibilityModal] = useState(false);
  const [currentTab, setCurrentTab] = useState("Entity Details");

  const navigate = useNavigate();

  const entity = data?.getEntity;
  const formattedAddress = entity && formatAddress(entity.address);

  const filings = entity?.filings?.slice(0, 3) || [];

  const handleDeleteEntity = () => {
    if (entityId) {
      deleteEntityAction({
        variables: {
          input: {
            id: entityId
          }
        },
        onCompleted: (data) => {
          if (data?.deleteEntity?.errors?.length) {
            toast.error("Something went wrong");
          } else {
            toast.success("Entity removed");
            handleShowAlertModal();
            refetch();
            navigate(`/entities`);
          }
        },
        onError: (error) => {
          toast.error("Something went wrong");
        }
      });
    }
  }

  const handleShowAlertModal = () => {
    setShowAlertModal((prev) => !prev);
  }

  const handleShowVisibilityModal = () => {
    setShowVisibilityModal((prev) => !prev);
  }

  const handleOnSubmitVisibilityModal = (visibility: string, people: string[]) => {
    const selectedPeople = people.filter(people => people !== '');
    if (entityId) {
      inviteUsersToEntityAction({
        variables: {
          input: {
            entityId: entityId,
            visibility: visibility,
            userEmails: selectedPeople
          }
        },
        onCompleted: (data) => {
          if (data?.inviteUsersToEntity?.errors?.length) {
            toast.error(data?.inviteUsersToEntity?.errors?.join(", /n"));
          } else {
            toast.success("Entity access updated successfully");
            refetch();
            handleShowVisibilityModal();
          }
        },
        onError: (error) => {
          toast.error("Something went wrong");
        }
      });
    }
  };

  return (
    <>
      <WithLoader loading={loading}>
        <PageLayout
          className="entity-details"
          headerNode={
            <div className="flex items-center">
              <h1 className="mt-1 text-2xl font-semibold leading-6 text-gray-900">
                {entity?.legalName}
              </h1>
              {auth.user?.role === "admin" && (<div className="ml-3 mt-2 cursor-pointer">
                <SmallButton
                  onClick={handleShowVisibilityModal}
                >
                  {entity?.visibility === 'private' ?
                    <HiOutlineLockClosed /> :
                    <HiOutlineGlobeAlt />}
                </SmallButton>
              </div>
              )}
            </div>
          }
          headerActions={
            <>{auth.user?.role === "admin" && (
              <Button
                variant='destructive'
                onClick={handleShowAlertModal}>
                Delete Entity
              </Button>
            )}
              <Button
                variant="outline"
                onClick={() => navigate(`/edit-entity/${entityId}`)}
              >
                Edit Entity
              </Button>
              <Button
                onClick={() =>
                  navigate("/filings/create?entityId=" + entity?.id)
                }
              >
                Create Filing
              </Button>
            </>
          }
        >
          <Tabs
            tabs={[
              {
                name: "Entity Details",
                current: currentTab === "Entity Details",
                onClick: () => setCurrentTab("Entity Details"),
              },
              {
                name: "Filings",
                current: currentTab === "Filings",
                onClick: () => setCurrentTab("Filings"),
              },
            ]}
          >
          </Tabs>
          {currentTab === "Entity Details" && (
            <div className="m-4 mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              <div className="-mx-4 sm:mx-0 px-4 sm:rounded-lg lg:col-span-2 lg:row-span-2 lg:row-end-2">
                <dl className="grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
                  <div className="mt-2 sm:pr-4 col-start-1">
                    <DetailsField
                      label="Full Legal Name"
                      value={entity?.legalName}
                    />
                  </div>
                  <div className="mt-6 sm:pr-4 col-start-1">
                    <DetailsField
                      label="Jurisdiction"
                      value={[
                        entity?.jurisdictionState,
                        entity?.jurisdictionCountry,
                      ]
                        .filter(Boolean)
                        .join(", ")}
                    />
                  </div>

                  <div className="mt-6 sm:pr-4 col-start-2">
                    <DetailsField
                      label="Date of Formation *"
                      value={
                        entity?.dateOfFormation &&
                        parseISO(entity?.dateOfFormation)?.toLocaleDateString()
                      }
                    />
                  </div>
                </dl>

                <h2 className="mt-8 text-base font-semibold leading-6 text-gray-900">
                  Additional Information
                </h2>
                <dl className="mt-1 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
                  <div className="mt-2 sm:pr-4 col-start-1">
                    <DetailsField
                      label="Trade Names"
                      value={entity?.tradeNames?.join(", ")}
                    />
                  </div>
                  <div className="mt-2 sm:pr-4 col-start-2">
                    <DetailsField
                      label="Tax Identification Number"
                      value={entity?.taxIdentificationNumber}
                    />
                  </div>
                  <div className="mt-6 sm:pr-4 col-start-1">
                    <DetailsField
                      label="Address"
                      value={
                        formattedAddress && (
                          <>
                            {formattedAddress.map((line, index) => (
                              <>
                                {line}
                                <br />
                              </>
                            ))}
                          </>
                        )
                      }
                    />
                  </div>

                  <div className="mt-6 sm:pr-4 col-start-1">
                    <DetailsField
                      label="Is reporting company a foreign pooled investment vehicle?"
                      value={
                        entity?.isForeignPooledInvestmentVehicle ? "Yes" : "No"
                      }
                    />
                  </div>
                </dl>

                <h2 className="mt-8 text-base font-semibold leading-6 text-gray-900">
                  Beneficial Owners
                </h2>
                {entity?.beneficialOwners?.length === 0 ? (
                  <dl className="m-2 my-4 text-sm leading-6 sm:grid-cols-2">
                    <Empty
                      message="No Beneficial Owners"
                      tagline="Add beneficial owners by editing the entity"
                    />
                  </dl>
                ) : (
                  <dl className="mt-1 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
                    {entity?.beneficialOwners?.map((owner) => (
                      <IndividualCard individual={owner} />
                    ))}
                  </dl>
                )}

                <h2 className="mt-8 text-base font-semibold leading-6 text-gray-900">
                  Company Applicants
                </h2>
                {entity?.companyApplicants?.length === 0 ? (
                  <dl className="m-2 my-4 text-sm leading-6 sm:grid-cols-2">
                    <Empty
                      message="No Company Applicants"
                      tagline="Add company applicants by editing the entity"
                    />
                  </dl>
                ) : (
                  <dl className="mt-1 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
                    {entity?.companyApplicants?.map((owner) => (
                      <IndividualCard individual={owner} />
                    ))}
                  </dl>
                )}
              </div>

              <div className="lg:col-start-3 lg:row-end-1">
                <h2 className="sr-only">Summary</h2>
                <div className="rounded-lg shadow-sm ring-1 ring-gray-900/5">
                  <dl className="flex flex-wrap">
                    <div className="flex-auto pl-6 pt-6">
                      <h2 className="text-base font-semibold leading-6 text-gray-900">
                        Filings
                      </h2>
                    </div>
                    <div className="p-6 w-full">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-900 sm:pl-0"
                            >
                              Created
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                            >
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                          {
                            filings.length === 0 && (
                              <tr>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                                  No filings
                                </td>
                              </tr>
                            )
                          }

                          {
                            filings.map((filing) => (
                              <tr>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                                  {formatTime(filing.createdAt)}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <Badge variant="outline">{titlecase(filing.status)}</Badge>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                  </dl>
                  <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
                    <Link
                      to={`/entities/${entityId}/filings`}
                      className="text-sm font-semibold leading-6 text-gray-900 cursor-pointer"                   >
                      View all filings <span aria-hidden="true">&rarr;</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
          {currentTab === "Filings" && (<EntityFilings />)}

        </PageLayout >
      </WithLoader >


      {showAlertModal && <AlertDialog
        visible={showAlertModal}
        onClose={handleShowAlertModal}
        content={`Are you sure you want to remove the Entity: <b>${data?.getEntity.legalName}</b>?`}
        onConfirm={handleDeleteEntity} />
      }

      {showVisibilityModal && <VisibilityModal
        visible={showVisibilityModal}
        entity={entity}
        onClose={handleShowVisibilityModal}
        onSubmit={handleOnSubmitVisibilityModal}
      />
      }

    </>
  );
};
